import React, { useState } from 'react';
import Payment from "../../models/Payment";
import Button from "../../components/Button";
import CreditForm from "../../components/Forms/CreditForm";
import ACHForm from "../../components/Forms/ACHForm";
import { D2CAgentId,  somethingWentWrong } from '../../utils';
import { PAYMENT_VALIDATION_HARD_ERROR_CODES, PAYMENT_VALIDATION_INSUFFICIENT_FUNDS, PAYMENT_VALIDATION_RETRY_LIMIT_EXCEEDED } from '../../services/validateTransaction';

type ValidationProps = {
    agentId: number;
    visible: boolean;
    onClose: () => void;
    updatePaymentInfo: (payment: Payment) => void;
    errorCode?: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined; // Added errorCode to determine message
};

const Validation: React.FC<ValidationProps> = ({ agentId, visible, onClose, updatePaymentInfo, errorCode }) => {
    const [payment, setPayment] = useState<Payment>(new Payment());
    const [paymentType, setPaymentType] = useState<'ACH' | 'CC'>('ACH');

    const handlePaymentSubmit = () => {
        updatePaymentInfo(payment);
        onClose();
    };

    if (!visible) return null;

    let errorMessage = "Please check your payment information and try again.";
    if (errorCode === PAYMENT_VALIDATION_INSUFFICIENT_FUNDS) {
        errorMessage = "It looks like there was an issue with your payment. Please check that your account has sufficient funds or try a different payment method.";
    }
    else if (PAYMENT_VALIDATION_HARD_ERROR_CODES.includes(errorCode as string)) {
        errorMessage = "It looks like there was an issue with your payment. The card details you entered are incorrect. Please verify the card number, expiration date, and try again.";
    } else if (errorCode === PAYMENT_VALIDATION_RETRY_LIMIT_EXCEEDED) {
        const action = 'while processing your payment';
        const isD2C = agentId === D2CAgentId;
        errorMessage = somethingWentWrong(isD2C, action);
    }

    return (
        <div className="absolute top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50" id='ccValidationModal'>
            <div className="rounded-lg bg-white p-8 shadow-lg border-4 border-black">
                {/* Payment Validation Failed Header */}
                <h2 className="text-2xl font-bold text-center text-blue-600 mb-4">
                    Unable to Process Payment
                </h2>
                <p className="text-center mb-4">
                    {errorMessage}
                </p>

                {/* Toggle Payment Type */}
                <fieldset className="flex flex-col gap-4 md:flex-row mb-4">
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="method"
                            value="ACH"
                            className="h-[25px] w-[25px] accent-lightBlue checked:bg-lightBlue"
                            checked={paymentType === "ACH"}
                            onChange={() => {
                                setPaymentType('ACH');
                                setPayment({
                                    ...payment,
                                    ccExpYear: undefined,
                                    ccExpMonth: undefined,
                                    ccNumber: undefined,
                                    ccSecurityCode: undefined,
                                    paymentType: 'ACH',
                                });
                            }}
                        />
                        <span className="pl-2 font-bold text-lightBlue">ACH (Preferred)</span>
                    </label>
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="method"
                            value="CC"
                            className="h-[25px] w-[25px] accent-lightBlue checked:bg-lightBlue"
                            checked={paymentType === "CC"}
                            onChange={() => {
                                setPaymentType('CC');
                                setPayment({
                                    ...payment,
                                    achAccount: undefined,
                                    achBankName: undefined,
                                    achRouting: undefined,
                                    achType: undefined,
                                    paymentType: 'CC',
                                });
                            }}
                        />
                        <span className="pl-2 font-bold text-lightBlue">Credit Card</span>
                    </label>
                </fieldset>

                {/* Render CreditForm or ACHForm based on payment type */}
                {paymentType === "CC" && (
                    <CreditForm
                        payment={payment}
                        setPayment={setPayment}
                        setStep={() => { }}
                        hideSubmitButton={true}
                    />
                )}
                {paymentType === "ACH" && (
                    <ACHForm
                        payment={payment}
                        setPayment={setPayment}
                        setStep={() => { }}
                        hideSubmitButton={true}
                    />
                )}

                {/* SSL Lock and Info */}
                <div className="mt-2 text-left text-xs text-gray-500 flex items-center">
                    <img
                        src="/lock.png"
                        alt="SSL lock"
                        className="inline-block mr-2 h-[20px]"
                    />
                    <span>Your Account information is protected using industry standard SSL Encryption technology.</span>
                </div>

                {/* Submit and Cancel Buttons */}
                <div className="mt-4 flex justify-between">
                    <Button
                        text="Submit"
                        className="w-[100px] px-4 py-2 bg-orange-500 text-white rounded"
                        mainPath={true}
                        action={handlePaymentSubmit}
                    />
                    <Button
                        text="Cancel"
                        className="w-[100px] px-4 py-2 bg-gray-500 text-white rounded"
                        mainPath={false}
                        action={onClose}
                    />
                </div>
            </div>
        </div>
    );
};

export default Validation;