import React, { FC } from "react";
import Button from "../../components/Button";
import Plan from "../../models/Plan";
import cx from "classnames";

type CoverageProps = {
  type?: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setStepsNames: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Plan>>;
  setWants: React.Dispatch<React.SetStateAction<boolean>>;
};

const Coverage: FC<CoverageProps> = ({
  type,
  setStep,
  setStepsNames,
  setSelectedPlan,
  setWants,
}: CoverageProps) => {
  return (
    <div className="flex h-[70vh] w-full flex-row items-center lg:container">
      <div
        className={cx(
          "w-full max-w-[460px]",
          "flex flex-col items-center justify-center",
          "rounded-[32px] border-4 border-black bg-white",
          "!mx-2 py-9 px-8"
        )}
      >
        <h1 className="text-lg font-medium leading-[24.2px] text-navyBlue sm:text-[20px]">
          I am looking for {type?.toLowerCase()} coverage.
        </h1>
        <div className="mt-[15px] flex w-full flex-col items-center gap-[15px] md:flex-row md:justify-center">
          <div className="h-[45px] w-full max-w-[312px] text-xl leading-[29.05px] sm:text-[24px] md:w-[157px]">
            <Button
              text="Yes"
              action={() => {
                setWants(true);
                setStep((current) => current + 1);
              }}
              mainPath
            />
          </div>
          <div className="h-[45px] w-full max-w-[312px] text-xl leading-[29.05px] sm:text-[24px] md:w-[157px]">
            <Button
              text={`${type === "Dental" ? "Vision Only" : "Dental Only"}`}
              action={() => {
                if (type === "Dental") {
                  setStepsNames((current) =>
                    current.filter(
                      (value) =>
                        value !== "Amount Dental" &&
                        value !== "Dental" &&
                        value !== "Dependents Dental" &&
                        value !== "Vision Coverage"
                    )
                  );
                } else if (type === "Vision") {
                  setStepsNames((current) =>
                    current.filter(
                      (value) =>
                        value !== "Amount Vision" &&
                        value !== "Vision" &&
                        value !== "Dependents Vision"
                    )
                  );
                }
                setSelectedPlan(new Plan());
                setWants(false);
                setStep((current) => current + 1);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coverage;
