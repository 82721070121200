import cx from "classnames";
import { FC } from "react";

type FormFieldErrorProps = {
  error: string
}
const FormFieldError: FC<FormFieldErrorProps> = ({
  error
}) => {
  return (
    <p
      className={cx(
        "text-base font-bold text-red",
        "flex justify-center",
        "mt-4 md:mt-0"
      )}
    >
      {error}
    </p>
  )
}

export default FormFieldError;