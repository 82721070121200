import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Icons/LoadingSpinner";

type ButtonProps = {
  url?: string;
  action?: () => void;
  text?: string;
  submit?: boolean;
  disabled?: boolean;
  mainPath?: boolean;
  loadingIcon?: boolean;
  className?: string;
};

const Button: FC<ButtonProps & React.HTMLAttributes<HTMLDivElement>> = ({
  url,
  action,
  text,
  submit = false,
  disabled = false,
  mainPath = false,
  loadingIcon = false,
  className = "",
}: ButtonProps) => {
  const navigate = useNavigate();
  const [loadingIconState, setLoadingIconState] = useState(loadingIcon);

  useEffect(() => {
    setLoadingIconState(loadingIcon);
  }, [loadingIcon]);

  const btnClassNames = `flex h-full w-full cursor-pointer items-center justify-center rounded-lg ${
            mainPath ? "bg-orange" : "bg-lightBlue disabled:bg-grey"
          } ${
            loadingIconState && "disabled:bg-grey"
          } p-3 text-center text-white ${className}
          hover:shadow-lg disabled:cursor-default `

  return (
    <>
      {submit ? (
        <button
          type="submit"
          className={btnClassNames}
          disabled={disabled}
        >
          {loadingIconState ? (<LoadingSpinner />) : (<>{text}</>)}
        </button>
      ) : (
        <button
          type="button"
          className={btnClassNames}
          onClick={() => {
            if (action) action();
            if (url) navigate(url);
          }}
          disabled={disabled}
        >
          {loadingIconState ? (<LoadingSpinner />) : (<>{text}</>)}
        </button>
      )}
    </>
  );
};

export default Button;
