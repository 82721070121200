import { FC } from "react"
import cx from "classnames";
import NwfaLink from "../../components/Links/NwfaLink";

const HomepageBlurbD2C: FC = () => {
  return (
    <div
      className={cx(
        "flex flex-col overflow-y-auto",
        "rounded-3xl border-4 border-solid border-black bg-white",
        "py-6 px-6 pb-3 text-black"
      )}
    >
      <div className="self-start text-[24px] font-semibold leading-[29.05px]">
        NCD: Exceptional Products.
      </div>
      <div className="mb-3 self-start text-[24px] font-semibold leading-[29.05px]">
        Outstanding Service.
      </div>
      <p className="my-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
        Your health should be your top priority. It is for us.
      </p>
      <p className="my-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
        By choosing NCD, you're getting more than just great vision or
        dental coverage. You're getting care that goes beyond. Care
        that makes a difference to your whole body, care that makes
        your life a little easier.
      </p>
      <p className="my-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
        So check us out. We're doing something different, because we
        believe that insurance should do more.
      </p>
      <div className="my-3 self-start text-[20px] font-semibold leading-[24.2px]">
        Our Family of Exceptional Products
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="md:max-w-[319px]">
          <div className="my-3 self-start text-[20px] font-semibold leading-[24.2px]">
            NCD by MetLife
          </div>
          <ul className="my-3 list-disc pl-[25px] text-[20px] leading-[24.2px]">
            <li>
              Annual max benefits ranging from $750 to $10,000!
            </li>
            <li>100% coverage for preventive care*</li>
            <li>
              Additional Coverage for fillings, crowns, surgery, and
              much more!
            </li>
          </ul>
          <a
            href="#"
            className="my-3 pl-[25px] text-[20px] font-bold leading-[24.2px] text-[#003764]"
          >
            Apply Now
          </a>
        </div>
        <div className="md:max-w-[319px]">
          <div className="my-3 self-start text-[20px] font-semibold leading-[24.2px]">
            VSP Preferred Vision by NCD
          </div>
          <ul className="my-3 list-disc pl-[25px] text-[20px]  leading-[24.2px]">
            <li>
              $20 exam/$25 materials per covered person per office
              visit
            </li>
            <li>
              Great coverage on single, lined bifocal, lined trifocal,
              progressive lenses, and contacts.
            </li>
            <li>
              Average 25%-30% savings on other lens enhancements
            </li>
          </ul>
          <a
            href="#"
            className="my-3 pl-[25px] text-[20px] font-bold leading-[24.2px] text-[#003764]"
          >
            Apply Now
          </a>
        </div>
      </div>
      <p className="mt-5 text-[20px] leading-[24.2px]">
        Ready to move forward? Enter your zip code above to select the
        plan that's best for you.
      </p>
      <div className="mt-10 mb-3 self-start text-[20px] font-semibold leading-[24.2px]">
        An Outstanding Service Team
      </div>
      <p className="mt-3 text-[20px] leading-[24.2px] md:max-w-[667px]">
        When it comes to member care, we've got a team you can put
        your trust in. Our US-based Member Care Team is dedicated to
        treating each and every one of our members with the care and
        consideration they deserve.
      </p>
      <p className="text-[20px] leading-[24.2px]">
        Member Care Team Highlights
      </p>
      <ul className="mb-3 list-disc pl-[25px] text-[20px]  leading-[24.2px]">
        <li>Phone calls answered in 30 seconds or less</li>
        <li>Emailed returned in 1 hour or less</li>
      </ul>
      <p className="mt-4 text-[20px] leading-[24.2px]">
        Plans available for
          <NwfaLink
            text=' National Wellness and Fitness'
            queryString='?utm_source=D2C+NCD+MetLife+Enrollment+Platform&utm_medium=direct&utm_id=NWFA%2C+Direct+Traffic'
          /> members.
      </p>
      <p className="italic mt-4 text-[14px] leading-[24.2px]">
        <span>
          *
        </span>
        Some in-network preventive services are subject to copayments on NCD Smile by MetLife plans. Out-of-network, preventive services are subject to deductibles/cost-sharing on NCD Smile by MetLife plans.
      </p>
    </div>
  )
}

export default HomepageBlurbD2C
