import React, { FC } from "react";
import cx from "classnames";
import CoveredMembers from "../../components/CoveredMembers";
import Agent from "../../models/Agent";
import Dependent from "../../models/Dependent";
import Member from "../../models/Member";
import Plan from "../../models/Plan";

type DependentInformationProps = {
  member: Member;
  agent: Agent;
  setAmountOfDependents: React.Dispatch<React.SetStateAction<number>>;
  amountOfDependents: number;
  amountOfDependentsType: string;
  setDependents: React.Dispatch<React.SetStateAction<Dependent[]>>;
  dependents: Dependent[];
  validVisionInState: boolean;
  selectedPlans: Plan[];
  setSelectedPlanVision: React.Dispatch<React.SetStateAction<Plan>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  zipCodeState: string;
};

const DependentInformation: FC<DependentInformationProps> = ({
  member,
  agent,
  setAmountOfDependents,
  amountOfDependents,
  amountOfDependentsType,
  setDependents,
  dependents,
  validVisionInState,
  selectedPlans,
  setSelectedPlanVision,
  setStep,
  zipCodeState,
}: DependentInformationProps) => {
  return (
    <div
      className={cx(
        "px-2 py-2",
        "flex flex-col",
        "min-h-[70vh] w-full sm:w-[400px] md:w-[700px] xl:w-full",
        "xl:pt-17"
      )}
    >
      <div className="page-header-container">
        <h1 className="page-header-text text-center">Dependent Information</h1>
      </div>
      <CoveredMembers
        member={member}
        agent={agent}
        setAmountOfDependents={setAmountOfDependents}
        amountOfDependents={amountOfDependents}
        amountOfDependentsType={amountOfDependentsType}
        setDependents={setDependents}
        dependents={dependents}
        setSelectedPlanVision={setSelectedPlanVision}
        validVisionInState={validVisionInState}
        setStep={setStep}
        selectedPlans={selectedPlans}
        zipCodeState={zipCodeState}
      />
    </div>
  );
};

export default DependentInformation;
