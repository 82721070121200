import { FC, useCallback } from "react";
import cx from "classnames";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../components/ProgressBar";
import { D2CAgentId, NCD_APP_TECH_SUPPORT, NCD_MEMBER_CARE_EMAIL, NCD_MEMBER_CARE_PHONE, NCD_WELCOME_TEAM_EMAIL } from "../../utils";
import Member from "../../models/Member";

type SubmittedProps = {
  agentId?: number;
  memberId?: string;
  memberFirstName?: string;
  memberLastName?: string;
  setMemberInstance: React.Dispatch<React.SetStateAction<Member>>;
};

const Submitted: FC<SubmittedProps> = ({
  agentId,
  memberId,
  memberFirstName,
  memberLastName,
  setMemberInstance
}: SubmittedProps) => {
  const navigate = useNavigate();

  const handleStartNewApplication = useCallback(() => {
    setMemberInstance(new Member());
    navigate(`/${agentId}`);
    if(!memberId) {
      window.location.reload(); // navigate seems to get stuck upon 5xx / 4xx errors that could occur during member create...
    }
  }, [agentId, memberId, navigate, setMemberInstance])

  const memberCreatingMsg = `Successfully completed the application! The new Member ID for ${memberFirstName} ${memberLastName}`
  const hasEmailMemberCreatingMsg = `${memberCreatingMsg} will be arriving in your email inbox shortly!`
  const noEmailMemberCreatingMsg = `${memberCreatingMsg} is being created.`

  const isD2C = agentId === D2CAgentId;

  return (
    <>
      <div className={cx("px-2 pt-4 pb-48 sm:pb-0")}>
        <div className="flex h-screen flex-row justify-center gap-4">
          <div className="flex basis-full flex-col gap-3 lg:basis-2/4">
            <div className="flex h-[250px] w-full flex-col items-center justify-center self-center rounded-3xl border-4 border-black bg-white p-4 text-center">
              <img
                src="/ncd_logo.svg"
                className="h-24 xs:h-32"
                alt="NCD Logo"
              />
              <div className="text-xl font-medium text-black">
                Welcome to NCD!
              </div>
              <div className="text-sm text-textGrey">
                Your Application has been received
              </div>
              {!isD2C ? (
                <div className="mt-2 h-[40px] w-[230px] font-bold">
                  <Button
                    text="Start a new application"
                    className="startNewApplication"
                    action={handleStartNewApplication}
                  />
                </div>
              ) : null}
            </div>
            <div className="rounded-3xl border-4 border-black bg-white p-4">
              <div className="flex">
                <div className="my-2">
                  <div data-cy="memberCreatingMsg" className="font-semibold">
                    { memberId && (
                      <>
                        <p>Member ID: <span data-cy="memberId">{memberId}</span></p>
                        <p>Primary Member Name: <span data-cy="primaryMemberName">{memberFirstName} {memberLastName}</span></p>
                      </>
                    )}
                    { !memberId && isD2C && hasEmailMemberCreatingMsg }
                    { !memberId && !isD2C && noEmailMemberCreatingMsg }
                  </div>
                </div>
                {isD2C && (
                  <div
                    className="ml-auto h-5 cursor-pointer pl-4 text-lightBlue"
                    role="button"
                    onClick={() => window.print()}
                  >
                    Print This Page
                  </div>
                )}
              </div>
              <div className="flex max-w-3xl">
                <p className="font-semibold">New Members:</p>
              </div>
              <p className="my-2">
                Please be on the lookout for your Enrollment Confirmation
                Letter.
              </p>
              <div className="my-2">
                <p>
                  If you provided an email address in your enrollment application,
                  you'll be receiving a new member welcome email from{" "}
                  <a className="text-lightBlue" href={`mailto:${NCD_WELCOME_TEAM_EMAIL}`}>
                    {NCD_WELCOME_TEAM_EMAIL}
                  </a>.
                </p>
              </div>
              <p className="my-2">
                Keep an eye out for your physical copy of your new member welcome kit,
                which should arrive in the mail soon.
              </p>
              <p className="my-2">
                For immediate needs or specific questions, you can contact NCD
                Member Care department in the following ways:
              </p>
              <div className="my-2">
                <p>
                  Phone:{" "}
                  <a href={`tel: ${NCD_MEMBER_CARE_PHONE}`} className="text-lightBlue">
                    {NCD_MEMBER_CARE_PHONE}
                  </a>
                </p>
                <p>
                  Email:{" "}
                  <a
                    className="text-lightBlue"
                    href={`mailto:${NCD_MEMBER_CARE_EMAIL}`}
                  >
                    {NCD_MEMBER_CARE_EMAIL}
                  </a>
                </p>
              </div>
              {!isD2C ? (
                <>
                  <p className="my-2 font-semibold">Agents:</p>
                  <p className="my-2">
                    If you need assistance with this application or have
                    immediate questions, please contact{" "}
                    <a
                      className="text-lightBlue"
                      href={`mailto:${NCD_APP_TECH_SUPPORT}`}
                    >
                      {NCD_APP_TECH_SUPPORT}
                    </a>.
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed bottom-0 z-30 ml-auto mr-auto w-full bg-orange pt-1`}
      >
        <ProgressBar step={1} agentId={agentId} />
      </div>
    </>
  );
};

export default Submitted;
