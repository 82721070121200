import { v4 as uuid } from "uuid";

interface IErrorLogEntry {
  error: any;
  message: string;
  level: LogLevel;
}
interface IErrorLog {
  entries: IErrorLogEntry[];
}

export enum LogLevel {
  debug, info, log, warn, error
}

// ErrorLog class abstracts away how we log and retain errors
// Uses the Singleton creational pattern so that we only instantiate one instance of the ErrorLog
export default class ErrorLog implements IErrorLog {
  private static instance: ErrorLog;
  entries: IErrorLogEntry[];
  id: string;

  private constructor() {
    this.id = uuid();
    this.entries = [];
  }

  // logs the error with specified level
  // @params error: Error, message: string to be logged, level: LogLevel to console.log
  // @returns undefined
  public static log(error: any, message: string, level: LogLevel = LogLevel.log) {
    this.getInstance().logError(error, message, level);
  }

  public static getInstance(): ErrorLog {
    if (!ErrorLog.instance) {
      ErrorLog.instance = new ErrorLog();
    }

    return ErrorLog.instance;
  }

  logError(error: any, message: string, level = LogLevel.warn ) {
    this.entries.push(new ErrorLogEntry(error, message, level))

    switch (level) {
      case LogLevel.error:
        console.error(message, error);
        break;
      case LogLevel.warn:
        console.warn(message, error);
        break;
      case LogLevel.info:
        console.info(message, error);
        break;
      case LogLevel.debug:
        console.debug(message, error);
        break;
      default:
        console.log(message, error);
        break;
    }
  }

  public static lastEntry(): ErrorLogEntry | undefined {
    const instance = this.getInstance();
    return instance.entries[instance.entries.length - 1]
  }
}

class ErrorLogEntry implements IErrorLogEntry {
  message: string;
  error: any;
  level: LogLevel;

  constructor(error: any, message: string, level = LogLevel.warn ) {
    this.error = error;
    this.message = message;
    this.level = level;
  }
}