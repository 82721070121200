import { IAgent } from "./AgentInterface";
import axios from "axios";
import { buildNCDHeaders, ENV } from "../../utils";
import { handleAxiosError } from "../../axiosHelper";
import { getEnvKey } from "../../environment";

export default class Agent implements IAgent {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: number;
  parentAgentId?: number;

  constructor(
    id?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    phoneNumber?: number,
    parentAgentId?: number
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.parentAgentId = parentAgentId;
  }

  /**
   * @name loginAgent
   * @param agentId
   * @description this function takes the ID of an Agent and validates it to give it access
   * */
  loginAgent = async (agentId = this.id, setAgentState: React.Dispatch<React.SetStateAction<Agent>>, appId?: string) => {
    return await axios
      .get(`${getEnvKey(ENV.REACT_APP_AGENTLOGINLAMBDAURL)}/${agentId}`,
        {
          headers: buildNCDHeaders(agentId, appId)
        })
      .then((response) => {
        const agent = JSON.parse(response.data);
        this.id = agent.ID;
        this.firstName = agent.FIRSTNAME;
        this.lastName = agent.LASTNAME;
        this.email = agent.EMAIL;
        this.phoneNumber = agent.PHONE1;
        this.parentAgentId = agent.PARENT.ID;
        setAgentState({ ...this }) // make a new object for setState
        return response;
      })
      .catch((error) => {
        return handleAxiosError(error)
      });
  };

  /**
   * @name getAgentlicnese
   * @param agentId
   * @description Gets the agent licenses
   * */
  getAgentLicense = async (agentId = this.id, appId?: string) => {
    return await axios
      .get(`${process.env.REACT_APP_AGENTLICENSELAMBDAURL}/${agentId}`,
        {
          headers: buildNCDHeaders(agentId, appId)
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        handleAxiosError(error)
        return '[]'
      });
  };
}
