const eliteDentalData = [
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 995
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 996
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 997
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 998
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 999
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 350
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 351
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 352
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 354
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 355
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 356
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 357
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 358
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 359
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 360
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 361
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 362
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 363
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 364
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 365
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 366
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 367
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 368
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 369
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 716
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 717
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 718
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 719
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 720
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 721
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 722
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 723
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 724
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 725
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 726
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 727
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 728
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 729
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 850
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 851
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 852
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 853
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 855
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 856
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 857
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 859
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 860
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 863
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 864
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 865
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 900
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 901
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 902
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 903
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 904
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 905
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 906
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 907
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 908
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 910
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 911
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 912
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 913
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 914
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 915
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 916
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 917
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 918
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 919
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 920
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 921
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 922
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 923
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 924
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 925
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 926
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 927
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 928
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 930
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 931
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 932
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 933
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 934
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 935
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 936
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 937
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 938
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 939
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 940
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 941
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 942
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 943
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 944
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 945
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 946
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 947
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 948
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 949
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 950
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 951
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 952
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 953
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 954
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 955
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 956
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 957
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 958
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 959
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 960
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 961
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 800
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 801
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 802
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 803
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 804
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 805
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 806
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 807
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 808
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 809
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 810
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 811
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 812
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 813
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 814
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 815
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 816
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 60
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 61
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 62
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 63
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 64
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 65
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 66
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 67
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 68
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 69
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 200
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 202
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 203
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 204
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 205
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 569
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 888
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 197
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 198
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 199
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 320
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 321
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 322
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 323
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 324
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 325
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 326
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 327
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 328
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 329
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 330
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 331
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 332
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 333
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 334
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 335
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 336
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 337
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 338
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 339
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 341
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 342
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 344
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 346
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 347
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 349
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 300
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 301
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 302
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 303
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 304
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 305
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 306
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 307
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 308
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 309
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 310
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 311
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 312
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 313
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 314
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 315
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 316
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 317
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 318
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 319
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 398
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 399
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 967
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 968
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 500
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 501
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 502
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 503
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 504
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 505
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 506
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 507
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 508
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 509
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 510
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 511
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 512
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 513
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 514
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 515
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 516
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 520
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 521
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 522
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 523
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 524
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 525
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 526
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 527
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 528
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 832
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 833
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 834
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 835
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 836
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 837
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 838
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 600
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 601
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 602
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 603
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 604
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 605
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 606
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 607
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 608
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 609
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 610
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 611
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 612
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 613
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 614
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 615
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 616
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 617
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 618
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 619
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 620
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 622
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 623
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 624
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 625
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 626
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 627
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 628
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 629
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 460
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 461
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 462
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 463
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 464
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 465
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 466
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 467
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 468
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 469
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 470
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 471
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 472
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 473
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 474
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 475
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 476
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 477
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 478
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 479
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 660
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 661
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 662
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 664
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 665
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 666
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 667
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 668
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 669
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 670
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 671
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 672
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 673
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 674
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 675
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 676
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 677
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 678
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 679
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 400
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 401
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 402
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 403
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 404
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 405
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 406
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 407
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 408
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 409
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 410
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 411
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 412
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 413
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 414
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 415
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 416
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 417
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 418
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 420
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 421
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 422
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 423
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 424
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 425
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 426
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 427
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 700
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 701
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 703
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 704
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 705
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 706
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 707
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 708
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 710
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 711
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 712
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 713
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 714
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 10
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 11
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 12
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 13
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 14
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 15
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 16
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 17
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 18
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 19
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 20
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 21
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 22
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 23
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 24
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 25
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 26
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 27
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 55
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 206
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 207
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 208
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 209
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 210
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 211
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 212
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 214
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 215
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 216
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 217
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 218
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 219
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 39
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 40
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 41
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 42
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 43
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 44
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 45
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 46
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 47
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 48
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 49
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 480
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 481
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 482
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 483
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 484
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 485
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 486
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 487
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 488
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 489
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 490
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 491
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 492
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 493
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 494
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 495
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 496
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 497
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 498
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 499
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 550
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 551
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 553
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 554
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 555
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 556
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 557
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 558
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 559
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 560
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 561
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 562
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 563
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 564
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 565
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 566
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 567
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 630
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 631
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 633
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 634
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 635
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 636
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 637
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 638
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 639
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 640
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 641
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 644
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 645
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 646
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 647
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 648
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 649
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 650
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 651
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 652
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 653
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 654
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 655
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 656
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 657
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 658
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 386
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 387
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 388
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 389
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 390
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 391
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 392
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 393
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 394
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 395
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 396
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 397
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 590
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 591
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 592
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 593
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 594
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 595
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 596
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 597
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 598
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 599
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 270
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 271
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 272
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 273
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 274
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 275
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 276
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 277
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 278
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 279
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 280
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 281
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 282
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 283
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 284
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 285
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 286
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 287
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 288
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 289
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 580
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 581
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 582
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 583
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 584
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 585
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 586
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 587
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 588
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 680
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 681
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 683
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 684
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 685
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 686
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 687
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 688
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 689
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 690
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 691
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 692
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 693
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 30
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 31
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 32
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 33
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 34
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 35
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 36
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 37
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 38
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 70
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 71
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 72
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 73
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 74
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 75
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 76
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 77
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 78
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 79
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 80
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 81
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 82
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 83
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 84
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 85
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 86
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 87
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 88
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 89
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 870
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 871
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 873
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 874
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 875
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 876
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 877
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 878
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 879
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 880
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 881
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 882
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 883
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 884
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 889
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 890
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 891
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 893
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 894
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 895
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 897
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 898
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 5
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 100
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 101
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 102
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 103
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 104
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 105
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 106
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 107
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 108
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 109
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 110
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 111
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 112
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 113
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 114
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 115
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 116
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 117
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 118
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 119
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 120
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 121
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 122
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 123
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 124
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 125
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 126
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 127
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 128
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 129
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 130
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 131
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 132
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 133
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 134
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 135
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 136
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 137
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 138
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 139
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 140
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 141
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 142
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 143
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 144
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 145
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 146
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 147
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 148
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 149
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 430
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 431
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 432
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 433
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 434
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 435
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 436
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 437
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 438
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 439
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 440
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 441
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 442
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 443
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 444
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 445
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 446
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 447
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 448
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 449
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 450
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 451
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 452
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 453
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 454
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 455
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 456
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 457
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 458
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 459
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 730
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 731
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 734
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 735
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 736
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 737
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 738
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 739
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 740
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 741
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 743
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 744
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 745
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 746
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 747
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 748
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 749
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 970
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 971
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 972
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 973
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 974
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 975
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 976
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 977
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 978
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 979
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 150
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 151
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 152
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 153
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 154
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 155
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 156
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 157
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 158
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 159
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 160
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 161
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 162
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 163
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 164
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 165
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 166
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 167
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 168
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 169
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 170
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 171
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 172
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 173
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 174
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 175
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 176
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 177
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 178
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 179
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 180
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 181
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 182
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 183
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 184
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 185
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 186
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 187
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 188
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 189
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 190
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 191
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 192
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 193
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 194
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 195
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 196
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 28
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 29
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 290
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 291
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 292
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 293
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 294
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 295
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 296
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 297
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 298
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 299
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 570
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 571
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 572
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 573
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 574
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 575
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 576
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 577
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 370
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 371
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 372
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 373
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 374
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 375
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 376
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 377
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 378
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 379
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 380
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 381
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 382
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 383
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 384
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 385
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 733
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 750
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 751
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 752
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 753
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 754
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 755
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 756
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 757
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 758
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 759
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 760
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 761
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 762
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 763
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 764
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 765
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 766
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 767
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 768
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 769
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 770
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 772
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 773
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 774
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 775
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 776
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 777
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 778
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 779
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 780
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 781
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 782
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 783
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 784
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 785
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 786
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 787
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 788
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 789
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 790
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 791
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 792
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 793
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 794
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 795
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 796
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 797
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 798
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 799
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 885
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 840
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 841
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 842
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 843
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 844
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 845
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 846
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 847
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 201
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 220
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 221
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 222
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 223
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 224
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 225
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 226
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 227
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 228
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 229
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 230
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 231
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 232
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 233
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 234
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 235
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 236
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 237
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 238
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 239
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 240
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 241
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 242
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 243
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 244
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 245
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 246
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 50
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 51
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 52
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 53
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 54
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 56
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 57
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 58
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 59
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 980
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 981
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 982
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 983
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 43,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 984
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 985
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 986
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 988
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 989
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 990
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 991
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 992
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 993
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 994
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 530
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 531
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 532
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 534
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 535
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 537
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 538
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 539
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 540
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 541
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 542
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 543
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 544
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 545
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 546
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 547
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 548
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 40,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 549
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 247
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 248
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 249
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 250
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 251
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 252
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 253
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 254
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 255
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 256
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 257
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 258
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 259
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 260
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 261
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 262
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 263
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 264
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 265
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 266
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 267
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 268
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 820
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 821
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 822
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 823
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 824
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 825
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 826
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 827
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 828
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 829
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 830
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 38,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 831
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 995
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 996
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 997
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 998
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 999
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 350
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 351
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 352
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 354
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 355
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 356
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 357
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 358
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 359
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 360
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 361
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 362
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 363
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 364
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 365
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 366
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 367
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 368
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 369
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 716
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 717
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 718
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 719
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 720
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 721
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 722
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 723
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 724
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 725
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 726
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 727
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 728
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 729
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 850
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 851
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 852
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 853
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 855
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 856
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 857
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 859
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 860
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 863
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 864
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 865
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 900
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 901
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 902
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 903
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 904
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 905
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 906
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 907
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 908
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 910
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 911
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 912
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 913
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 914
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 915
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 916
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 917
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 918
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 919
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 920
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 921
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 922
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 923
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 924
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 925
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 926
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 927
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 928
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 930
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 931
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 932
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 933
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 934
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 935
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 936
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 937
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 938
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 939
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 940
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 941
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 942
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 943
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 944
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 945
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 946
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 947
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 948
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 949
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 950
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 951
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 952
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 953
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 954
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 955
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 956
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 957
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 958
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 959
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 960
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 961
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 800
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 801
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 802
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 803
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 804
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 805
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 806
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 807
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 808
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 809
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 810
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 811
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 812
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 813
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 814
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 815
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 816
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 60
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 61
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 62
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 63
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 64
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 65
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 66
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 67
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 68
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 69
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 200
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 202
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 203
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 204
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 205
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 569
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 888
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 197
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 198
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 199
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 320
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 321
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 322
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 323
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 324
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 325
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 326
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 327
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 328
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 329
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 330
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 331
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 332
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 333
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 334
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 335
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 336
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 337
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 338
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 339
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 341
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 342
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 344
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 346
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 347
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 349
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 300
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 301
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 302
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 303
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 304
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 305
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 306
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 307
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 308
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 309
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 310
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 311
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 312
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 313
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 314
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 315
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 316
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 317
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 318
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 319
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 398
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 399
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 967
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 968
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 500
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 501
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 502
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 503
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 504
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 505
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 506
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 507
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 508
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 509
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 510
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 511
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 512
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 513
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 514
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 515
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 516
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 520
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 521
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 522
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 523
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 524
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 525
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 526
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 527
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 528
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 832
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 833
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 834
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 835
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 836
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 837
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 838
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 600
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 601
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 602
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 603
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 604
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 605
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 606
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 607
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 608
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 609
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 610
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 611
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 612
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 613
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 614
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 615
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 616
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 617
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 618
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 619
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 620
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 622
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 623
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 624
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 625
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 626
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 627
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 628
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 629
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 460
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 461
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 462
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 463
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 464
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 465
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 466
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 467
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 468
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 469
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 470
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 471
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 472
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 473
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 474
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 475
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 476
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 477
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 478
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 479
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 660
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 661
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 662
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 664
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 665
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 666
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 667
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 668
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 669
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 670
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 671
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 672
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 673
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 674
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 675
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 676
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 677
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 678
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 679
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 400
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 401
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 402
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 403
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 404
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 405
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 406
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 407
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 408
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 409
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 410
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 411
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 412
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 413
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 414
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 415
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 416
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 417
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 418
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 420
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 421
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 422
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 423
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 424
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 425
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 426
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 427
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 700
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 701
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 703
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 704
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 705
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 706
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 707
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 708
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 710
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 711
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 712
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 713
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 714
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 10
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 11
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 12
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 13
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 14
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 15
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 16
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 17
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 18
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 19
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 20
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 21
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 22
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 23
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 24
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 25
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 26
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 27
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 55
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 206
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 207
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 208
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 209
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 210
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 211
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 212
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 214
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 215
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 216
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 217
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 218
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 219
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 39
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 40
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 41
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 42
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 43
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 44
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 45
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 46
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 47
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 48
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 49
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 480
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 481
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 482
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 483
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 484
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 485
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 486
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 487
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 488
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 489
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 490
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 491
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 492
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 493
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 494
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 495
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 496
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 497
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 498
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 499
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 550
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 551
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 553
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 554
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 555
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 556
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 557
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 558
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 559
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 560
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 561
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 562
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 563
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 564
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 565
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 566
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 567
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 630
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 631
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 633
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 634
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 635
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 636
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 637
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 638
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 639
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 640
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 641
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 644
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 645
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 646
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 647
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 648
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 649
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 650
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 651
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 652
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 653
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 654
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 655
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 656
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 657
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 658
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 386
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 387
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 388
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 389
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 390
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 391
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 392
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 393
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 394
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 395
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 396
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 397
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 590
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 591
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 592
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 593
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 594
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 595
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 596
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 597
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 598
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 599
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 270
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 271
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 272
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 273
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 274
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 275
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 276
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 277
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 278
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 279
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 280
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 281
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 282
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 283
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 284
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 285
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 286
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 287
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 288
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 289
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 580
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 581
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 582
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 583
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 584
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 585
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 586
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 587
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 588
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 680
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 681
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 683
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 684
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 685
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 686
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 687
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 688
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 689
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 690
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 691
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 692
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 693
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 30
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 31
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 32
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 33
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 34
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 35
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 36
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 37
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 38
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 70
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 71
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 72
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 73
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 74
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 75
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 76
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 77
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 78
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 79
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 80
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 81
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 82
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 83
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 84
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 85
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 86
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 87
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 88
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 89
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 870
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 871
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 873
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 874
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 875
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 876
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 877
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 878
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 879
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 880
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 881
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 882
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 883
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 884
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 889
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 890
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 891
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 893
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 894
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 895
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 897
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 898
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 5
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 100
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 101
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 102
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 103
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 104
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 105
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 106
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 107
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 108
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 109
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 110
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 111
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 112
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 113
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 114
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 115
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 116
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 117
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 118
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 119
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 120
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 121
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 122
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 123
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 124
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 125
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 126
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 127
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 128
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 129
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 130
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 131
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 132
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 133
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 134
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 135
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 136
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 137
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 138
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 139
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 140
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 141
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 142
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 143
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 144
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 145
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 146
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 147
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 148
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 149
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 430
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 431
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 432
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 433
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 434
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 435
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 436
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 437
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 438
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 439
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 440
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 441
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 442
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 443
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 444
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 445
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 446
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 447
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 448
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 449
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 450
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 451
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 452
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 453
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 454
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 455
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 456
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 457
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 458
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 459
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 730
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 731
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 734
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 735
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 736
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 737
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 738
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 739
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 740
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 741
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 743
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 744
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 745
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 746
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 747
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 748
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 749
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 970
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 971
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 972
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 973
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 974
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 975
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 976
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 977
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 978
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 979
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 150
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 151
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 152
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 153
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 154
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 155
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 156
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 157
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 158
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 159
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 160
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 161
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 162
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 163
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 164
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 165
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 166
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 167
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 168
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 169
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 170
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 171
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 172
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 173
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 174
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 175
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 176
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 177
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 178
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 179
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 180
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 181
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 182
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 183
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 184
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 185
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 186
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 187
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 188
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 189
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 190
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 191
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 192
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 193
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 194
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 195
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 196
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 28
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 29
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 290
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 291
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 292
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 293
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 294
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 295
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 296
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 297
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 298
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 299
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 570
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 571
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 572
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 573
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 574
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 575
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 576
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 577
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 370
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 371
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 372
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 373
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 374
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 375
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 376
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 377
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 378
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 379
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 380
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 381
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 382
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 383
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 384
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 385
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 733
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 750
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 751
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 752
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 753
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 754
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 755
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 756
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 757
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 758
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 759
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 760
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 761
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 762
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 763
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 764
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 765
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 766
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 767
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 768
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 769
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 770
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 772
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 773
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 774
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 775
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 776
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 777
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 778
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 779
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 780
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 781
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 782
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 783
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 784
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 785
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 786
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 787
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 788
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 789
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 790
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 791
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 792
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 793
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 794
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 795
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 796
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 797
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 798
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 799
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 885
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 840
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 841
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 842
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 843
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 844
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 845
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 846
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 847
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 201
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 220
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 221
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 222
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 223
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 224
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 225
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 226
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 227
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 228
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 229
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 230
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 231
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 232
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 233
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 234
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 235
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 236
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 237
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 238
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 239
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 240
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 241
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 242
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 243
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 244
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 245
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 246
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 50
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 51
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 52
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 53
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 54
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 56
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 57
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 58
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 59
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 980
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 981
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 982
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 983
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 86,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 984
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 985
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 986
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 988
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 989
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 990
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 991
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 992
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 993
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 994
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 530
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 531
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 532
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 534
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 535
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 537
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 538
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 539
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 540
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 541
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 542
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 543
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 544
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 545
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 546
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 547
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 548
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 80,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 549
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 247
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 248
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 249
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 250
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 251
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 252
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 253
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 254
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 255
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 256
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 257
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 258
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 259
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 260
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 261
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 262
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 263
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 264
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 265
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 266
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 267
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 268
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 820
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 821
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 822
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 823
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 824
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 825
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 826
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 827
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 828
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 829
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 830
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 76,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 831
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 995
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 996
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 997
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 998
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 999
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 350
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 351
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 352
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 354
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 355
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 356
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 357
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 358
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 359
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 360
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 361
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 362
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 363
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 364
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 365
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 366
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 367
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 368
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 369
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 716
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 717
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 718
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 719
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 720
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 721
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 722
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 723
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 724
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 725
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 726
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 727
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 728
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 729
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 850
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 851
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 852
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 853
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 855
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 856
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 857
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 859
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 860
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 863
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 864
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 865
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 900
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 901
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 902
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 903
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 904
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 905
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 906
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 907
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 908
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 910
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 911
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 912
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 913
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 914
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 915
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 916
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 917
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 918
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 919
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 920
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 921
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 922
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 923
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 924
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 925
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 926
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 927
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 928
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 930
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 931
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 932
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 933
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 934
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 935
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 936
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 937
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 938
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 939
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 940
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 941
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 942
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 943
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 944
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 945
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 946
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 947
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 948
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 949
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 950
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 951
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 952
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 953
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 954
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 955
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 956
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 957
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 958
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 959
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 960
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 961
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 800
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 801
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 802
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 803
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 804
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 805
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 806
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 807
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 808
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 809
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 810
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 811
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 812
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 813
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 814
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 815
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 816
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 60
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 61
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 62
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 63
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 64
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 65
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 66
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 67
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 68
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 69
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 200
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 202
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 203
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 204
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 205
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 569
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 888
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 197
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 198
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 199
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 320
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 321
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 322
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 323
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 324
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 325
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 326
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 327
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 328
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 329
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 330
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 331
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 332
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 333
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 334
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 335
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 336
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 337
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 338
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 339
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 341
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 342
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 344
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 346
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 347
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 349
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 300
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 301
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 302
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 303
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 304
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 305
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 306
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 307
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 308
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 309
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 310
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 311
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 312
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 313
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 314
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 315
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 316
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 317
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 318
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 319
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 398
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 399
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 967
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 968
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 500
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 501
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 502
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 503
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 504
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 505
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 506
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 507
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 508
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 509
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 510
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 511
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 512
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 513
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 514
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 515
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 516
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 520
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 521
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 522
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 523
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 524
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 525
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 526
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 527
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 528
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 832
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 833
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 834
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 835
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 836
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 837
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 838
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 600
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 601
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 602
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 603
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 604
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 605
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 606
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 607
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 608
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 609
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 610
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 611
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 612
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 613
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 614
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 615
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 616
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 617
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 618
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 619
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 620
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 622
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 623
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 624
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 625
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 626
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 627
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 628
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 629
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 460
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 461
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 462
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 463
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 464
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 465
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 466
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 467
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 468
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 469
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 470
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 471
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 472
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 473
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 474
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 475
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 476
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 477
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 478
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 479
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 660
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 661
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 662
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 664
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 665
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 666
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 667
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 668
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 669
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 670
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 671
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 672
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 673
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 674
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 675
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 676
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 677
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 678
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 679
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 400
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 401
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 402
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 403
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 404
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 405
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 406
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 407
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 408
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 409
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 410
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 411
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 412
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 413
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 414
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 415
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 416
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 417
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 418
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 420
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 421
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 422
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 423
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 424
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 425
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 426
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 427
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 700
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 701
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 703
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 704
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 705
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 706
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 707
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 708
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 710
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 711
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 712
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 713
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 714
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 10
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 11
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 12
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 13
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 14
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 15
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 16
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 17
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 18
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 19
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 20
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 21
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 22
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 23
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 24
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 25
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 26
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 27
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 55
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 206
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 207
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 208
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 209
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 210
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 211
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 212
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 214
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 215
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 216
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 217
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 218
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 219
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 39
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 40
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 41
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 42
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 43
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 44
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 45
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 46
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 47
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 48
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 49
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 480
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 481
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 482
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 483
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 484
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 485
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 486
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 487
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 488
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 489
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 490
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 491
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 492
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 493
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 494
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 495
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 496
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 497
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 498
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 499
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 550
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 551
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 553
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 554
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 555
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 556
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 557
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 558
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 559
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 560
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 561
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 562
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 563
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 564
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 565
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 566
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 567
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 630
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 631
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 633
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 634
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 635
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 636
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 637
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 638
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 639
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 640
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 641
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 644
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 645
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 646
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 647
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 648
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 649
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 650
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 651
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 652
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 653
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 654
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 655
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 656
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 657
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 658
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 386
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 387
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 388
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 389
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 390
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 391
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 392
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 393
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 394
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 395
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 396
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 397
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 590
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 591
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 592
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 593
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 594
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 595
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 596
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 597
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 598
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 599
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 270
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 271
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 272
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 273
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 274
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 275
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 276
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 277
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 278
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 279
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 280
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 281
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 282
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 283
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 284
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 285
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 286
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 287
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 288
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 289
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 580
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 581
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 582
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 583
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 584
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 585
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 586
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 587
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 588
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 680
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 681
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 683
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 684
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 685
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 686
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 687
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 688
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 689
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 690
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 691
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 692
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 693
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 30
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 31
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 32
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 33
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 34
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 35
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 36
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 37
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 38
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 70
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 71
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 72
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 73
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 74
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 75
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 76
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 77
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 78
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 79
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 80
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 81
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 82
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 83
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 84
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 85
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 86
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 87
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 88
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 89
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 870
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 871
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 873
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 874
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 875
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 876
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 877
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 878
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 879
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 880
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 881
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 882
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 883
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 884
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 889
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 890
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 891
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 893
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 894
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 895
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 897
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 898
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 5
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 100
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 101
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 102
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 103
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 104
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 105
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 106
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 107
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 108
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 109
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 110
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 111
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 112
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 113
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 114
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 115
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 116
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 117
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 118
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 119
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 120
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 121
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 122
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 123
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 124
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 125
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 126
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 127
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 128
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 129
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 130
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 131
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 132
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 133
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 134
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 135
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 136
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 137
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 138
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 139
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 140
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 141
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 142
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 143
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 144
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 145
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 146
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 147
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 148
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 149
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 430
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 431
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 432
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 433
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 434
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 435
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 436
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 437
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 438
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 439
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 440
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 441
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 442
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 443
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 444
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 445
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 446
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 447
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 448
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 449
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 450
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 451
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 452
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 453
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 454
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 455
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 456
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 457
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 458
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 459
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 730
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 731
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 734
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 735
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 736
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 737
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 738
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 739
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 740
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 741
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 743
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 744
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 745
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 746
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 747
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 748
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 749
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 970
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 971
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 972
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 973
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 974
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 975
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 976
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 977
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 978
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 979
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 150
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 151
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 152
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 153
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 154
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 155
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 156
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 157
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 158
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 159
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 160
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 161
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 162
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 163
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 164
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 165
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 166
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 167
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 168
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 169
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 170
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 171
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 172
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 173
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 174
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 175
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 176
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 177
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 178
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 179
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 180
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 181
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 182
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 183
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 184
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 185
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 186
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 187
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 188
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 189
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 190
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 191
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 192
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 193
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 194
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 195
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 196
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 28
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 29
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 290
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 291
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 292
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 293
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 294
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 295
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 296
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 297
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 298
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 299
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 570
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 571
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 572
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 573
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 574
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 575
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 576
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 577
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 370
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 371
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 372
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 373
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 374
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 375
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 376
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 377
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 378
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 379
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 380
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 381
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 382
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 383
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 384
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 385
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 733
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 750
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 751
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 752
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 753
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 754
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 755
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 756
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 757
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 758
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 759
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 760
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 761
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 762
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 763
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 764
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 765
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 766
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 767
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 768
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 769
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 770
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 772
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 773
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 774
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 775
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 776
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 777
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 778
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 779
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 780
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 781
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 782
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 783
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 784
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 785
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 786
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 787
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 788
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 789
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 790
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 791
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 792
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 793
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 794
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 795
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 796
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 797
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 798
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 799
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 885
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 840
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 841
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 842
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 843
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 844
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 845
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 846
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 847
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 201
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 220
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 221
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 222
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 223
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 224
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 225
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 226
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 227
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 228
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 229
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 230
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 231
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 232
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 233
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 234
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 235
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 236
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 237
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 238
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 239
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 240
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 241
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 242
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 243
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 244
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 245
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 246
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 50
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 51
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 52
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 53
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 54
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 56
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 57
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 58
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 59
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 980
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 981
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 982
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 983
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 108,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 984
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 985
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 986
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 988
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 989
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 990
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 991
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 992
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 993
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 994
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 530
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 531
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 532
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 534
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 535
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 537
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 538
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 539
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 540
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 541
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 542
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 543
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 544
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 545
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 546
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 547
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 548
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 100,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 549
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 247
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 248
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 249
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 250
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 251
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 252
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 253
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 254
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 255
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 256
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 257
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 258
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 259
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 260
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 261
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 262
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 263
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 264
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 265
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 266
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 267
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 268
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 820
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 821
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 822
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 823
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 824
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 825
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 826
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 827
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 828
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 829
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 830
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 95,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 831
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 995
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 996
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 997
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 998
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 999
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 350
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 351
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 352
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 354
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 355
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 356
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 357
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 358
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 359
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 360
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 361
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 362
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 363
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 364
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 365
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 366
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 367
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 368
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 369
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 716
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 717
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 718
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 719
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 720
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 721
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 722
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 723
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 724
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 725
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 726
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 727
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 728
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 729
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 850
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 851
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 852
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 853
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 855
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 856
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 857
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 859
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 860
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 863
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 864
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 865
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 900
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 901
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 902
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 903
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 904
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 905
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 906
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 907
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 908
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 910
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 911
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 912
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 913
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 914
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 915
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 916
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 917
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 918
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 919
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 920
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 921
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 922
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 923
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 924
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 925
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 926
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 927
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 928
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 930
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 931
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 932
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 933
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 934
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 935
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 936
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 937
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 938
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 939
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 940
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 941
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 942
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 943
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 944
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 945
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 946
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 947
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 948
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 949
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 950
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 951
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 952
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 953
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 954
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 955
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 956
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 957
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 958
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 959
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 960
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 961
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 800
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 801
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 802
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 803
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 804
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 805
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 806
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 807
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 808
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 809
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 810
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 811
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 812
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 813
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 814
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 815
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 816
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 60
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 61
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 62
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 63
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 64
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 65
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 66
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 67
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 68
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 69
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 200
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 202
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 203
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 204
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 205
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 569
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 888
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 197
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 198
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 199
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 320
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 321
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 322
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 323
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 324
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 325
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 326
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 327
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 328
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 329
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 330
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 331
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 332
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 333
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 334
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 335
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 336
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 337
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 338
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 339
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 341
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 342
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 344
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 346
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 347
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 349
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 300
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 301
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 302
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 303
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 304
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 305
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 306
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 307
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 308
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 309
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 310
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 311
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 312
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 313
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 314
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 315
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 316
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 317
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 318
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 319
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 398
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 399
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 967
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 968
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 500
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 501
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 502
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 503
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 504
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 505
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 506
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 507
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 508
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 509
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 510
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 511
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 512
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 513
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 514
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 515
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 516
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 520
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 521
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 522
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 523
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 524
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 525
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 526
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 527
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 528
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 832
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 833
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 834
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 835
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 836
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 837
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 838
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 600
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 601
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 602
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 603
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 604
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 605
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 606
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 607
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 608
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 609
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 610
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 611
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 612
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 613
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 614
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 615
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 616
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 617
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 618
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 619
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 620
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 622
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 623
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 624
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 625
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 626
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 627
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 628
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 629
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 460
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 461
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 462
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 463
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 464
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 465
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 466
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 467
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 468
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 469
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 470
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 471
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 472
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 473
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 474
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 475
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 476
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 477
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 478
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 479
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 660
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 661
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 662
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 664
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 665
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 666
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 667
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 668
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 669
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 670
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 671
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 672
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 673
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 674
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 675
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 676
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 677
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 678
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 679
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 400
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 401
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 402
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 403
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 404
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 405
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 406
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 407
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 408
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 409
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 410
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 411
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 412
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 413
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 414
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 415
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 416
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 417
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 418
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 420
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 421
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 422
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 423
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 424
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 425
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 426
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 427
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 700
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 701
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 703
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 704
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 705
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 706
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 707
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 708
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 710
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 711
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 712
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 713
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 714
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 10
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 11
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 12
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 13
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 14
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 15
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 16
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 17
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 18
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 19
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 20
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 21
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 22
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 23
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 24
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 25
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 26
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 27
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 55
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 206
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 207
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 208
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 209
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 210
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 211
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 212
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 214
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 215
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 216
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 217
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 218
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 219
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 39
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 40
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 41
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 42
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 43
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 44
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 45
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 46
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 47
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 48
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 49
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 480
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 481
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 482
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 483
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 484
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 485
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 486
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 487
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 488
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 489
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 490
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 491
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 492
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 493
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 494
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 495
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 496
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 497
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 498
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 499
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 550
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 551
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 553
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 554
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 555
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 556
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 557
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 558
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 559
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 560
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 561
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 562
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 563
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 564
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 565
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 566
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 567
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 630
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 631
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 633
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 634
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 635
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 636
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 637
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 638
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 639
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 640
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 641
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 644
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 645
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 646
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 647
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 648
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 649
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 650
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 651
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 652
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 653
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 654
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 655
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 656
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 657
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 658
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 386
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 387
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 388
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 389
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 390
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 391
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 392
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 393
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 394
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 395
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 396
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 397
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 590
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 591
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 592
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 593
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 594
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 595
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 596
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 597
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 598
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 599
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 270
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 271
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 272
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 273
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 274
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 275
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 276
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 277
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 278
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 279
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 280
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 281
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 282
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 283
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 284
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 285
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 286
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 287
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 288
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 289
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 580
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 581
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 582
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 583
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 584
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 585
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 586
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 587
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 588
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 680
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 681
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 683
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 684
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 685
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 686
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 687
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 688
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 689
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 690
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 691
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 692
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 693
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 30
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 31
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 32
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 33
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 34
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 35
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 36
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 37
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 38
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 70
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 71
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 72
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 73
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 74
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 75
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 76
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 77
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 78
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 79
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 80
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 81
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 82
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 83
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 84
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 85
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 86
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 87
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 88
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 89
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 870
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 871
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 873
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 874
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 875
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 876
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 877
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 878
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 879
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 880
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 881
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 882
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 883
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 884
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 889
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 890
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 891
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 893
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 894
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 895
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 897
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 898
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 5
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 100
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 101
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 102
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 103
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 104
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 105
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 106
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 107
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 108
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 109
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 110
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 111
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 112
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 113
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 114
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 115
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 116
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 117
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 118
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 119
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 120
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 121
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 122
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 123
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 124
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 125
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 126
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 127
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 128
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 129
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 130
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 131
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 132
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 133
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 134
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 135
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 136
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 137
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 138
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 139
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 140
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 141
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 142
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 143
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 144
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 145
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 146
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 147
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 148
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 149
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 430
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 431
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 432
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 433
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 434
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 435
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 436
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 437
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 438
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 439
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 440
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 441
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 442
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 443
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 444
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 445
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 446
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 447
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 448
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 449
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 450
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 451
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 452
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 453
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 454
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 455
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 456
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 457
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 458
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 459
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 730
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 731
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 734
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 735
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 736
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 737
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 738
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 739
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 740
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 741
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 743
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 744
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 745
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 746
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 747
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 748
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 749
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 970
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 971
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 972
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 973
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 974
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 975
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 976
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 977
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 978
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 979
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 150
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 151
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 152
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 153
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 154
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 155
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 156
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 157
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 158
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 159
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 160
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 161
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 162
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 163
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 164
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 165
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 166
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 167
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 168
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 169
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 170
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 171
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 172
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 173
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 174
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 175
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 176
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 177
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 178
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 179
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 180
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 181
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 182
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 183
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 184
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 185
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 186
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 187
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 188
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 189
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 190
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 191
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 192
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 193
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 194
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 195
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 196
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 28
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 29
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 290
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 291
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 292
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 293
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 294
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 295
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 296
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 297
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 298
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 299
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 570
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 571
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 572
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 573
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 574
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 575
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 576
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 577
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 370
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 371
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 372
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 373
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 374
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 375
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 376
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 377
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 378
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 379
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 380
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 381
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 382
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 383
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 384
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 385
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 733
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 750
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 751
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 752
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 753
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 754
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 755
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 756
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 757
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 758
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 759
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 760
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 761
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 762
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 763
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 764
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 765
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 766
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 767
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 768
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 769
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 770
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 772
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 773
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 774
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 775
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 776
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 777
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 778
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 779
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 780
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 781
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 782
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 783
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 784
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 785
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 786
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 787
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 788
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 789
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 790
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 791
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 792
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 793
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 794
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 795
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 796
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 797
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 798
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 799
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 885
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 840
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 841
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 842
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 843
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 844
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 845
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 846
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 847
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 201
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 220
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 221
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 222
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 223
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 224
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 225
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 226
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 227
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 228
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 229
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 230
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 231
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 232
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 233
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 234
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 235
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 236
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 237
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 238
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 239
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 240
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 241
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 242
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 243
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 244
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 245
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 246
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 50
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 51
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 52
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 53
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 54
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 56
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 57
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 58
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 59
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 980
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 981
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 982
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 983
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 155,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 984
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 985
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 986
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 988
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 989
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 990
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 991
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 992
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 993
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 994
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 530
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 531
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 532
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 534
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 535
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 537
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 538
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 539
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 540
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 541
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 542
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 543
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 544
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 545
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 546
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 547
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 548
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 144,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 549
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 247
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 248
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 249
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 250
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 251
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 252
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 253
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 254
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 255
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 256
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 257
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 258
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 259
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 260
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 261
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 262
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 263
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 264
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 265
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 266
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 267
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 268
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 820
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 821
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 822
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 823
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 824
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 825
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 826
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 827
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 828
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 829
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 830
  },
  {
    "ProductID": 42249,
    "Label": "MetLife Elite 1500",
    "Price": 137,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 831
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 995
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 996
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 997
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 998
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 999
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 350
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 351
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 352
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 354
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 355
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 356
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 357
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 358
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 359
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 360
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 361
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 362
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 363
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 364
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 365
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 366
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 367
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 368
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 369
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 716
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 717
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 718
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 719
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 720
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 721
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 722
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 723
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 724
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 725
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 726
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 727
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 728
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 729
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 850
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 851
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 852
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 853
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 855
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 856
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 857
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 859
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 860
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 863
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 864
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 865
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 900
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 901
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 902
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 903
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 904
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 905
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 906
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 907
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 908
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 910
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 911
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 912
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 913
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 914
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 915
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 916
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 917
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 918
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 919
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 920
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 921
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 922
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 923
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 924
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 925
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 926
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 927
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 928
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 930
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 931
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 932
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 933
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 934
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 935
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 936
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 937
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 938
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 939
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 940
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 941
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 942
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 943
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 944
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 945
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 946
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 947
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 948
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 949
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 950
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 951
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 952
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 953
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 954
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 955
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 956
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 957
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 958
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 959
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 960
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 961
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 800
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 801
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 802
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 803
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 804
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 805
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 806
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 807
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 808
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 809
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 810
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 811
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 812
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 813
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 814
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 815
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 816
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 60
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 61
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 62
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 63
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 64
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 65
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 66
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 67
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 68
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 69
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 200
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 202
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 203
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 204
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 205
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 569
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 888
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 197
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 198
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 199
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 320
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 321
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 322
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 323
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 324
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 325
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 326
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 327
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 328
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 329
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 330
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 331
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 332
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 333
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 334
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 335
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 336
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 337
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 338
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 339
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 341
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 342
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 344
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 346
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 347
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 349
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 300
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 301
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 302
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 303
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 304
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 305
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 306
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 307
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 308
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 309
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 310
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 311
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 312
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 313
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 314
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 315
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 316
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 317
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 318
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 319
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 398
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 399
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 967
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 968
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 500
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 501
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 502
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 503
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 504
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 505
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 506
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 507
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 508
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 509
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 510
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 511
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 512
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 513
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 514
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 515
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 516
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 520
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 521
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 522
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 523
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 524
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 525
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 526
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 527
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 528
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 832
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 833
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 834
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 835
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 836
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 837
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 838
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 600
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 601
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 602
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 603
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 604
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 605
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 606
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 607
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 608
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 609
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 610
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 611
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 612
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 613
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 614
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 615
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 616
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 617
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 618
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 619
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 620
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 622
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 623
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 624
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 625
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 626
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 627
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 628
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 629
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 460
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 461
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 462
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 463
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 464
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 465
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 466
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 467
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 468
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 469
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 470
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 471
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 472
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 473
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 474
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 475
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 476
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 477
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 478
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 479
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 660
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 661
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 662
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 664
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 665
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 666
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 667
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 668
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 669
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 670
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 671
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 672
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 673
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 674
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 675
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 676
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 677
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 678
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 679
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 400
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 401
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 402
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 403
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 404
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 405
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 406
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 407
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 408
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 409
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 410
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 411
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 412
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 413
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 414
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 415
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 416
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 417
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 418
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 420
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 421
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 422
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 423
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 424
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 425
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 426
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 427
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 700
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 701
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 703
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 704
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 705
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 706
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 707
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 708
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 710
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 711
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 712
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 713
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 714
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 10
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 11
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 12
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 13
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 14
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 15
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 16
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 17
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 18
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 19
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 20
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 21
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 22
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 23
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 24
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 25
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 26
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 27
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 55
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 206
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 207
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 208
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 209
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 210
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 211
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 212
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 214
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 215
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 216
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 217
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 218
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 219
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 39
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 40
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 41
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 42
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 43
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 44
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 45
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 46
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 47
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 48
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 49
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 480
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 481
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 482
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 483
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 484
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 485
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 486
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 487
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 488
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 489
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 490
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 491
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 492
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 493
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 494
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 495
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 496
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 497
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 498
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 499
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 550
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 551
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 553
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 554
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 555
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 556
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 557
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 558
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 559
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 560
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 561
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 562
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 563
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 564
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 565
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 566
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 567
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 630
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 631
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 633
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 634
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 635
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 636
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 637
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 638
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 639
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 640
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 641
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 644
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 645
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 646
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 647
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 648
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 649
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 650
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 651
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 652
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 653
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 654
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 655
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 656
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 657
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 658
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 386
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 387
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 388
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 389
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 390
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 391
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 392
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 393
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 394
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 395
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 396
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 397
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 590
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 591
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 592
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 593
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 594
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 595
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 596
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 597
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 598
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 599
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 270
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 271
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 272
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 273
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 274
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 275
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 276
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 277
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 278
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 279
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 280
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 281
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 282
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 283
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 284
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 285
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 286
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 287
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 288
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 289
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 580
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 581
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 582
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 583
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 584
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 585
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 586
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 587
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 588
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 680
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 681
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 683
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 684
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 685
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 686
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 687
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 688
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 689
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 690
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 691
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 692
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 693
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 30
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 31
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 32
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 33
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 34
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 35
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 36
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 37
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 38
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 70
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 71
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 72
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 73
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 74
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 75
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 76
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 77
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 78
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 79
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 80
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 81
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 82
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 83
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 84
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 85
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 86
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 87
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 88
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 89
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 870
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 871
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 873
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 874
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 875
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 876
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 877
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 878
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 879
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 880
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 881
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 882
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 883
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 884
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 889
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 890
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 891
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 893
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 894
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 895
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 897
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 898
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 5
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 100
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 101
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 102
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 103
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 104
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 105
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 106
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 107
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 108
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 109
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 110
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 111
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 112
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 113
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 114
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 115
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 116
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 117
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 118
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 119
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 120
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 121
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 122
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 123
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 124
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 125
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 126
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 127
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 128
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 129
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 130
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 131
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 132
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 133
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 134
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 135
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 136
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 137
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 138
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 139
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 140
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 141
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 142
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 143
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 144
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 145
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 146
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 147
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 148
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 149
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 430
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 431
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 432
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 433
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 434
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 435
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 436
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 437
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 438
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 439
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 440
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 441
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 442
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 443
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 444
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 445
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 446
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 447
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 448
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 449
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 450
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 451
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 452
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 453
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 454
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 455
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 456
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 457
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 458
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 459
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 730
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 731
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 734
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 735
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 736
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 737
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 738
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 739
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 740
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 741
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 743
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 744
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 745
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 746
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 747
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 748
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 749
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 970
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 971
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 972
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 973
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 974
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 975
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 976
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 977
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 978
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 979
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 150
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 151
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 152
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 153
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 154
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 155
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 156
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 157
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 158
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 159
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 160
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 161
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 162
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 163
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 164
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 165
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 166
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 167
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 168
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 169
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 170
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 171
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 172
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 173
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 174
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 175
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 176
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 177
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 178
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 179
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 180
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 181
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 182
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 183
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 184
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 185
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 186
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 187
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 188
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 189
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 190
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 191
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 192
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 193
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 194
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 195
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 196
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 28
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 29
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 290
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 291
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 292
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 293
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 294
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 295
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 296
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 297
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 298
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 299
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 570
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 571
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 572
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 573
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 574
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 575
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 576
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 577
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 370
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 371
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 372
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 373
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 374
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 375
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 376
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 377
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 378
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 379
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 380
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 381
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 382
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 383
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 384
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 385
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 733
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 750
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 751
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 752
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 753
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 754
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 755
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 756
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 757
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 758
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 759
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 760
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 761
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 762
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 763
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 764
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 765
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 766
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 767
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 768
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 769
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 770
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 772
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 773
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 774
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 775
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 776
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 777
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 778
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 779
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 780
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 781
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 782
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 783
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 784
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 785
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 786
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 787
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 788
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 789
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 790
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 791
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 792
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 793
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 794
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 795
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 796
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 797
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 798
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 799
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 885
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 840
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 841
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 842
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 843
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 844
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 845
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 846
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 847
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 201
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 220
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 221
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 222
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 223
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 224
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 225
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 226
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 227
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 228
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 229
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 230
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 231
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 232
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 233
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 234
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 235
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 236
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 237
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 238
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 239
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 240
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 241
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 242
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 243
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 244
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 245
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 246
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 50
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 51
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 52
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 53
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 54
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 56
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 57
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 58
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 59
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 980
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 981
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 982
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 983
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 59,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 984
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 985
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 986
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 988
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 989
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 990
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 991
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 992
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 993
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 994
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 530
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 531
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 532
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 534
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 535
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 537
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 538
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 539
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 540
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 541
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 542
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 543
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 544
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 545
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 546
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 547
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 548
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 55,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 549
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 247
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 248
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 249
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 250
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 251
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 252
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 253
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 254
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 255
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 256
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 257
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 258
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 259
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 260
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 261
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 262
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 263
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 264
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 265
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 266
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 267
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 268
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 820
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 821
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 822
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 823
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 824
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 825
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 826
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 827
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 828
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 829
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 830
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 52,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 831
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 995
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 996
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 997
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 998
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 999
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 350
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 351
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 352
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 354
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 355
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 356
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 357
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 358
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 359
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 360
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 361
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 362
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 363
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 364
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 365
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 366
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 367
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 368
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 369
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 716
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 717
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 718
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 719
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 720
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 721
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 722
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 723
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 724
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 725
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 726
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 727
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 728
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 729
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 850
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 851
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 852
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 853
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 855
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 856
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 857
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 859
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 860
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 863
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 864
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 865
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 900
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 901
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 902
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 903
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 904
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 905
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 906
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 907
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 908
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 910
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 911
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 912
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 913
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 914
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 915
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 916
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 917
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 918
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 919
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 920
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 921
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 922
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 923
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 924
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 925
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 926
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 927
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 928
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 930
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 931
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 932
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 933
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 934
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 935
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 936
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 937
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 938
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 939
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 940
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 941
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 942
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 943
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 944
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 945
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 946
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 947
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 948
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 949
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 950
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 951
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 952
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 953
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 954
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 955
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 956
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 957
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 958
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 959
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 960
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 961
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 800
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 801
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 802
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 803
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 804
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 805
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 806
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 807
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 808
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 809
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 810
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 811
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 812
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 813
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 814
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 815
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 816
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 60
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 61
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 62
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 63
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 64
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 65
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 66
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 67
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 68
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 69
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 200
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 202
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 203
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 204
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 205
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 569
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 888
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 197
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 198
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 199
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 320
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 321
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 322
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 323
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 324
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 325
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 326
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 327
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 328
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 329
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 330
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 331
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 332
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 333
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 334
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 335
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 336
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 337
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 338
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 339
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 341
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 342
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 344
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 346
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 347
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 349
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 300
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 301
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 302
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 303
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 304
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 305
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 306
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 307
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 308
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 309
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 310
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 311
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 312
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 313
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 314
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 315
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 316
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 317
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 318
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 319
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 398
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 399
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 967
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 968
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 500
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 501
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 502
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 503
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 504
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 505
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 506
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 507
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 508
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 509
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 510
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 511
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 512
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 513
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 514
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 515
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 516
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 520
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 521
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 522
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 523
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 524
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 525
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 526
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 527
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 528
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 832
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 833
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 834
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 835
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 836
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 837
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 838
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 600
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 601
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 602
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 603
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 604
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 605
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 606
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 607
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 608
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 609
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 610
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 611
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 612
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 613
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 614
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 615
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 616
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 617
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 618
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 619
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 620
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 622
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 623
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 624
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 625
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 626
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 627
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 628
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 629
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 460
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 461
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 462
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 463
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 464
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 465
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 466
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 467
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 468
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 469
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 470
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 471
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 472
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 473
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 474
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 475
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 476
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 477
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 478
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 479
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 660
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 661
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 662
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 664
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 665
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 666
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 667
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 668
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 669
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 670
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 671
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 672
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 673
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 674
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 675
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 676
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 677
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 678
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 679
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 400
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 401
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 402
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 403
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 404
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 405
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 406
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 407
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 408
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 409
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 410
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 411
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 412
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 413
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 414
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 415
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 416
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 417
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 418
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 420
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 421
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 422
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 423
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 424
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 425
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 426
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 427
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 700
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 701
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 703
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 704
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 705
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 706
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 707
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 708
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 710
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 711
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 712
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 713
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 714
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 10
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 11
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 12
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 13
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 14
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 15
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 16
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 17
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 18
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 19
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 20
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 21
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 22
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 23
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 24
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 25
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 26
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 27
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 55
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 206
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 207
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 208
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 209
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 210
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 211
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 212
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 214
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 215
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 216
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 217
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 218
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 219
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 39
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 40
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 41
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 42
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 43
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 44
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 45
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 46
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 47
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 48
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 49
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 480
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 481
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 482
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 483
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 484
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 485
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 486
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 487
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 488
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 489
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 490
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 491
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 492
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 493
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 494
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 495
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 496
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 497
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 498
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 499
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 550
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 551
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 553
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 554
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 555
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 556
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 557
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 558
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 559
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 560
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 561
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 562
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 563
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 564
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 565
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 566
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 567
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 630
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 631
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 633
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 634
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 635
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 636
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 637
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 638
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 639
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 640
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 641
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 644
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 645
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 646
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 647
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 648
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 649
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 650
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 651
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 652
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 653
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 654
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 655
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 656
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 657
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 658
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 386
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 387
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 388
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 389
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 390
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 391
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 392
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 393
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 394
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 395
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 396
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 397
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 590
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 591
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 592
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 593
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 594
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 595
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 596
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 597
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 598
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 599
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 270
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 271
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 272
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 273
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 274
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 275
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 276
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 277
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 278
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 279
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 280
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 281
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 282
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 283
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 284
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 285
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 286
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 287
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 288
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 289
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 580
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 581
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 582
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 583
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 584
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 585
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 586
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 587
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 588
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 680
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 681
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 683
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 684
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 685
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 686
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 687
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 688
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 689
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 690
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 691
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 692
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 693
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 30
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 31
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 32
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 33
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 34
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 35
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 36
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 37
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 38
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 70
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 71
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 72
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 73
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 74
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 75
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 76
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 77
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 78
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 79
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 80
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 81
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 82
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 83
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 84
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 85
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 86
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 87
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 88
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 89
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 870
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 871
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 873
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 874
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 875
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 876
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 877
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 878
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 879
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 880
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 881
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 882
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 883
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 884
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 889
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 890
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 891
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 893
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 894
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 895
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 897
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 898
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 5
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 100
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 101
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 102
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 103
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 104
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 105
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 106
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 107
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 108
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 109
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 110
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 111
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 112
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 113
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 114
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 115
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 116
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 117
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 118
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 119
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 120
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 121
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 122
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 123
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 124
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 125
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 126
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 127
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 128
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 129
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 130
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 131
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 132
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 133
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 134
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 135
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 136
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 137
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 138
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 139
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 140
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 141
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 142
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 143
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 144
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 145
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 146
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 147
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 148
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 149
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 430
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 431
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 432
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 433
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 434
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 435
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 436
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 437
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 438
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 439
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 440
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 441
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 442
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 443
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 444
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 445
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 446
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 447
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 448
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 449
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 450
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 451
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 452
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 453
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 454
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 455
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 456
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 457
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 458
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 459
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 730
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 731
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 734
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 735
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 736
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 737
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 738
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 739
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 740
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 741
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 743
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 744
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 745
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 746
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 747
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 748
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 749
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 970
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 971
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 972
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 973
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 974
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 975
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 976
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 977
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 978
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 979
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 150
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 151
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 152
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 153
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 154
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 155
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 156
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 157
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 158
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 159
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 160
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 161
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 162
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 163
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 164
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 165
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 166
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 167
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 168
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 169
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 170
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 171
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 172
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 173
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 174
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 175
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 176
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 177
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 178
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 179
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 180
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 181
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 182
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 183
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 184
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 185
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 186
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 187
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 188
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 189
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 190
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 191
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 192
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 193
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 194
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 195
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 196
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 28
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 29
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 290
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 291
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 292
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 293
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 294
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 295
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 296
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 297
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 298
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 299
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 570
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 571
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 572
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 573
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 574
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 575
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 576
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 577
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 370
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 371
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 372
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 373
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 374
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 375
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 376
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 377
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 378
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 379
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 380
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 381
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 382
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 383
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 384
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 385
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 733
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 750
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 751
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 752
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 753
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 754
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 755
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 756
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 757
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 758
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 759
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 760
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 761
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 762
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 763
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 764
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 765
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 766
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 767
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 768
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 769
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 770
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 772
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 773
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 774
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 775
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 776
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 777
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 778
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 779
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 780
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 781
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 782
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 783
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 784
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 785
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 786
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 787
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 788
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 789
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 790
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 791
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 792
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 793
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 794
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 795
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 796
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 797
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 798
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 799
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 885
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 840
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 841
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 842
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 843
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 844
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 845
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 846
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 847
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 201
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 220
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 221
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 222
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 223
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 224
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 225
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 226
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 227
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 228
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 229
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 230
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 231
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 232
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 233
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 234
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 235
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 236
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 237
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 238
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 239
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 240
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 241
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 242
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 243
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 244
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 245
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 246
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 50
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 51
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 52
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 53
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 54
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 56
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 57
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 58
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 59
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 980
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 981
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 982
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 983
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 118,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 984
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 985
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 986
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 988
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 989
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 990
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 991
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 992
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 993
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 994
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 530
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 531
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 532
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 534
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 535
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 537
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 538
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 539
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 540
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 541
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 542
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 543
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 544
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 545
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 546
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 547
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 548
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 110,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 549
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 247
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 248
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 249
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 250
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 251
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 252
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 253
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 254
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 255
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 256
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 257
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 258
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 259
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 260
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 261
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 262
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 263
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 264
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 265
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 266
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 267
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 268
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 820
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 821
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 822
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 823
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 824
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 825
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 826
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 827
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 828
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 829
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 830
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 104,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 831
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 995
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 996
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 997
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 998
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 999
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 350
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 351
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 352
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 354
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 355
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 356
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 357
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 358
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 359
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 360
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 361
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 362
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 363
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 364
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 365
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 366
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 367
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 368
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 369
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 716
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 717
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 718
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 719
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 720
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 721
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 722
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 723
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 724
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 725
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 726
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 727
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 728
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 729
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 850
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 851
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 852
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 853
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 855
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 856
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 857
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 859
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 860
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 863
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 864
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 865
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 900
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 901
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 902
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 903
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 904
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 905
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 906
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 907
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 908
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 910
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 911
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 912
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 913
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 914
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 915
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 916
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 917
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 918
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 919
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 920
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 921
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 922
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 923
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 924
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 925
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 926
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 927
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 928
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 930
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 931
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 932
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 933
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 934
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 935
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 936
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 937
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 938
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 939
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 940
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 941
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 942
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 943
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 944
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 945
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 946
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 947
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 948
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 949
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 950
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 951
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 952
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 953
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 954
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 955
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 956
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 957
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 958
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 959
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 960
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 961
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 800
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 801
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 802
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 803
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 804
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 805
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 806
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 807
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 808
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 809
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 810
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 811
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 812
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 813
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 814
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 815
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 816
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 60
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 61
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 62
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 63
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 64
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 65
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 66
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 67
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 68
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 69
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 200
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 202
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 203
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 204
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 205
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 569
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 888
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 197
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 198
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 199
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 320
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 321
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 322
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 323
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 324
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 325
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 326
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 327
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 328
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 329
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 330
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 331
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 332
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 333
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 334
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 335
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 336
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 337
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 338
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 339
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 341
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 342
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 344
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 346
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 347
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 349
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 300
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 301
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 302
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 303
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 304
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 305
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 306
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 307
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 308
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 309
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 310
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 311
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 312
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 313
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 314
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 315
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 316
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 317
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 318
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 319
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 398
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 399
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 967
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 968
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 500
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 501
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 502
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 503
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 504
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 505
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 506
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 507
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 508
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 509
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 510
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 511
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 512
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 513
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 514
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 515
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 516
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 520
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 521
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 522
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 523
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 524
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 525
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 526
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 527
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 528
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 832
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 833
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 834
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 835
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 836
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 837
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 838
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 600
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 601
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 602
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 603
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 604
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 605
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 606
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 607
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 608
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 609
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 610
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 611
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 612
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 613
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 614
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 615
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 616
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 617
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 618
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 619
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 620
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 622
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 623
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 624
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 625
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 626
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 627
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 628
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 629
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 460
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 461
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 462
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 463
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 464
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 465
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 466
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 467
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 468
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 469
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 470
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 471
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 472
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 473
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 474
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 475
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 476
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 477
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 478
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 479
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 660
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 661
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 662
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 664
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 665
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 666
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 667
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 668
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 669
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 670
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 671
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 672
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 673
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 674
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 675
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 676
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 677
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 678
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 679
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 400
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 401
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 402
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 403
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 404
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 405
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 406
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 407
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 408
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 409
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 410
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 411
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 412
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 413
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 414
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 415
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 416
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 417
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 418
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 420
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 421
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 422
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 423
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 424
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 425
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 426
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 427
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 700
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 701
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 703
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 704
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 705
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 706
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 707
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 708
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 710
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 711
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 712
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 713
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 714
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 10
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 11
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 12
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 13
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 14
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 15
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 16
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 17
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 18
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 19
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 20
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 21
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 22
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 23
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 24
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 25
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 26
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 27
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 55
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 206
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 207
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 208
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 209
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 210
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 211
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 212
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 214
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 215
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 216
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 217
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 218
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 219
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 39
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 40
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 41
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 42
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 43
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 44
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 45
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 46
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 47
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 48
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 49
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 480
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 481
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 482
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 483
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 484
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 485
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 486
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 487
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 488
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 489
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 490
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 491
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 492
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 493
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 494
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 495
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 496
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 497
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 498
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 499
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 550
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 551
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 553
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 554
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 555
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 556
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 557
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 558
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 559
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 560
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 561
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 562
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 563
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 564
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 565
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 566
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 567
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 630
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 631
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 633
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 634
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 635
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 636
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 637
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 638
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 639
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 640
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 641
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 644
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 645
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 646
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 647
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 648
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 649
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 650
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 651
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 652
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 653
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 654
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 655
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 656
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 657
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 658
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 386
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 387
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 388
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 389
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 390
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 391
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 392
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 393
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 394
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 395
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 396
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 397
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 590
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 591
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 592
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 593
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 594
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 595
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 596
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 597
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 598
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 599
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 270
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 271
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 272
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 273
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 274
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 275
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 276
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 277
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 278
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 279
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 280
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 281
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 282
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 283
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 284
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 285
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 286
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 287
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 288
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 289
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 580
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 581
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 582
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 583
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 584
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 585
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 586
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 587
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 588
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 680
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 681
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 683
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 684
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 685
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 686
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 687
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 688
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 689
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 690
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 691
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 692
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 693
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 30
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 31
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 32
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 33
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 34
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 35
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 36
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 37
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 38
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 70
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 71
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 72
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 73
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 74
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 75
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 76
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 77
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 78
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 79
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 80
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 81
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 82
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 83
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 84
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 85
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 86
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 87
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 88
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 89
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 870
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 871
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 873
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 874
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 875
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 876
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 877
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 878
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 879
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 880
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 881
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 882
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 883
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 884
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 889
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 890
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 891
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 893
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 894
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 895
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 897
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 898
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 5
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 100
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 101
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 102
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 103
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 104
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 105
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 106
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 107
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 108
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 109
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 110
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 111
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 112
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 113
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 114
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 115
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 116
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 117
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 118
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 119
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 120
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 121
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 122
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 123
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 124
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 125
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 126
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 127
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 128
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 129
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 130
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 131
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 132
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 133
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 134
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 135
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 136
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 137
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 138
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 139
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 140
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 141
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 142
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 143
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 144
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 145
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 146
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 147
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 148
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 149
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 430
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 431
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 432
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 433
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 434
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 435
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 436
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 437
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 438
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 439
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 440
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 441
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 442
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 443
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 444
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 445
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 446
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 447
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 448
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 449
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 450
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 451
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 452
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 453
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 454
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 455
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 456
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 457
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 458
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 459
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 730
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 731
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 734
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 735
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 736
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 737
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 738
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 739
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 740
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 741
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 743
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 744
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 745
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 746
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 747
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 748
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 749
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 970
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 971
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 972
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 973
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 974
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 975
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 976
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 977
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 978
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 979
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 150
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 151
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 152
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 153
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 154
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 155
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 156
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 157
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 158
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 159
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 160
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 161
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 162
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 163
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 164
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 165
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 166
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 167
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 168
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 169
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 170
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 171
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 172
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 173
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 174
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 175
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 176
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 177
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 178
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 179
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 180
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 181
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 182
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 183
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 184
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 185
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 186
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 187
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 188
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 189
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 190
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 191
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 192
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 193
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 194
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 195
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 196
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 28
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 29
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 290
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 291
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 292
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 293
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 294
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 295
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 296
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 297
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 298
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 299
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 570
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 571
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 572
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 573
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 574
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 575
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 576
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 577
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 370
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 371
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 372
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 373
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 374
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 375
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 376
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 377
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 378
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 379
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 380
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 381
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 382
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 383
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 384
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 385
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 733
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 750
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 751
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 752
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 753
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 754
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 755
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 756
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 757
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 758
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 759
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 760
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 761
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 762
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 763
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 764
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 765
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 766
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 767
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 768
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 769
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 770
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 772
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 773
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 774
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 775
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 776
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 777
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 778
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 779
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 780
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 781
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 782
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 783
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 784
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 785
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 786
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 787
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 788
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 789
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 790
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 791
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 792
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 793
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 794
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 795
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 796
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 797
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 798
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 799
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 885
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 840
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 841
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 842
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 843
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 844
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 845
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 846
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 847
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 201
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 220
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 221
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 222
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 223
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 224
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 225
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 226
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 227
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 228
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 229
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 230
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 231
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 232
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 233
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 234
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 235
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 236
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 237
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 238
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 239
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 240
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 241
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 242
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 243
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 244
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 245
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 246
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 50
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 51
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 52
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 53
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 54
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 56
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 57
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 58
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 59
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 980
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 981
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 982
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 983
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 148,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 984
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 985
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 986
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 988
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 989
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 990
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 991
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 992
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 993
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 994
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 530
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 531
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 532
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 534
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 535
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 537
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 538
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 539
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 540
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 541
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 542
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 543
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 544
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 545
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 546
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 547
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 548
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 138,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 549
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 247
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 248
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 249
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 250
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 251
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 252
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 253
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 254
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 255
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 256
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 257
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 258
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 259
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 260
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 261
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 262
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 263
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 264
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 265
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 266
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 267
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 268
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 820
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 821
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 822
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 823
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 824
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 825
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 826
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 827
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 828
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 829
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 830
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 130,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 831
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 995
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 996
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 997
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 998
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 999
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 350
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 351
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 352
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 354
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 355
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 356
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 357
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 358
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 359
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 360
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 361
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 362
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 363
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 364
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 365
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 366
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 367
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 368
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 369
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 716
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 717
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 718
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 719
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 720
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 721
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 722
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 723
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 724
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 725
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 726
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 727
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 728
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 729
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 850
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 851
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 852
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 853
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 855
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 856
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 857
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 859
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 860
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 863
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 864
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 865
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 900
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 901
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 902
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 903
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 904
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 905
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 906
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 907
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 908
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 910
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 911
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 912
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 913
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 914
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 915
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 916
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 917
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 918
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 919
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 920
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 921
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 922
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 923
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 924
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 925
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 926
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 927
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 928
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 930
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 931
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 932
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 933
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 934
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 935
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 936
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 937
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 938
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 939
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 940
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 941
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 942
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 943
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 944
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 945
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 946
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 947
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 948
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 949
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 950
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 951
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 952
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 953
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 954
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 955
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 956
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 957
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 958
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 959
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 960
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 961
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 800
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 801
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 802
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 803
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 804
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 805
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 806
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 807
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 808
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 809
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 810
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 811
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 812
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 813
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 814
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 815
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 816
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 60
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 61
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 62
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 63
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 64
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 65
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 66
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 67
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 68
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 69
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 200
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 202
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 203
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 204
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 205
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 569
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 888
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 197
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 198
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 199
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 320
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 321
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 322
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 323
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 324
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 325
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 326
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 327
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 328
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 329
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 330
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 331
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 332
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 333
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 334
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 335
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 336
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 337
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 338
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 339
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 341
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 342
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 344
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 346
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 347
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 349
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 300
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 301
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 302
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 303
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 304
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 305
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 306
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 307
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 308
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 309
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 310
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 311
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 312
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 313
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 314
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 315
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 316
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 317
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 318
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 319
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 398
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 399
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 967
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 968
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 500
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 501
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 502
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 503
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 504
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 505
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 506
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 507
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 508
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 509
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 510
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 511
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 512
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 513
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 514
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 515
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 516
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 520
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 521
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 522
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 523
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 524
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 525
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 526
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 527
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 528
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 832
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 833
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 834
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 835
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 836
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 837
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 838
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 600
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 601
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 602
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 603
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 604
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 605
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 606
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 607
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 608
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 609
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 610
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 611
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 612
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 613
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 614
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 615
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 616
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 617
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 618
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 619
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 620
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 622
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 623
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 624
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 625
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 626
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 627
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 628
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 629
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 460
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 461
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 462
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 463
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 464
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 465
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 466
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 467
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 468
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 469
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 470
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 471
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 472
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 473
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 474
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 475
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 476
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 477
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 478
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 479
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 660
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 661
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 662
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 664
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 665
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 666
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 667
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 668
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 669
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 670
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 671
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 672
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 673
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 674
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 675
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 676
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 677
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 678
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 679
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 400
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 401
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 402
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 403
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 404
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 405
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 406
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 407
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 408
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 409
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 410
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 411
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 412
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 413
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 414
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 415
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 416
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 417
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 418
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 420
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 421
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 422
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 423
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 424
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 425
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 426
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 427
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 700
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 701
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 703
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 704
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 705
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 706
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 707
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 708
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 710
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 711
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 712
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 713
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 714
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 10
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 11
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 12
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 13
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 14
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 15
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 16
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 17
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 18
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 19
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 20
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 21
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 22
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 23
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 24
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 25
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 26
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 27
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 55
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 206
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 207
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 208
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 209
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 210
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 211
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 212
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 214
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 215
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 216
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 217
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 218
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 219
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 39
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 40
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 41
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 42
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 43
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 44
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 45
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 46
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 47
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 48
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 49
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 480
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 481
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 482
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 483
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 484
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 485
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 486
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 487
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 488
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 489
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 490
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 491
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 492
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 493
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 494
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 495
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 496
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 497
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 498
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 499
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 550
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 551
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 553
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 554
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 555
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 556
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 557
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 558
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 559
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 560
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 561
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 562
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 563
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 564
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 565
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 566
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 567
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 630
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 631
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 633
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 634
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 635
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 636
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 637
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 638
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 639
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 640
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 641
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 644
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 645
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 646
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 647
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 648
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 649
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 650
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 651
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 652
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 653
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 654
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 655
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 656
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 657
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 658
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 386
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 387
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 388
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 389
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 390
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 391
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 392
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 393
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 394
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 395
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 396
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 397
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 590
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 591
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 592
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 593
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 594
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 595
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 596
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 597
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 598
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 599
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 270
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 271
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 272
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 273
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 274
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 275
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 276
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 277
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 278
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 279
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 280
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 281
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 282
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 283
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 284
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 285
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 286
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 287
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 288
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 289
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 580
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 581
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 582
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 583
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 584
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 585
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 586
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 587
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 588
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 680
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 681
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 683
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 684
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 685
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 686
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 687
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 688
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 689
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 690
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 691
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 692
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 693
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 30
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 31
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 32
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 33
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 34
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 35
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 36
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 37
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 38
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 70
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 71
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 72
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 73
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 74
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 75
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 76
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 77
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 78
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 79
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 80
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 81
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 82
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 83
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 84
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 85
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 86
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 87
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 88
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 89
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 870
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 871
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 873
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 874
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 875
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 876
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 877
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 878
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 879
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 880
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 881
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 882
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 883
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 884
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 889
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 890
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 891
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 893
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 894
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 895
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 897
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 898
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 5
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 100
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 101
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 102
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 103
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 104
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 105
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 106
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 107
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 108
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 109
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 110
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 111
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 112
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 113
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 114
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 115
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 116
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 117
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 118
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 119
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 120
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 121
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 122
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 123
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 124
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 125
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 126
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 127
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 128
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 129
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 130
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 131
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 132
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 133
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 134
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 135
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 136
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 137
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 138
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 139
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 140
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 141
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 142
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 143
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 144
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 145
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 146
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 147
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 148
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 149
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 430
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 431
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 432
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 433
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 434
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 435
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 436
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 437
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 438
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 439
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 440
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 441
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 442
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 443
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 444
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 445
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 446
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 447
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 448
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 449
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 450
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 451
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 452
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 453
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 454
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 455
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 456
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 457
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 458
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 459
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 730
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 731
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 734
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 735
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 736
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 737
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 738
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 739
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 740
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 741
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 743
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 744
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 745
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 746
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 747
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 748
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 749
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 970
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 971
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 972
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 973
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 974
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 975
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 976
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 977
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 978
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 979
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 150
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 151
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 152
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 153
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 154
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 155
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 156
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 157
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 158
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 159
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 160
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 161
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 162
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 163
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 164
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 165
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 166
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 167
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 168
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 169
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 170
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 171
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 172
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 173
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 174
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 175
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 176
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 177
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 178
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 179
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 180
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 181
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 182
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 183
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 184
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 185
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 186
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 187
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 188
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 189
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 190
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 191
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 192
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 193
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 194
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 195
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 196
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 28
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 29
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 290
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 291
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 292
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 293
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 294
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 295
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 296
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 297
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 298
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 299
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 570
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 571
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 572
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 573
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 574
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 575
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 576
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 577
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 370
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 371
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 372
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 373
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 374
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 375
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 376
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 377
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 378
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 379
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 380
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 381
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 382
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 383
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 384
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 385
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 733
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 750
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 751
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 752
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 753
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 754
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 755
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 756
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 757
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 758
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 759
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 760
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 761
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 762
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 763
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 764
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 765
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 766
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 767
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 768
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 769
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 770
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 772
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 773
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 774
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 775
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 776
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 777
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 778
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 779
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 780
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 781
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 782
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 783
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 784
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 785
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 786
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 787
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 788
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 789
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 790
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 791
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 792
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 793
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 794
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 795
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 796
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 797
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 798
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 799
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 885
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 840
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 841
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 842
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 843
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 844
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 845
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 846
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 847
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 201
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 220
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 221
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 222
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 223
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 224
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 225
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 226
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 227
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 228
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 229
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 230
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 231
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 232
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 233
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 234
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 235
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 236
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 237
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 238
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 239
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 240
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 241
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 242
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 243
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 244
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 245
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 246
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 50
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 51
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 52
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 53
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 54
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 56
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 57
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 58
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 59
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 980
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 981
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 982
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 983
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 212,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 984
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 985
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 986
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 988
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 989
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 990
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 991
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 992
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 993
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 994
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 530
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 531
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 532
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 534
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 535
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 537
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 538
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 539
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 540
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 541
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 542
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 543
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 544
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 545
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 546
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 547
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 548
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 198,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 549
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 247
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 248
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 249
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 250
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 251
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 252
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 253
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 254
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 255
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 256
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 257
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 258
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 259
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 260
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 261
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 262
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 263
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 264
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 265
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 266
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 267
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 268
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 820
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 821
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 822
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 823
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 824
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 825
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 826
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 827
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 828
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 829
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 830
  },
  {
    "ProductID": 42250,
    "Label": "MetLife Elite 3000",
    "Price": 187,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 831
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 995
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 996
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 997
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 998
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 999
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 350
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 351
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 352
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 354
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 355
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 356
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 357
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 358
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 359
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 360
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 361
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 362
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 363
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 364
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 365
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 366
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 367
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 368
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 369
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 716
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 717
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 718
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 719
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 720
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 721
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 722
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 723
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 724
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 725
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 726
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 727
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 728
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 729
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 850
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 851
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 852
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 853
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 855
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 856
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 857
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 859
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 860
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 863
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 864
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 865
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 900
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 901
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 902
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 903
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 904
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 905
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 906
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 907
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 908
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 910
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 911
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 912
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 913
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 914
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 915
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 916
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 917
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 918
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 919
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 920
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 921
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 922
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 923
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 924
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 925
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 926
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 927
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 928
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 930
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 931
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 932
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 933
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 934
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 935
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 936
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 937
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 938
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 939
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 940
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 941
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 942
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 943
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 944
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 945
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 946
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 947
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 948
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 949
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 950
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 951
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 952
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 953
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 954
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 955
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 956
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 957
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 958
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 959
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 960
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 961
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 800
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 801
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 802
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 803
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 804
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 805
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 806
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 807
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 808
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 809
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 810
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 811
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 812
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 813
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 814
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 815
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 816
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 60
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 61
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 62
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 63
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 64
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 65
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 66
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 67
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 68
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 69
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 200
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 202
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 203
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 204
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 205
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 569
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 888
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 197
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 198
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 199
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 320
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 321
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 322
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 323
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 324
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 325
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 326
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 327
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 328
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 329
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 330
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 331
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 332
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 333
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 334
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 335
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 336
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 337
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 338
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 339
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 341
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 342
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 344
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 346
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 347
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 349
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 300
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 301
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 302
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 303
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 304
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 305
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 306
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 307
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 308
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 309
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 310
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 311
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 312
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 313
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 314
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 315
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 316
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 317
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 318
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 319
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 398
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 399
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 967
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 968
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 500
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 501
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 502
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 503
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 504
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 505
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 506
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 507
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 508
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 509
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 510
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 511
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 512
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 513
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 514
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 515
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 516
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 520
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 521
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 522
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 523
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 524
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 525
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 526
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 527
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 528
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 832
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 833
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 834
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 835
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 836
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 837
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 838
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 600
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 601
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 602
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 603
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 604
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 605
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 606
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 607
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 608
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 609
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 610
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 611
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 612
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 613
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 614
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 615
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 616
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 617
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 618
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 619
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 620
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 622
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 623
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 624
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 625
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 626
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 627
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 628
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 629
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 460
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 461
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 462
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 463
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 464
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 465
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 466
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 467
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 468
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 469
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 470
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 471
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 472
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 473
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 474
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 475
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 476
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 477
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 478
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 479
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 660
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 661
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 662
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 664
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 665
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 666
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 667
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 668
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 669
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 670
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 671
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 672
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 673
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 674
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 675
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 676
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 677
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 678
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 679
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 400
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 401
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 402
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 403
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 404
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 405
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 406
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 407
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 408
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 409
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 410
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 411
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 412
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 413
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 414
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 415
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 416
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 417
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 418
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 420
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 421
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 422
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 423
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 424
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 425
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 426
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 427
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 700
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 701
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 703
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 704
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 705
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 706
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 707
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 708
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 710
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 711
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 712
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 713
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 714
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 10
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 11
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 12
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 13
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 14
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 15
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 16
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 17
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 18
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 19
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 20
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 21
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 22
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 23
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 24
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 25
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 26
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 27
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 55
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 206
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 207
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 208
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 209
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 210
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 211
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 212
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 214
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 215
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 216
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 217
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 218
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 219
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 39
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 40
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 41
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 42
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 43
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 44
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 45
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 46
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 47
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 48
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 49
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 480
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 481
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 482
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 483
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 484
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 485
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 486
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 487
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 488
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 489
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 490
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 491
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 492
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 493
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 494
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 495
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 496
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 497
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 498
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 499
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 550
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 551
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 553
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 554
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 555
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 556
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 557
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 558
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 559
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 560
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 561
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 562
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 563
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 564
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 565
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 566
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 567
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 630
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 631
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 633
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 634
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 635
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 636
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 637
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 638
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 639
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 640
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 641
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 644
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 645
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 646
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 647
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 648
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 649
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 650
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 651
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 652
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 653
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 654
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 655
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 656
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 657
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 658
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 386
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 387
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 388
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 389
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 390
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 391
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 392
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 393
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 394
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 395
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 396
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 397
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 590
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 591
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 592
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 593
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 594
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 595
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 596
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 597
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 598
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 599
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 270
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 271
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 272
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 273
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 274
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 275
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 276
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 277
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 278
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 279
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 280
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 281
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 282
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 283
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 284
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 285
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 286
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 287
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 288
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 289
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 580
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 581
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 582
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 583
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 584
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 585
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 586
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 587
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 588
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 680
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 681
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 683
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 684
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 685
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 686
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 687
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 688
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 689
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 690
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 691
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 692
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 693
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 30
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 31
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 32
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 33
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 34
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 35
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 36
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 37
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 38
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 70
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 71
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 72
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 73
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 74
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 75
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 76
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 77
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 78
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 79
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 80
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 81
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 82
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 83
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 84
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 85
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 86
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 87
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 88
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 89
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 870
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 871
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 873
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 874
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 875
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 876
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 877
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 878
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 879
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 880
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 881
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 882
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 883
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 884
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 889
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 890
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 891
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 893
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 894
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 895
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 897
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 898
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 5
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 100
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 101
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 102
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 103
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 104
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 105
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 106
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 107
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 108
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 109
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 110
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 111
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 112
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 113
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 114
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 115
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 116
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 117
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 118
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 119
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 120
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 121
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 122
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 123
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 124
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 125
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 126
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 127
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 128
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 129
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 130
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 131
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 132
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 133
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 134
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 135
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 136
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 137
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 138
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 139
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 140
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 141
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 142
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 143
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 144
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 145
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 146
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 147
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 148
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 149
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 430
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 431
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 432
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 433
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 434
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 435
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 436
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 437
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 438
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 439
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 440
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 441
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 442
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 443
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 444
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 445
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 446
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 447
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 448
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 449
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 450
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 451
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 452
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 453
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 454
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 455
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 456
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 457
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 458
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 459
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 730
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 731
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 734
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 735
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 736
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 737
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 738
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 739
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 740
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 741
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 743
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 744
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 745
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 746
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 747
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 748
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 749
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 970
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 971
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 972
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 973
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 974
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 975
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 976
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 977
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 978
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 979
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 150
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 151
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 152
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 153
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 154
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 155
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 156
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 157
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 158
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 159
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 160
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 161
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 162
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 163
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 164
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 165
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 166
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 167
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 168
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 169
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 170
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 171
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 172
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 173
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 174
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 175
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 176
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 177
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 178
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 179
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 180
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 181
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 182
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 183
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 184
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 185
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 186
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 187
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 188
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 189
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 190
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 191
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 192
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 193
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 194
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 195
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 196
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 28
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 29
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 290
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 291
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 292
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 293
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 294
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 295
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 296
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 297
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 298
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 299
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 570
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 571
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 572
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 573
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 574
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 575
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 576
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 577
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 370
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 371
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 372
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 373
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 374
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 375
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 376
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 377
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 378
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 379
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 380
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 381
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 382
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 383
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 384
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 385
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 733
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 750
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 751
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 752
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 753
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 754
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 755
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 756
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 757
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 758
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 759
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 760
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 761
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 762
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 763
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 764
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 765
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 766
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 767
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 768
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 769
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 770
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 772
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 773
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 774
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 775
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 776
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 777
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 778
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 779
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 780
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 781
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 782
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 783
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 784
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 785
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 786
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 787
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 788
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 789
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 790
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 791
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 792
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 793
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 794
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 795
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 796
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 797
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 798
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 799
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 885
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 840
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 841
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 842
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 843
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 844
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 845
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 846
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 847
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 201
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 220
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 221
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 222
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 223
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 224
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 225
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 226
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 227
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 228
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 229
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 230
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 231
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 232
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 233
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 234
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 235
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 236
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 237
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 238
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 239
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 240
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 241
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 242
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 243
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 244
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 245
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 246
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 50
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 51
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 52
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 53
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 54
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 56
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 57
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 58
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 59
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 980
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 981
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 982
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 983
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 252,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 984
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 985
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 986
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 988
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 989
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 990
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 991
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 992
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 993
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 994
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 530
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 531
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 532
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 534
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 535
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 537
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 538
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 539
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 540
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 541
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 542
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 543
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 544
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 545
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 546
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 547
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 548
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 234,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 549
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 247
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 248
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 249
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 250
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 251
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 252
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 253
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 254
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 255
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 256
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 257
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 258
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 259
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 260
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 261
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 262
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 263
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 264
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 265
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 266
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 267
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 268
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 820
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 821
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 822
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 823
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 824
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 825
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 826
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 827
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 828
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 829
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 830
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 223,
    "BenefitID": 21,
    "AmountOfPeople": "Family",
    "Zipcode": 831
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 995
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 996
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 997
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 998
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 999
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 350
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 351
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 352
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 354
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 355
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 356
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 357
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 358
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 359
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 360
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 361
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 362
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 363
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 364
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 365
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 366
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 367
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 368
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 369
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 716
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 717
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 718
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 719
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 720
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 721
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 722
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 723
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 724
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 725
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 726
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 727
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 728
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 729
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 850
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 851
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 852
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 853
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 855
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 856
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 857
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 859
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 860
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 863
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 864
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 865
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 900
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 901
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 902
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 903
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 904
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 905
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 906
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 907
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 908
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 910
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 911
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 912
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 913
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 914
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 915
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 916
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 917
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 918
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 919
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 920
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 921
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 922
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 923
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 924
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 925
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 926
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 927
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 928
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 930
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 931
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 932
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 933
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 934
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 935
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 936
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 937
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 938
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 939
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 940
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 941
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 942
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 943
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 944
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 945
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 946
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 947
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 948
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 949
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 950
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 951
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 952
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 953
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 954
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 955
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 956
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 957
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 958
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 959
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 960
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 961
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 800
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 801
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 802
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 803
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 804
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 805
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 806
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 807
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 808
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 809
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 810
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 811
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 812
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 813
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 814
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 815
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 816
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 60
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 61
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 62
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 63
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 64
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 65
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 66
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 67
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 68
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 69
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 200
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 202
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 203
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 204
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 205
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 569
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 888
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 197
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 198
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 199
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 320
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 321
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 322
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 323
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 324
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 325
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 326
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 327
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 328
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 329
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 330
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 331
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 332
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 333
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 334
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 335
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 336
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 337
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 338
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 339
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 341
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 342
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 344
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 346
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 347
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 349
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 300
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 301
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 302
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 303
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 304
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 305
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 306
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 307
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 308
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 309
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 310
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 311
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 312
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 313
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 314
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 315
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 316
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 317
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 318
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 319
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 398
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 399
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 967
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 968
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 500
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 501
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 502
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 503
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 504
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 505
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 506
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 507
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 508
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 509
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 510
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 511
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 512
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 513
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 514
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 515
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 516
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 520
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 521
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 522
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 523
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 524
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 525
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 526
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 527
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 528
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 832
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 833
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 834
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 835
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 836
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 837
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 838
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 600
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 601
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 602
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 603
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 604
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 605
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 606
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 607
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 608
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 609
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 610
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 611
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 612
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 613
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 614
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 615
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 616
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 617
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 618
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 619
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 620
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 622
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 623
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 624
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 625
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 626
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 627
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 628
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 629
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 460
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 461
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 462
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 463
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 464
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 465
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 466
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 467
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 468
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 469
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 470
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 471
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 472
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 473
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 474
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 475
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 476
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 477
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 478
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 479
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 660
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 661
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 662
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 664
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 665
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 666
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 667
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 668
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 669
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 670
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 671
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 672
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 673
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 674
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 675
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 676
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 677
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 678
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 679
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 400
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 401
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 402
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 403
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 404
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 405
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 406
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 407
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 408
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 409
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 410
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 411
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 412
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 413
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 414
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 415
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 416
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 417
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 418
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 420
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 421
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 422
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 423
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 424
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 425
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 426
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 427
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 700
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 701
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 703
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 704
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 705
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 706
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 707
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 708
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 710
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 711
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 712
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 713
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 714
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 10
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 11
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 12
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 13
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 14
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 15
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 16
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 17
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 18
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 19
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 20
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 21
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 22
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 23
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 24
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 25
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 26
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 27
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 55
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 206
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 207
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 208
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 209
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 210
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 211
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 212
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 214
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 215
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 216
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 217
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 218
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 219
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 39
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 40
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 41
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 42
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 43
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 44
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 45
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 46
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 47
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 48
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 49
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 480
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 481
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 482
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 483
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 484
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 485
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 486
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 487
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 488
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 489
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 490
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 491
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 492
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 493
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 494
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 495
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 496
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 497
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 498
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 499
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 550
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 551
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 553
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 554
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 555
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 556
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 557
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 558
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 559
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 560
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 561
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 562
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 563
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 564
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 565
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 566
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 567
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 630
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 631
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 633
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 634
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 635
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 636
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 637
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 638
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 639
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 640
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 641
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 644
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 645
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 646
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 647
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 648
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 649
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 650
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 651
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 652
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 653
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 654
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 655
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 656
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 657
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 658
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 386
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 387
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 388
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 389
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 390
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 391
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 392
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 393
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 394
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 395
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 396
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 397
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 590
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 591
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 592
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 593
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 594
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 595
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 596
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 597
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 598
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 599
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 270
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 271
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 272
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 273
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 274
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 275
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 276
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 277
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 278
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 279
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 280
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 281
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 282
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 283
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 284
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 285
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 286
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 287
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 288
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 289
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 580
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 581
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 582
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 583
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 584
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 585
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 586
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 587
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 588
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 680
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 681
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 683
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 684
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 685
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 686
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 687
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 688
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 689
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 690
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 691
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 692
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 693
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 30
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 31
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 32
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 33
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 34
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 35
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 36
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 37
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 38
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 70
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 71
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 72
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 73
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 74
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 75
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 76
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 77
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 78
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 79
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 80
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 81
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 82
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 83
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 84
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 85
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 86
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 87
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 88
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 89
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 870
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 871
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 873
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 874
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 875
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 876
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 877
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 878
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 879
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 880
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 881
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 882
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 883
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 884
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 889
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 890
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 891
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 893
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 894
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 895
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 897
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 898
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 5
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 100
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 101
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 102
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 103
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 104
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 105
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 106
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 107
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 108
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 109
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 110
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 111
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 112
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 113
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 114
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 115
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 116
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 117
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 118
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 119
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 120
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 121
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 122
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 123
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 124
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 125
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 126
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 127
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 128
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 129
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 130
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 131
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 132
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 133
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 134
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 135
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 136
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 137
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 138
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 139
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 140
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 141
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 142
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 143
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 144
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 145
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 146
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 147
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 148
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 149
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 430
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 431
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 432
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 433
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 434
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 435
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 436
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 437
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 438
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 439
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 440
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 441
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 442
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 443
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 444
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 445
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 446
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 447
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 448
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 449
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 450
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 451
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 452
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 453
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 454
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 455
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 456
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 457
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 458
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 459
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 730
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 731
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 734
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 735
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 736
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 737
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 738
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 739
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 740
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 741
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 743
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 744
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 745
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 746
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 747
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 748
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 749
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 970
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 971
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 972
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 973
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 974
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 975
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 976
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 977
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 978
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 979
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 150
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 151
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 152
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 153
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 154
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 155
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 156
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 157
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 158
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 159
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 160
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 161
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 162
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 163
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 164
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 165
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 166
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 167
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 168
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 169
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 170
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 171
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 172
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 173
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 174
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 175
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 176
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 177
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 178
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 179
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 180
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 181
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 182
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 183
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 184
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 185
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 186
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 187
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 188
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 189
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 190
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 191
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 192
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 193
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 194
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 195
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 196
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 28
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 29
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 290
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 291
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 292
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 293
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 294
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 295
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 296
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 297
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 298
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 299
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 570
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 571
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 572
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 573
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 574
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 575
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 576
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 577
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 370
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 371
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 372
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 373
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 374
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 375
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 376
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 377
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 378
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 379
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 380
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 381
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 382
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 383
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 384
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 385
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 733
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 750
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 751
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 752
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 753
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 754
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 755
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 756
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 757
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 758
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 759
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 760
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 761
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 762
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 763
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 764
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 765
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 766
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 767
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 768
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 769
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 770
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 772
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 773
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 774
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 775
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 776
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 777
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 778
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 779
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 780
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 781
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 782
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 783
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 784
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 785
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 786
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 787
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 788
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 789
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 790
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 791
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 792
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 793
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 794
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 795
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 796
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 797
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 798
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 799
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 885
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 840
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 841
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 842
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 843
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 844
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 845
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 846
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 847
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 201
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 220
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 221
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 222
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 223
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 224
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 225
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 226
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 227
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 228
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 229
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 230
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 231
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 232
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 233
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 234
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 235
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 236
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 237
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 238
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 239
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 240
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 241
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 242
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 243
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 244
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 245
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 246
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 50
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 51
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 52
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 53
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 54
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 56
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 57
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 58
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 59
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 980
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 981
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 982
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 983
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 175,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 984
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 985
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 986
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 988
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 989
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 990
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 991
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 992
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 993
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 994
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 530
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 531
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 532
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 534
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 535
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 537
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 538
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 539
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 540
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 541
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 542
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 543
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 544
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 545
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 546
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 547
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 548
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 163,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 549
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 247
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 248
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 249
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 250
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 251
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 252
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 253
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 254
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 255
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 256
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 257
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 258
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 259
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 260
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 261
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 262
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 263
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 264
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 265
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 266
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 267
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 268
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 820
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 821
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 822
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 823
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 824
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 825
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 826
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 827
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 828
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 829
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 830
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 155,
    "BenefitID": 677,
    "AmountOfPeople": "Member plus Children",
    "Zipcode": 831
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 995
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 996
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 997
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 998
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 999
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 350
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 351
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 352
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 354
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 355
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 356
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 357
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 358
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 359
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 360
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 361
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 362
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 363
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 364
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 365
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 366
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 367
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 368
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 369
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 716
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 717
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 718
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 719
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 720
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 721
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 722
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 723
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 724
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 725
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 726
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 727
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 728
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 729
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 850
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 851
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 852
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 853
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 855
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 856
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 857
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 859
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 860
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 863
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 864
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 865
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 900
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 901
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 902
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 903
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 904
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 905
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 906
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 907
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 908
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 910
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 911
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 912
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 913
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 914
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 915
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 916
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 917
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 918
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 919
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 920
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 921
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 922
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 923
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 924
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 925
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 926
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 927
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 928
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 930
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 931
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 932
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 933
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 934
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 935
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 936
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 937
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 938
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 939
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 940
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 941
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 942
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 943
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 944
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 945
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 946
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 947
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 948
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 949
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 950
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 951
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 952
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 953
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 954
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 955
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 956
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 957
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 958
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 959
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 960
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 961
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 800
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 801
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 802
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 803
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 804
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 805
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 806
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 807
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 808
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 809
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 810
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 811
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 812
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 813
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 814
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 815
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 816
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 60
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 61
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 62
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 63
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 64
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 65
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 66
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 67
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 68
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 69
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 200
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 202
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 203
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 204
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 205
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 569
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 888
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 197
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 198
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 199
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 320
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 321
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 322
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 323
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 324
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 325
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 326
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 327
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 328
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 329
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 330
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 331
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 332
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 333
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 334
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 335
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 336
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 337
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 338
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 339
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 341
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 342
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 344
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 346
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 347
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 349
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 300
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 301
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 302
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 303
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 304
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 305
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 306
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 307
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 308
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 309
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 310
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 311
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 312
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 313
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 314
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 315
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 316
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 317
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 318
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 319
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 398
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 399
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 967
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 968
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 500
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 501
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 502
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 503
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 504
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 505
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 506
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 507
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 508
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 509
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 510
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 511
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 512
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 513
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 514
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 515
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 516
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 520
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 521
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 522
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 523
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 524
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 525
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 526
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 527
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 528
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 832
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 833
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 834
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 835
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 836
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 837
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 838
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 600
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 601
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 602
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 603
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 604
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 605
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 606
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 607
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 608
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 609
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 610
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 611
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 612
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 613
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 614
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 615
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 616
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 617
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 618
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 619
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 620
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 622
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 623
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 624
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 625
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 626
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 627
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 628
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 629
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 460
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 461
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 462
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 463
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 464
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 465
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 466
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 467
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 468
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 469
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 470
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 471
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 472
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 473
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 474
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 475
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 476
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 477
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 478
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 479
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 660
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 661
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 662
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 664
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 665
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 666
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 667
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 668
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 669
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 670
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 671
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 672
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 673
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 674
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 675
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 676
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 677
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 678
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 679
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 400
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 401
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 402
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 403
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 404
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 405
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 406
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 407
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 408
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 409
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 410
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 411
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 412
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 413
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 414
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 415
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 416
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 417
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 418
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 420
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 421
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 422
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 423
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 424
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 425
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 426
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 427
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 700
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 701
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 703
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 704
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 705
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 706
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 707
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 708
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 710
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 711
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 712
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 713
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 714
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 10
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 11
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 12
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 13
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 14
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 15
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 16
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 17
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 18
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 19
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 20
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 21
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 22
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 23
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 24
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 25
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 26
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 27
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 55
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 206
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 207
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 208
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 209
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 210
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 211
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 212
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 214
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 215
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 216
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 217
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 218
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 219
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 39
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 40
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 41
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 42
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 43
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 44
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 45
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 46
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 47
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 48
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 49
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 480
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 481
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 482
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 483
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 484
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 485
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 486
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 487
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 488
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 489
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 490
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 491
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 492
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 493
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 494
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 495
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 496
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 497
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 498
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 499
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 550
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 551
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 553
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 554
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 555
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 556
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 557
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 558
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 559
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 560
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 561
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 562
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 563
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 564
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 565
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 566
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 567
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 630
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 631
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 633
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 634
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 635
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 636
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 637
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 638
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 639
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 640
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 641
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 644
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 645
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 646
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 647
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 648
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 649
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 650
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 651
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 652
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 653
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 654
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 655
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 656
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 657
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 658
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 386
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 387
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 388
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 389
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 390
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 391
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 392
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 393
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 394
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 395
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 396
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 397
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 590
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 591
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 592
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 593
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 594
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 595
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 596
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 597
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 598
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 599
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 270
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 271
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 272
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 273
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 274
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 275
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 276
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 277
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 278
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 279
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 280
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 281
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 282
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 283
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 284
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 285
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 286
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 287
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 288
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 289
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 580
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 581
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 582
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 583
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 584
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 585
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 586
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 587
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 588
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 680
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 681
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 683
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 684
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 685
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 686
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 687
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 688
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 689
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 690
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 691
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 692
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 693
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 30
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 31
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 32
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 33
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 34
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 35
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 36
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 37
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 38
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 70
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 71
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 72
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 73
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 74
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 75
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 76
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 77
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 78
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 79
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 80
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 81
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 82
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 83
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 84
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 85
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 86
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 87
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 88
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 89
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 870
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 871
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 873
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 874
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 875
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 876
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 877
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 878
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 879
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 880
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 881
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 882
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 883
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 884
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 889
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 890
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 891
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 893
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 894
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 895
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 897
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 898
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 5
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 100
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 101
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 102
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 103
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 104
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 105
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 106
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 107
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 108
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 109
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 110
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 111
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 112
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 113
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 114
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 115
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 116
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 117
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 118
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 119
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 120
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 121
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 122
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 123
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 124
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 125
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 126
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 127
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 128
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 129
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 130
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 131
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 132
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 133
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 134
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 135
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 136
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 137
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 138
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 139
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 140
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 141
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 142
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 143
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 144
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 145
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 146
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 147
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 148
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 149
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 430
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 431
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 432
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 433
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 434
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 435
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 436
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 437
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 438
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 439
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 440
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 441
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 442
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 443
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 444
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 445
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 446
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 447
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 448
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 449
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 450
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 451
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 452
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 453
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 454
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 455
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 456
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 457
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 458
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 459
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 730
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 731
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 734
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 735
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 736
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 737
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 738
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 739
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 740
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 741
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 743
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 744
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 745
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 746
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 747
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 748
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 749
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 970
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 971
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 972
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 973
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 974
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 975
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 976
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 977
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 978
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 979
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 150
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 151
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 152
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 153
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 154
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 155
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 156
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 157
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 158
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 159
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 160
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 161
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 162
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 163
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 164
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 165
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 166
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 167
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 168
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 169
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 170
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 171
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 172
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 173
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 174
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 175
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 176
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 177
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 178
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 179
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 180
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 181
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 182
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 183
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 184
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 185
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 186
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 187
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 188
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 189
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 190
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 191
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 192
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 193
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 194
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 195
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 196
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 28
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 29
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 290
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 291
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 292
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 293
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 294
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 295
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 296
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 297
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 298
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 299
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 570
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 571
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 572
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 573
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 574
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 575
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 576
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 577
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 370
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 371
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 372
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 373
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 374
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 375
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 376
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 377
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 378
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 379
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 380
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 381
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 382
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 383
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 384
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 385
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 733
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 750
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 751
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 752
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 753
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 754
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 755
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 756
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 757
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 758
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 759
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 760
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 761
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 762
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 763
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 764
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 765
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 766
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 767
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 768
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 769
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 770
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 772
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 773
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 774
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 775
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 776
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 777
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 778
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 779
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 780
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 781
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 782
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 783
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 784
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 785
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 786
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 787
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 788
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 789
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 790
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 791
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 792
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 793
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 794
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 795
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 796
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 797
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 798
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 799
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 885
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 840
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 841
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 842
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 843
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 844
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 845
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 846
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 847
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 201
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 220
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 221
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 222
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 223
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 224
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 225
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 226
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 227
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 228
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 229
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 230
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 231
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 232
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 233
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 234
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 235
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 236
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 237
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 238
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 239
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 240
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 241
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 242
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 243
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 244
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 245
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 246
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 50
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 51
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 52
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 53
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 54
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 56
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 57
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 58
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 59
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 980
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 981
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 982
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 983
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 140,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 984
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 985
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 986
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 988
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 989
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 990
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 991
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 992
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 993
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 994
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 530
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 531
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 532
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 534
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 535
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 537
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 538
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 539
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 540
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 541
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 542
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 543
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 544
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 545
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 546
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 547
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 548
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 130,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 549
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 247
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 248
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 249
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 250
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 251
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 252
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 253
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 254
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 255
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 256
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 257
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 258
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 259
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 260
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 261
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 262
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 263
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 264
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 265
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 266
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 267
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 268
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 820
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 821
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 822
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 823
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 824
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 825
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 826
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 827
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 828
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 829
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 830
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 124,
    "BenefitID": 41,
    "AmountOfPeople": "Member plus Spouse",
    "Zipcode": 831
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 995
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 996
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 997
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 998
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 999
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 350
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 351
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 352
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 354
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 355
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 356
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 357
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 358
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 359
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 360
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 361
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 362
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 363
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 364
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 365
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 366
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 367
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 368
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 369
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 716
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 717
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 718
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 719
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 720
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 721
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 722
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 723
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 724
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 725
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 726
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 727
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 728
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 729
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 850
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 851
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 852
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 853
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 855
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 856
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 857
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 859
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 860
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 863
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 864
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 865
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 900
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 901
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 902
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 903
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 904
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 905
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 906
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 907
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 908
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 910
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 911
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 912
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 913
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 914
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 915
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 916
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 917
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 918
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 919
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 920
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 921
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 922
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 923
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 924
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 925
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 926
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 927
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 928
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 930
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 931
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 932
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 933
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 934
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 935
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 936
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 937
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 938
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 939
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 940
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 941
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 942
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 943
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 944
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 945
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 946
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 947
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 948
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 949
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 950
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 951
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 952
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 953
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 954
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 955
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 956
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 957
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 958
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 959
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 960
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 961
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 800
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 801
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 802
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 803
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 804
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 805
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 806
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 807
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 808
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 809
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 810
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 811
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 812
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 813
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 814
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 815
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 816
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 60
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 61
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 62
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 63
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 64
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 65
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 66
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 67
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 68
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 69
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 200
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 202
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 203
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 204
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 205
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 569
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 888
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 197
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 198
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 199
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 320
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 321
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 322
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 323
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 324
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 325
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 326
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 327
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 328
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 329
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 330
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 331
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 332
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 333
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 334
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 335
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 336
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 337
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 338
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 339
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 341
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 342
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 344
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 346
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 347
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 349
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 300
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 301
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 302
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 303
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 304
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 305
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 306
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 307
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 308
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 309
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 310
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 311
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 312
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 313
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 314
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 315
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 316
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 317
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 318
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 319
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 398
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 399
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 967
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 968
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 500
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 501
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 502
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 503
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 504
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 505
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 506
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 507
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 508
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 509
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 510
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 511
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 512
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 513
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 514
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 515
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 516
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 520
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 521
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 522
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 523
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 524
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 525
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 526
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 527
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 528
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 832
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 833
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 834
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 835
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 836
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 837
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 838
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 600
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 601
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 602
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 603
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 604
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 605
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 606
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 607
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 608
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 609
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 610
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 611
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 612
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 613
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 614
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 615
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 616
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 617
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 618
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 619
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 620
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 622
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 623
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 624
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 625
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 626
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 627
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 628
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 629
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 460
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 461
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 462
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 463
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 464
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 465
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 466
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 467
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 468
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 469
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 470
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 471
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 472
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 473
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 474
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 475
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 476
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 477
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 478
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 479
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 660
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 661
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 662
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 664
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 665
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 666
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 667
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 668
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 669
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 670
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 671
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 672
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 673
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 674
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 675
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 676
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 677
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 678
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 679
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 400
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 401
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 402
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 403
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 404
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 405
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 406
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 407
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 408
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 409
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 410
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 411
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 412
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 413
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 414
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 415
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 416
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 417
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 418
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 420
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 421
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 422
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 423
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 424
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 425
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 426
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 427
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 700
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 701
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 703
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 704
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 705
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 706
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 707
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 708
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 710
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 711
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 712
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 713
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 714
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 10
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 11
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 12
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 13
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 14
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 15
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 16
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 17
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 18
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 19
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 20
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 21
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 22
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 23
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 24
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 25
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 26
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 27
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 55
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 206
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 207
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 208
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 209
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 210
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 211
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 212
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 214
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 215
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 216
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 217
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 218
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 219
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 39
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 40
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 41
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 42
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 43
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 44
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 45
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 46
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 47
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 48
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 49
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 480
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 481
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 482
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 483
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 484
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 485
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 486
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 487
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 488
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 489
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 490
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 491
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 492
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 493
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 494
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 495
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 496
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 497
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 498
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 499
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 550
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 551
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 553
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 554
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 555
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 556
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 557
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 558
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 559
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 560
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 561
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 562
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 563
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 564
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 565
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 566
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 567
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 630
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 631
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 633
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 634
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 635
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 636
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 637
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 638
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 639
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 640
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 641
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 644
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 645
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 646
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 647
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 648
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 649
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 650
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 651
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 652
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 653
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 654
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 655
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 656
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 657
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 658
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 386
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 387
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 388
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 389
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 390
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 391
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 392
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 393
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 394
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 395
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 396
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 397
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 590
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 591
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 592
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 593
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 594
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 595
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 596
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 597
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 598
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 599
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 270
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 271
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 272
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 273
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 274
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 275
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 276
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 277
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 278
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 279
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 280
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 281
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 282
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 283
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 284
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 285
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 286
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 287
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 288
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 289
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 580
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 581
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 582
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 583
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 584
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 585
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 586
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 587
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 588
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 680
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 681
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 683
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 684
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 685
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 686
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 687
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 688
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 689
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 690
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 691
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 692
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 693
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 30
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 31
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 32
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 33
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 34
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 35
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 36
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 37
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 38
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 70
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 71
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 72
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 73
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 74
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 75
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 76
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 77
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 78
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 79
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 80
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 81
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 82
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 83
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 84
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 85
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 86
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 87
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 88
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 89
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 870
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 871
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 873
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 874
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 875
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 876
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 877
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 878
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 879
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 880
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 881
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 882
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 883
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 884
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 889
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 890
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 891
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 893
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 894
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 895
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 897
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 898
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 5
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 100
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 101
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 102
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 103
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 104
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 105
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 106
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 107
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 108
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 109
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 110
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 111
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 112
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 113
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 114
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 115
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 116
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 117
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 118
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 119
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 120
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 121
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 122
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 123
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 124
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 125
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 126
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 127
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 128
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 129
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 130
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 131
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 132
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 133
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 134
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 135
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 136
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 137
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 138
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 139
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 140
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 141
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 142
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 143
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 144
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 145
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 146
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 147
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 148
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 149
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 430
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 431
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 432
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 433
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 434
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 435
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 436
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 437
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 438
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 439
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 440
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 441
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 442
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 443
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 444
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 445
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 446
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 447
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 448
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 449
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 450
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 451
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 452
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 453
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 454
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 455
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 456
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 457
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 458
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 459
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 730
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 731
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 734
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 735
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 736
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 737
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 738
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 739
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 740
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 741
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 743
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 744
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 745
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 746
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 747
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 748
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 749
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 970
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 971
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 972
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 973
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 974
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 975
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 976
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 977
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 978
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 979
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 150
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 151
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 152
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 153
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 154
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 155
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 156
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 157
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 158
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 159
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 160
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 161
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 162
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 163
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 164
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 165
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 166
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 167
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 168
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 169
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 170
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 171
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 172
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 173
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 174
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 175
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 176
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 177
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 178
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 179
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 180
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 181
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 182
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 183
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 184
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 185
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 186
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 187
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 188
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 189
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 190
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 191
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 192
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 193
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 194
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 195
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 196
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 28
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 29
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 290
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 291
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 292
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 293
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 294
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 295
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 296
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 297
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 298
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 299
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 570
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 571
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 572
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 573
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 574
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 575
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 576
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 577
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 370
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 371
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 372
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 373
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 374
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 375
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 376
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 377
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 378
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 379
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 380
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 381
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 382
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 383
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 384
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 385
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 733
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 750
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 751
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 752
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 753
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 754
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 755
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 756
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 757
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 758
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 759
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 760
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 761
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 762
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 763
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 764
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 765
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 766
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 767
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 768
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 769
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 770
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 772
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 773
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 774
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 775
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 776
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 777
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 778
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 779
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 780
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 781
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 782
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 783
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 784
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 785
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 786
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 787
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 788
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 789
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 790
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 791
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 792
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 793
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 794
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 795
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 796
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 797
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 798
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 799
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 885
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 840
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 841
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 842
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 843
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 844
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 845
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 846
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 847
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 201
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 220
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 221
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 222
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 223
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 224
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 225
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 226
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 227
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 228
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 229
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 230
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 231
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 232
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 233
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 234
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 235
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 236
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 237
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 238
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 239
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 240
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 241
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 242
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 243
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 244
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 245
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 246
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 50
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 51
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 52
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 53
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 54
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 56
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 57
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 58
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 59
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 980
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 981
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 982
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 983
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 70,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 984
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 985
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 986
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 988
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 989
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 990
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 991
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 992
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 993
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 994
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 530
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 531
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 532
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 534
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 535
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 537
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 538
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 539
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 540
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 541
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 542
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 543
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 544
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 545
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 546
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 547
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 548
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 65,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 549
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 247
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 248
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 249
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 250
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 251
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 252
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 253
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 254
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 255
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 256
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 257
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 258
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 259
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 260
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 261
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 262
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 263
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 264
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 265
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 266
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 267
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 268
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 820
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 821
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 822
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 823
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 824
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 825
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 826
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 827
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 828
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 829
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 830
  },
  {
    "ProductID": 42251,
    "Label": "MetLife Elite 5000",
    "Price": 62,
    "BenefitID": 51,
    "AmountOfPeople": "Member",
    "Zipcode": 831
  }
];

export default eliteDentalData;
