export const visionData = [
  /*
  {
    ProductID: 39651,
    Price: 11,
    BenefitID: 51,
    AmountOfPeople: "Member",
    Label: "NCD - Vision OR & NY",
    State: "NY",
  },
  */
  {
    ProductID: 39651,
    Price: 12,
    BenefitID: 51,
    AmountOfPeople: "Member",
    Label: "NCD - Vision OR",
    State: "OR",
  },
  /*
  {
    ProductID: 39651,
    Price: 16,
    BenefitID: 450,
    AmountOfPeople: "Member + 1",
    Label: "NCD - Vision OR & NY",
    State: "NY",
  },
  */
  {
    ProductID: 39651,
    Price: 17,
    BenefitID: 450,
    AmountOfPeople: "Member + 1",
    Label: "NCD - Vision OR",
    State: "OR",
  },
  /*
  {
    ProductID: 39651,
    Price: 18,
    BenefitID: 21,
    AmountOfPeople: "Family",
    Label: "NCD - Vision OR & NY",
    State: "NY",
  },
  */
  {
    ProductID: 39651,
    Price: 19,
    BenefitID: 21,
    AmountOfPeople: "Family",
    Label: "NCD - Vision OR",
    State: "OR",
  },
  {
    ProductID: 38593,
    Price: 11,
    BenefitID: 51,
    AmountOfPeople: "Member",
    Label: "VSP Preferred Plan by NCD",
    State: "Default",
  },
  {
    ProductID: 38593,
    Price: 15,
    BenefitID: 450,
    AmountOfPeople: "Member + 1",
    Label: "VSP Preferred Plan by NCD",
    State: "Default",
  },
  {
    ProductID: 38593,
    Price: 20,
    BenefitID: 21,
    AmountOfPeople: "Family",
    Label: "VSP Preferred Plan by NCD",
    State: "Default",
  },
];
