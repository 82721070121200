import React, { FC, useEffect, useState, useCallback } from "react";
import cx from "classnames";
import Dependent from "../../../models/Dependent";
import {
  dateIsValid,
  formatDate,
  formatDateString,
  getMaxDate,
  getMaxDateChildren,
  getMinDate,
} from "../../../utils";
import Button from "../../Button";
import Member from "../../../models/Member";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";
import { CoverageType } from "../../../enums";

type DependentListProps = {
  index: number;
  dependent: Dependent;
  dependents: Dependent[];
  member: Member;
  setDependents: React.Dispatch<React.SetStateAction<Dependent[]>>;
  amountOfDependents: number;
  setAmountOfDependents: React.Dispatch<React.SetStateAction<number>>;
  spouseSelected: Boolean;
  amountOfDependentsType: string;
  setSpouseSelected: React.Dispatch<React.SetStateAction<Boolean>>;
  setErrorList: React.Dispatch<React.SetStateAction<Boolean[]>>;
  errorList: Boolean[];
  missingFields: { [field: string]: boolean };
  submitted: boolean;
};

const DependentList: FC<DependentListProps> = ({
  index,
  dependent,
  dependents,
  member,
  setSpouseSelected,
  spouseSelected,
  amountOfDependentsType,
  setDependents,
  setAmountOfDependents,
  amountOfDependents,
  setErrorList,
  errorList,
  missingFields,
  submitted,
}: DependentListProps) => {
  const [error, setError] = useState("");
  const [date, setDate] = useState<Date>();

  useEffect(() => {
    if (amountOfDependentsType === CoverageType.MemberPlusSpouse)
      updateDependent("relationship", "Spouse");
    else if (amountOfDependentsType === CoverageType.MemberPlusChildren)
      updateDependent("relationship", "Child");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfDependentsType]);

  useEffect(() => {
    if (dependent.dateOfBirth) {
      if (new Date(dependent.dateOfBirth) !== date) {
        setDate(new Date(dependent.dateOfBirth));
      }
    }
  }, [dependent]);

  useEffect(() => {
    switch (amountOfDependentsType) {
      case CoverageType.MemberPlusSpouse:
        updateDependent("relationship", "Spouse");
        break;
      case CoverageType.MemberPlusChildren:
        updateDependent("relationship", "Child");
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDate = useCallback(
    (date: Date, relationship: string) => {
      if (date > new Date()) {
        setError("Date can't be further than today");
        return false;
      }
      if (relationship === "Spouse") {
        if (date > new Date(getMaxDate().replace(/-/g, "/"))) {
          setError("A Spouse dependent must be 18 years or older");
          let tempErrorList = [...errorList];
          tempErrorList[index] = true;
          setErrorList(tempErrorList);
          return false;
        }
      } else if (relationship === "Child") {
        if (date < new Date(member.dateOfBirth || "")) {
          setError("A Child dependent must not be older than the user");
          let tempErrorList = [...errorList];
          tempErrorList[index] = true;
          setErrorList(tempErrorList);
          return false;
        } else if (
          date > new Date(getMaxDateChildren().replace(/-/g, "/")) ||
          date < new Date(getMinDate().replace(/-/g, "/"))
        ) {
          setError("A Child dependent must be between the ages of 0 and 25");
          let tempErrorList = [...errorList];
          tempErrorList[index] = true;
          setErrorList(tempErrorList);
          return false;
        }
      }
      setError("");
      let tempErrorList = [...errorList];
      tempErrorList[index] = false;
      setErrorList(tempErrorList);
      return true;
    },
    [errorList, index, setErrorList]
  );

  const updateDependent = useCallback(
    (field: string, value: any) => {
      setDependents((current) => {
        const fullDependents = [...current];
        const activeDependent = fullDependents.findIndex(
          (dp) => dp.id === dependent.id
        );
        if (activeDependent < 0) return current;
        fullDependents[activeDependent] = {
          ...fullDependents[activeDependent],
          [field]: value,
        };
        return fullDependents;
      });
    },
    [dependent, setDependents]
  );

  const removeButtonHandler = useCallback(() => {
    setDependents((current) => {
      return current?.filter((value) => value?.id !== dependent?.id);
    });
    if (amountOfDependentsType === CoverageType.Family)
      setSpouseSelected(false);
    setAmountOfDependents(amountOfDependents - 1);
  }, [
    setDependents,
    dependent,
    setSpouseSelected,
    setAmountOfDependents,
    amountOfDependents,
    amountOfDependentsType,
  ]);

  const handleRelationshipChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const cast = event.target;
      if (cast.value === "Spouse" && dependent.relationship !== "Spouse") {
        setSpouseSelected(true);
      }

      if (cast.value !== "Spouse" && dependent.relationship === "Spouse") {
        setSpouseSelected(false);
      }
      updateDependent("relationship", cast.value);

      if (dependent.dateOfBirth) {
        handleChangeDate(new Date(dependent.dateOfBirth), cast.value);
      }

      //if (submitted) validateFullPage()
    },
    [
      dependent.dateOfBirth,
      dependent.relationship,
      handleChangeDate,
      setSpouseSelected,
      submitted,
      updateDependent,
    ]
  );

  return (
    <>
      <div
        className={cx(
          "flex w-full flex-col",
          "items-start justify-start gap-4",
          "xl:w-auto xl:flex-row"
        )}
      >
        <div className="flex w-full flex-col xl:w-auto">
          <label className="text-xl font-bold text-navyBlue xl:mb-3">
            First Name
          </label>
          <input
            className={cx("form-input border-celadonBlue xl:w-[370px]", {
              "border-red": missingFields && missingFields["firstName"],
            })}
            placeholder="Enter the dependent first name"
            onChange={(event) => {
              let value = event.target.value;
              value = value.replace(/[^A-Za-z\s\-.]/g, "");
              updateDependent("firstName", value);
            }}
            value={dependent.firstName}
            defaultValue={dependent.firstName}
            required
          />
          {missingFields && missingFields["firstName"] && (
            <h2 className="error">This field is required.</h2>
          )}
        </div>
        <div className="flex w-full flex-col xl:w-auto">
          <label className="text-xl font-bold text-navyBlue xl:mb-3">
            Last Name
          </label>
          <input
            className={cx("form-input border-celadonBlue xl:w-[370px]", {
              "border-red": missingFields && missingFields["lastName"],
            })}
            placeholder="Enter the dependent last name"
            onChange={(event) => {
              let value = event.target.value;
              value = value.replace(/[^A-Za-z\s\-.]/g, "");
              updateDependent("lastName", value);
            }}
            value={dependent.lastName}
            defaultValue={dependent.lastName}
            required
          />
          {missingFields && missingFields["lastName"] && (
            <h2 className="error">This field is required.</h2>
          )}
        </div>
      </div>
      <div
        className={cx(
          "flex w-full flex-col",
          "items-start justify-start gap-4",
          "mt-4 xl:w-auto xl:max-w-full",
          "xl:flex-row xl:flex-wrap"
        )}
      >
        <div className="flex w-full flex-col xl:w-auto">
          <label className="text-xl font-bold text-navyBlue xl:mb-3">
            Birth Date
          </label>
          <DatePicker
            className={cx("form-input w-full border-celadonBlue xl:w-[241px]", {
              "border-red": missingFields && missingFields["dateOfBirth"],
            })}
            onChange={(date: Date) => {
              if (handleChangeDate(date, dependent.relationship || "")) {
                setDate(date);
                updateDependent("dateOfBirth", formatDate(date));
              }
            }}
            placeholderText="MM/DD/YYYY"
            dateFormat="MM-dd-yyyy"
            minDate={
              dependent.relationship === "Child"
                ? new Date(getMinDate().replace(/-/g, "/"))
                : new Date("01/01/1910")
            }
            maxDate={
              dependent.relationship === "Spouse"
                ? new Date(getMaxDate().replace(/-/g, "/"))
                : new Date()
            }
            selected={date}
            showYearDropdown
            popperContainer={({ children }) =>
              createPortal(children, document.body)
            }
            showMonthDropdown
            portalId="dependent"
            dropdownMode="select"
          />
          {missingFields && missingFields["dateOfBirth"] && (
            <h2 className="error">This field is required.</h2>
          )}
          {error && <h2 className="error xl:max-w-[241px]">{error}</h2>}
        </div>
        <div className="flex w-full flex-col xl:w-auto">
          <label className="text-xl font-bold text-navyBlue xl:mb-3">
            Gender
          </label>
          <select
            className={cx(
              "form-input border-celadonBlue bg-white xl:w-[241px]",
              {
                "border-red": missingFields && missingFields["gender"],
              }
            )}
            onChange={(event) => {
              updateDependent("gender", event.target.value);
              //if (submitted) validateFullPage()
            }}
            value={dependent.gender || ""}
          >
            <option value={""} disabled>
              Select gender
            </option>
            <option value={"M"}>Male</option>
            <option value={"F"}>Female</option>
          </select>
          {missingFields && missingFields["gender"] && (
            <h2 className="error">This field is required.</h2>
          )}
        </div>
        <div className="flex w-full flex-col xl:w-auto">
          <label className="text-xl font-bold text-navyBlue xl:mb-3">
            Relationship
          </label>
          <select
            className={cx(
              "form-input border-celadonBlue bg-white xl:w-[241px]",
              {
                "border-red": missingFields && missingFields["relationship"],
              }
            )}
            onChange={handleRelationshipChange}
            value={dependent.relationship}
            disabled={
              amountOfDependentsType === CoverageType.MemberPlusSpouse ||
              amountOfDependentsType === CoverageType.MemberPlusChildren
            }
            required
          >
            <option value={""} disabled>
              Select Relationship
            </option>

            <option value={"Spouse"} hidden={spouseSelected ? true : false}>
              Spouse
            </option>
            <option value={"Child"}>Child</option>
          </select>
          {missingFields && missingFields["relationship"] && (
            <h2 className="error">This field is required.</h2>
          )}
        </div>
      </div>
      {amountOfDependentsType === CoverageType.MemberPlusChildren &&
        index >= 1 && (
          <div className="mt-4 flex h-[30px] w-[100px] items-center justify-center font-bold">
            <Button text="REMOVE" action={removeButtonHandler} />
          </div>
        )}
      {amountOfDependentsType === CoverageType.Family && index >= 2 && (
        <div className="mt-4 flex h-[30px] w-[100px] items-center justify-center font-bold">
          <Button text="REMOVE" action={removeButtonHandler} />
        </div>
      )}
      {index + 1 !== amountOfDependents && (
        <div className="my-8 h-[2px] w-full bg-navyBlue" />
      )}
    </>
  );
};

export default DependentList;
