declare global {
  interface Window {
    hj: (method: string, ...args: any[]) => void;
  }
}

const identifyUser = (userId: string | null, attributes: Record<string, any>): void => {
  if (window.hj) {
    window.hj('identify', userId, attributes);
  }
};

const trackEvent = (event: string): void => {
  if (window.hj) {
    window.hj('event', event);
  }
};

const recordPageView = (name: string): void => {
  if (window.hj) {
    window.hj('stateChange', name);
  }
}

const hotJar = {
  identifyUser,
  recordPageView,
  trackEvent
}

export default hotJar;

// Example usage:
// identifyUser('user123', { 'Signed up': '2024-11-14', 'Plan': 'Premium' });
// trackEvent('Checkout completed');