// Default Plan IDs
export const NCDValueByMetLife = 38796;
export const NCDValueByMetLifeUpdate = 38797;
export const NCDEssentialsByMetLife = 38398;
export const NCDCompleteByMetLife = 38399;
export const NCDValueByMetLifeNYCT = 39849;
export const NCDEssentialsByMetLifeNYCT = 39847;
export const NCDCompleteByMetLifeNYCT = 39848;
export const NCDElite1500 = 42249;
export const NCDElite3000 = 42250;
export const NCDElite5000 = 42251;
export const NCDSmile1000byMetLife = 45035;
export const NCDSmile2500byMetLife = 45041;
export const VSPPreferredByMetLife = 38593;
export const VSPextra = 39651;
export const NWFA_Low_Dental = 38449;
export const NWFA_Mid_High_Dental = 38450;
export const NWFA_Low_Combo = 38928;
export const NWFA_Mid_High_Combo = 38941
export const NWFA_VSP_Only = 38923;

// SVG Plan IDs
export const NCDValueByMetLife_SVG = 44861;
export const NCDEssentialsByMetLife_SVG = 44864;
export const NCDCompleteByMetLife_SVG = 44865;
export const NCDElite1500_SVG = 44855;
export const NCDElite3000_SVG = 44856;
export const NCDElite5000_SVG = 44857;
export const VSPPreferredByMetLife_SVG = 44847;
export const VSPextra_SVG = 45051;
export const NWFA_Low_Dental_SVG = 44852;
export const NWFA_Mid_High_Dental_SVG = 44851;
export const NWFA_Low_Combo_SVG = 44853;
export const NWFA_Mid_High_Combo_SVG = 44854;
export const NWFA_VSP_Only_SVG = 44850;

// SQ Plan IDs
export const NCDValueByMetLife_SQ = 38397;
export const NCDValueByMetLifeNYCT_SQ = 39967;
export const NCDElite1500_SQ = 41928;
export const NCDElite3000_SQ = 41929;
export const NCDElite5000_SQ = 41930;
export const VSPPreferredByMetLife_SQ = 39062;
export const VSPextra_SQ = 39701;
export const NWFA_Low_Dental_SQ = 39378;
export const NWFA_Low_Combo_SQ = 39437;
export const NWFA_VSP_Only_SQ = 39465;