/* eslint-disable indent */
import React, { FC } from "react";
import cx from "classnames";
import Button from "../Button";
import { NCD_AGENT_SUPPORT_PHONE } from "../../utils";
import { useParams } from "react-router-dom";
import { D2CAgentId } from "../../utils";

type WizardProgressBarProps = {
  step: number;
  agentId?: number;
  purchaseState?: boolean;
  // stepsWithDescription: string[];
  setStep?: React.Dispatch<React.SetStateAction<number>>;
};

const ProgressBar: FC<WizardProgressBarProps> = ({
  step,
  purchaseState = false,
  // stepsWithDescription,
  setStep,
}: WizardProgressBarProps) => {
  const { agentId } = useParams()
  const isD2C = Number(agentId) === D2CAgentId;

  return (
    <div
      className={cx(
        "flex h-[84px] w-full flex-col items-center justify-between",
        "bg-white  px-4 sm:flex-row",
        {
          "py-[18px]": isD2C,
          "py-2": !isD2C,
        }
      )}
    >
      <div
        className={cx("w-[100px]", "font-bold lg:w-[129px]", {
          invisible: step === 1 || purchaseState,
        })}
      >
        <Button
          text="Back"
          className="h-[45px] text-[20px] font-normal leading-[24.2px] sm:ml-2"
          action={() => {
            if (!setStep) return;
            setStep((current) => {
              if (current === 1) return current;
              else return current - 1;
            });
          }}
        />
      </div>
      {!isD2C && (
        <div className="justify-center text-end text-xs text-black sm:pr-2 sm:text-base">
          Agent Support | AppTechSupport@NCD.com | {NCD_AGENT_SUPPORT_PHONE}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
