import { FC } from "react";
import cx from "classnames";
import NwfaLink from "../../components/Links/NwfaLink";

const HomepageBlurbAgent: FC = () => {
  return (
    <div
      className={cx(
        "flex flex-col overflow-y-auto",
        "rounded-3xl border-4 border-solid border-black bg-white",
        "px-[21px] pt-[23px] pb-[14px] text-black"
      )}
    >
      <div className="max-w-[708px]">
        <div className="mb-5 text-[24px] font-semibold leading-[29.05px]">
          NCD: Insurance, Reimagined{" "}
        </div>
        <p className="my-4 text-[20px] leading-[24.2px]">
          When it comes to quality care, it isn’t just about your
          eyes, or your teeth.
          <br />
          It’s about you — all of you.
        </p>{" "}
        <p className="my-4 max-w-[640px] text-[20px] leading-[24.2px]">
          At NCD, we’re here to empower all of our members to make
          their best healthcare decisions, to reach their goals, and
          to enjoy a well-lived, healthy, and smile-worthy life. We
          want to change lives and Spread the Smile, and that’s what
          drives us to do better, and offer incredible, industry-first
          products.{" "}
        </p>
        <p className="mt-4 text-[20px] leading-[24.2px]">
          Why pick and choose, when you could have so much more than
          insurance?
        </p>
        <p className="mt-4 text-[20px] leading-[24.2px]">
          Plans available for
          <NwfaLink
            text=' National Wellness and Fitness'
            queryString='?utm_source=Agent+Assisted+NCD+MetLife+Enrollment+Platform&utm_medium=direct&utm_id=NWFA%2C+Direct+Traffic'
          /> members.
        </p>
      </div>
    </div>
  )
}

export default HomepageBlurbAgent