import { create } from "zustand";
interface ApplicationState {
  applicationDate: number;
  appId: string | undefined ;
  setAppId: (appId: string) => void;
  setApplicationDate: (date: number) => void;
}

const useApplicationStore = create<ApplicationState>()((set) => ({
  applicationDate: new Date().getMonth(),
  appId: undefined,
  setAppId: (id: string) => set(() => ({ appId: id })),
  setApplicationDate: (date) => set(() => ({ applicationDate: date })),
}));

export default useApplicationStore;
