import React, { FC, useEffect, useState, useCallback, Fragment } from "react";
import cx from "classnames";
import Button from "../../components/Button";
import PlanDetail from "../../components/PlanDetail";
import Plan from "../../models/Plan";
import Member from "../../models/Member";
import Agent from "../../models/Agent";
import Dependent from "../../models/Dependent";
import ErrorLog, { LogLevel } from "../../models/ErrorLog";
import { VSPextra, VSPPreferredByMetLife } from "../../planIds"
import useApplicationStore from "../../context/application";
import QuoteModal from "./quote-modal";

type ConfirmCartProps = {
  member: Member;
  agent: Agent;
  selectedPlans: Plan[];
  wantsDental: boolean;
  wantsVision: boolean;
  associationPlans?: Plan[];
  associatedPlan?: Plan;
  associatedPlanDental: Plan;
  dentalAssociationPlans: Plan[] | undefined;
  dependents: Dependent[];
  amountOfDependentsType: string;
  agentState?: Agent;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  zipCodeState: string;
  max: number;
  setMax: React.Dispatch<React.SetStateAction<number>>;
  validVisionInState: boolean;
  associationLeftover: number;
  setAssociationLeftover: React.Dispatch<React.SetStateAction<number>>;
};

const ConfirmCart: FC<ConfirmCartProps> = ({
  member,
  agent,
  selectedPlans,
  wantsDental,
  wantsVision,
  associatedPlan,
  associatedPlanDental,
  dentalAssociationPlans,
  dependents = [],
  amountOfDependentsType,
  setStep,
  zipCodeState,
  max,
  setMax,
  agentState,
  validVisionInState,
  associationLeftover,
  setAssociationLeftover,
}: ConfirmCartProps) => {
  const [totalValue, setTotalValue] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [showDetailedPricing, setShowDetailedPricing] = useState(false);
  const [priceQuote, setPriceQuote] = useState(0);
  const [associatedPrice, setAssociatedPrice] = useState(0);
  const [fullViewArray, setFullViewArray] = useState<string[]>([]);

  useEffect(() => {
    let value = 0;

    selectedPlans.forEach((plan) => {
      if (plan?.rate) {
        value = value + plan?.rate;
      }
    });

    if (associatedPlan && associatedPlan.rate && associatedPlan.rateUpdate) {
      if (associatedPlan.rateUpdate !== undefined && associatedPlan.rateUpdate > 0) {
        setAssociatedPrice(associatedPlan.rateUpdate);
      } else if (associatedPlan.rate !== undefined && associatedPlan.rate > 0) {
        setAssociatedPrice(associatedPlan.rate)
      } else {
        ErrorLog.log(new Error(), `price for planID ${associatedPlan.id} is not available. Please check the data source.`, LogLevel.warn)
      }
    }

    setTotalValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlans, associatedPlan]);

  useEffect(() => {
    if (dependents.length < 2 && zipCodeState !== "OR") {
      setPriceQuote(5);
    } else if (dependents.length < 2 && zipCodeState === "OR") {
      setPriceQuote(2);
    } else {
      setPriceQuote(0);
    }
  }, [totalValue]);

  return (
    <>
      <QuoteModal
        openModal={openModal}
        handleModalState={setOpenModal}
        agentState={agentState}
      />
      <div className={cx("flex flex-col", "min-h-[70vh] w-full", "p-2")}>
        <div
          className={cx(
            "flex w-full justify-center",
            "mb-4 py-3",
            "rounded-3xl border-4",
            "border-black bg-white"
          )}
        >
          <h1 className={cx("page-header-text")}>Quote</h1>
        </div>
        <div
          className={cx(
            "top-44 z-20 lg:sticky",
            "mb-4 p-8",
            "flex flex-col",
            "rounded-3xl border-4",
            "border-black bg-white",
            "w-full lg:w-[980px]"
          )}
        >
          <div
            className={cx(
              "flex w-full flex-col gap-4 text-center sm:w-[400px]",
              "lg:w-full lg:flex-row lg:text-start"
            )}
          >
            <div className="flex flex-col text-xl leading-[24.2px] text-navyBlue">
              <p data-cy="totalQuote">Total Monthly Cost</p>
              <div className="font-bold">
                $
                {associatedPlan?.rate
                  ? totalValue + associatedPrice
                  : totalValue}
                /MO
              </div>
              {agent.id === 660555 &&
                amountOfDependentsType === "Member plus Children" &&
                selectedPlans.length >= 2 &&
                selectedPlans[1]?.id && (
                  <div
                    className={cx(
                      "mx-auto mt-3 max-w-[256px]",
                      "text-lg font-medium italic text-navyBlue"
                    )}
                  >
                    Price will change to $
                    {associatedPlan?.rate
                      ? totalValue + associatedPrice + priceQuote
                      : totalValue}{" "}
                    if more than one child is added
                  </div>
                )}
            </div>
            <a
              href="https://www.ncd.com/aboutNWFA"
              target="_blank"
              rel="noreferrer"
              className={cx(
                "max-w-[224px]",
                "text-xl leading-[24.2px]",
                "mx-auto flex font-bold text-celadonBlue"
              )}
            >
              View More About Your NWFA Benefits
            </a>
            <div className="flex flex-col">
              <div
                className={cx(
                  "h-[53px] max-h-[53px]",
                  "flex justify-center font-inter lg:justify-end",
                  "text-xl font-bold"
                )}
                onClick={() => setOpenModal(true)}
              >
                <div
                  className={cx(
                    "mb-3",
                    "h-[53px]",
                    "justify-self-end font-inter",
                    "text-xl font-bold"
                  )}
                >
                  <Button
                    text="Begin Application"
                    className="beginApplication w-full lg:w-[250px]"
                    action={() => setStep((current) => current + 1)}
                    mainPath
                  />
                </div>
                {/*
                  <Button
                    text="Send Quote"
                    submit={true}
                    action={() => setOpenModal(true)}
                  />
                   */}
              </div>
            </div>
          </div>

          {agent.id === 660555 ? (
            <>
              <div
                className={cx(
                  "pt-[25px]",
                  "text-center text-xl font-bold",
                  "lg:text-start xl:ml-0",
                  "hidden"
                )}
              >
                <span
                  className="cursor-pointer select-none text-celadonBlue"
                  onClick={() => setShowDetailedPricing(!showDetailedPricing)}
                >
                  {!showDetailedPricing
                    ? "View Detailed Pricing"
                    : "Close Detailed Pricing"}
                </span>
              </div>
              <div
                className={cx(
                  "flex w-full flex-col",
                  "gap-6 pt-4",
                  "lg:flex-row lg:items-start lg:justify-start",
                  { hidden: !showDetailedPricing }
                )}
              >
                {[...selectedPlans, associatedPlan]?.map((plan, index) => {
                  if (!plan?.id) return null;
                  return (
                    <div className="flex flex-row items-center gap-3 lg:flex-col lg:items-start lg:gap-0">
                      <div
                        className={cx(
                          "w-full max-w-[250px] pr-2 text-start text-base font-normal lg:w-11/12"
                        )}
                      >
                        {plan.label}
                      </div>
                      <div className="text-start text-base font-bold text-navyBlue">
                        ${plan.rate}/MO
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}

          {/*
            {agentState?.id !== 660555 ? (
              <div
                className="mt-5 justify-self-end font-inter text-xl font-bold"
                onClick={() => setOpenModal(true)}
              ></div>
            ) : null}
             */}
        </div>

        <div className="grid w-full grid-cols-1 place-items-center gap-4 lg:grid-cols-2 lg:gap-[63px]">
          {selectedPlans.map((plan) => {
            if (!plan?.id) return null;
            if (
              (plan.id === VSPPreferredByMetLife || plan.id === VSPextra) &&
              associatedPlan?.rate
            ) {
              return (
                <Fragment key={plan.id}>
                  <PlanDetail
                    member={member}
                    agent={agent}
                    plan={plan}
                    associationPlans={[associatedPlan!]}
                    setStep={() => {}}
                    selectable={false}
                    associatedPlan={associatedPlan}
                    associatedPlanDental={associatedPlanDental}
                    dentalAssociationPlans={dentalAssociationPlans}
                    numberOfSelectedPlans={
                      selectedPlans.filter((plan) => plan?.id !== undefined)
                        .length
                    }
                    wantsDental={wantsDental}
                    wantsVision={wantsVision}
                    fullViewArray={fullViewArray}
                    setFullViewArray={setFullViewArray}
                    zipCodeState={zipCodeState}
                    max={max}
                    setMax={setMax}
                    selectedPlans={selectedPlans}
                    dependents={dependents}
                    amountOfDependentsType={amountOfDependentsType}
                    validVisionInState={validVisionInState}
                    associationLeftover={associationLeftover}
                    setAssociationLeftover={setAssociationLeftover}
                  />
                </Fragment>
              );
            } else {
              return (
                <Fragment key={plan.id}>
                  <PlanDetail
                    member={member}
                    agent={agent}
                    plan={plan}
                    associationPlans={[associatedPlan!]}
                    associatedPlanDental={associatedPlanDental}
                    dentalAssociationPlans={dentalAssociationPlans}
                    setStep={() => {}}
                    selectable={false}
                    numberOfSelectedPlans={
                      selectedPlans.filter((plan) => plan?.id !== undefined)
                        .length
                    }
                    wantsDental={wantsDental}
                    wantsVision={wantsVision}
                    fullViewArray={fullViewArray}
                    setFullViewArray={setFullViewArray}
                    zipCodeState={zipCodeState}
                    max={max}
                    setMax={setMax}
                    selectedPlans={selectedPlans}
                    dependents={dependents}
                    amountOfDependentsType={amountOfDependentsType}
                    validVisionInState={validVisionInState}
                    associationLeftover={associationLeftover}
                    setAssociationLeftover={setAssociationLeftover}
                  />
              </Fragment>
              );
            }
          })}
        </div>

        {/*
          <div
            className="h-[53px] max-h-[53px] w-[250px] max-w-[250x] justify-self-end font-inter text-xl font-bold"
            onClick={() => setOpenModal(true)}
          >
            <Button
              text="Send Quote"
              submit={true}
              action={() => setOpenModal(true)}
            />
          </div>
              */}
      </div>
    </>
  );
};

export default ConfirmCart;
