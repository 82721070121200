import Button from "../../components/Button";

interface BillingDateModalProps {
  handleIncorrectBillingDate: () => void;
}

const BillingDateModal: React.FC<BillingDateModalProps> = ({ handleIncorrectBillingDate}) => {
  const newEffectiveDate = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1).toLocaleDateString();

  return (
    <div className="absolute top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-50">
      <div className="rounded-lg bg-white p-4">
        <h2 className="text-lg font-semibold">Invalid Billing Date</h2>
        <p>The date you entered was invalid, so we've updated your billing date to today. Your new effective date is {newEffectiveDate}</p>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            className="bg-blue-500 hover:bg-blue-600 rounded px-4 py-2 text-white"
            action={handleIncorrectBillingDate}
            text="Confirm"
          >
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BillingDateModal;
