import React, { FC, Fragment, useEffect, useState } from "react";
import PlanDetail from "../../components/PlanDetail";
import cx from "classnames";
import Plan from "../../models/Plan";
import Agent from "../../models/Agent";
import Member from "../../models/Member";
import Dependent from "../../models/Dependent";

type SelectPlanProps = {
  member: Member;
  plansProp?: Plan[];
  agent: Agent;
  dependents: Dependent[];
  associationPlans?: Plan[];
  associatedPlanDental: Plan;
  dentalAssociationPlans: Plan[] | undefined;
  type?: string;
  getAvailablePlans?: () => Promise<void>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPlan: React.Dispatch<React.SetStateAction<Plan>>;
  selectedPlans: Plan[];
  wantsDental: boolean;
  wantsVision: boolean;
  zipCodeState: string;
  max: number;
  setMax: React.Dispatch<React.SetStateAction<number>>;
  associationLeftover: number;
  setAssociationLeftover: React.Dispatch<React.SetStateAction<number>>;
};
const SelectPlan: FC<SelectPlanProps> = ({
  member,
  agent,
  plansProp,
  dependents,
  associationPlans,
  associatedPlanDental,
  dentalAssociationPlans,
  getAvailablePlans,
  type,
  setStep,
  setSelectedPlan,
  selectedPlans,
  wantsDental,
  wantsVision,
  zipCodeState,
  max,
  setMax,
  associationLeftover,
  setAssociationLeftover,
}: SelectPlanProps) => {
  const [fullViewArray, setFullViewArray] = useState<string[]>([]);
  const [plans, setPlans] = useState<Plan[] | undefined>(plansProp);
  const [rows, setRows] = useState<number>(3);

  useEffect(() => {
    if (getAvailablePlans) getAvailablePlans();
    setPlans(plansProp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansProp]);

  useEffect(() => {
    const updateRowsBasedOnWindowSize = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1280) {
        // xl
        setRows(3);
      } else if (windowWidth >= 1024) {
        // lg
        setRows(2);
      } else {
        setRows(1);
      }
      setFullViewArray([]);
    };

    updateRowsBasedOnWindowSize();

    window.addEventListener("resize", updateRowsBasedOnWindowSize);

    return () => {
      window.removeEventListener("resize", updateRowsBasedOnWindowSize);
    };
  }, []);

  return (
    <div
      className={cx(
        "flex min-h-screen w-full flex-col",
        "py-2 px-2",
        "lg:min-w-[910px] xl:min-w-[1280px] 2xl:min-w-[1354px]"
      )}
    >
      <div
        className={cx(
          "sticky top-2 z-20 mb-4",
          "flex w-full justify-center",
          "rounded-3xl border-4 border-black bg-white py-3 px-6"
        )}
      >
        <h1 className="page-header-text text-navyBlue">
          Please Select a {type} Plan
        </h1>
      </div>
      <div
        className={cx(
          `mt-4 grid grid-cols-1 place-items-center gap-y-4`,
          `lg:grid-cols-2 lg:gap-x-8`,
          `xl:grid-cols-3 xl:gap-x-[25px]`
        )}
      >
        {plans?.map((plan) => {
          return (
            <Fragment key={plan.id}>
              <PlanDetail
                member={member}
                agent={agent}
                plan={plan}
                rows={rows}
                selectedPlans={selectedPlans}
                associationPlans={associationPlans}
                associatedPlanDental={associatedPlanDental}
                dentalAssociationPlans={dentalAssociationPlans}
                dependents={dependents}
                setSelectedPlan={setSelectedPlan}
                setStep={() => setStep((current) => current + 1)}
                selectable
                setFullViewArray={setFullViewArray}
                fullViewArray={fullViewArray}
                numberOfSelectedPlans={
                  selectedPlans.filter((plan) => plan?.id !== undefined).length
                }
                wantsDental={wantsDental}
                wantsVision={wantsVision}
                zipCodeState={zipCodeState}
                max={max}
                setMax={setMax}
                associationLeftover={associationLeftover}
                setAssociationLeftover={setAssociationLeftover}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SelectPlan;
