import {planMappings} from "../planMappings";
import { SVGUplines, SQUplines } from "../utils";

export const getMappedPlanId = (parentAgentId: number | undefined, originalPlanId: number): number => {
    if (!parentAgentId) return originalPlanId;
    let mappedPlanId;

    if (SVGUplines.includes(parentAgentId)) {
      mappedPlanId = (planMappings.SVGUplines as Record<string, number>)[originalPlanId];
    } else if (SQUplines.includes(parentAgentId)) {
      mappedPlanId = (planMappings.SQUplines as Record<string, number>)[originalPlanId];
    }
    return mappedPlanId !== undefined ? mappedPlanId : originalPlanId;
  };