import React, { FC, useEffect, useState, useCallback } from 'react'
import axios from 'axios'

import Agent from '../../models/Agent'
import Button from '../../components/Button'

/**
 * QuoteModalProps is an object with two properties: openModal and handleModalState.
 *
 * openModal is a boolean and handleModalState is a function that takes a React.SetStateAction<boolean>
 * and returns void.
 * @property {boolean} openModal - boolean - This is the state of the modal.
 * @property {Agent} agentState - This is the state of the agent that is passed down from the parent
 * component.
 * @property handleModalState - This is a function that will be used to change the state of the modal.
 */

type QuoteModalProps = {
    openModal: boolean
    agentState?: Agent
    handleModalState: React.Dispatch<React.SetStateAction<boolean>>
}

/**
 * This function is a modal that allows the user to send a quote to a phone number or email
 * @param {string} email - string
 * @returns React.Fragment or Modal
 */

const QuoteModal: FC<QuoteModalProps> = ({
    openModal,
    agentState,
    handleModalState
}: QuoteModalProps) => {
    const [loading, setLoading] = useState(false)
    const [smsButton, setSMSButton] = useState(true)
    const [emailButton, setEmailButton] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumberError, setPhoneNumberError] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [url, setUrl] = useState('')
    const [quoteSent, setQuoteSent] = useState({ sent: false, error: false })

    const smsWebhook = async () => {
        setLoading(true)
        axios
            .post(
                `${process.env.REACT_APP_ZAPIERQUOTESMS}`,
                JSON.stringify({
                    payload: {
                        agentName: `${
                            agentState?.firstName &&
                            agentState?.lastName &&
                            agentState.firstName + ' ' + agentState?.lastName
                        }`,
                        memberPhone: phoneNumber,
                        url: url
                    }
                })
            )
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setQuoteSent({
                        sent: true,
                        error: false
                    })
                }
            })
            .catch((err) => {
                setLoading(false)
                setQuoteSent({
                    error: true,
                    sent: false
                })
            })
    }

    const emailWebhook = async () => {
        setLoading(true)
        axios
            .post(
                `${process.env.REACT_APP_ZAPIERQUOTEEMAIL}`,
                JSON.stringify({
                    payload: {
                        agentName: `${
                            agentState?.firstName &&
                            agentState?.lastName &&
                            agentState.firstName + ' ' + agentState?.lastName
                        }`,
                        agentEmail: `${agentState?.email && agentState?.email}`,
                        memberEmail: email, //I think you use member email here? Change if needed
                        url: url
                    }
                })
            )
            .then((res) => {
                setLoading(false)
                if (res.status === 200) {
                    setQuoteSent({
                        sent: true,
                        error: false
                    })
                } else {
                    setQuoteSent({
                        sent: false,
                        error: true
                    })
                }
            })
            .catch((err) => {
                setLoading(false)
                setQuoteSent({
                    error: true,
                    sent: false
                })
            })
    }
    function isValidEmail(email: string) {
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i
        return regex.test(email)
    }
    function isValidPhone(p: string) {
        var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/
        var digits = p.replace(/\D/g, '')
        return phoneRe.test(digits)
    }

    useEffect(() => {
        setUrl(window.location.href)
    }, [])

    if (!openModal) return <></>

    return (
        <>
            <div
                className="fixed z-30 h-[999vh] w-[999vh] bg-gradient-to-r from-[#00376475] to-[#53BFE775]"
                onClick={() => handleModalState(false)}
            />
            <div className="fixed top-[50%] left-[50%] z-40 translate-x-[-50%] translate-y-[-50%]">
                <div className="z-50 h-[337px] w-[775px] items-center justify-center self-start rounded-[32px] border-4 border-celadonBlue bg-backgroundSolidGrey py-4 text-center">
                    <h1 className="flex flex-row items-center justify-center gap-4 text-[32px] font-bold text-navyBlue">
                        Send Quote
                    </h1>
                    {quoteSent?.sent ? (
                        <>
                            <p className="mb-1 mt-[10px] text-xl font-medium text-navyBlue">
                                Thank you for your request, check your email/phone for more details.
                            </p>
                        </>
                    ) : (
                        <>
                            <div className="mb-1 mt-[10px] text-xl font-medium text-navyBlue">
                                Select which method you would like to send this quote with
                            </div>
                            <div className="mt-[21px] flex justify-center">
                                <button
                                    onClick={() => {
                                        setEmailButton(false)
                                        setSMSButton(true)
                                    }}
                                    className={`flex h-[53px] w-[96px] cursor-pointer items-center justify-center rounded-l-full p-2 text-center text-[20px]  font-bold leading-[24px] text-white hover:shadow-lg disabled:cursor-default ${
                                        smsButton === true ? 'bg-lightBlue' : 'bg-[#aaaaaa]'
                                    } `}
                                >
                                    SMS
                                </button>
                                <button
                                    onClick={() => {
                                        setSMSButton(false)
                                        setEmailButton(true)
                                    }}
                                    className={`flex h-[53px] w-[96px] cursor-pointer items-center justify-center rounded-r-full p-2 text-center text-[20px] font-bold leading-[24px] text-white hover:shadow-lg disabled:cursor-default ${
                                        emailButton === true ? 'bg-lightBlue' : 'bg-[#aaaaaa]'
                                    }`}
                                >
                                    Email
                                </button>
                            </div>
                            <div className="mt-[20px] flex items-end justify-center gap-[30px] md:gap-[60px]">
                                {quoteSent?.error && (
                                    <p className="absolute bottom-[2em] text-base font-light text-red">
                                        We couldn't process your request, try again later.
                                    </p>
                                )}
                                <div className="flex flex-col items-start">
                                    {smsButton ? (
                                        <>
                                            <label className="flex flex-col font-normal">
                                                <span className="w-fit p-1 text-xl font-bold text-navyBlue">
                                                    Phone Number
                                                </span>
                                                <input
                                                    className={`h-[29px] w-[145px] rounded-[59px] border  border-solid border-celadonBlue pl-2 text-xl font-normal text-navyBlue focus:outline-none md:h-[46px] xl:w-[352px] ${
                                                        !!phoneNumberError && 'border-red'
                                                    }`}
                                                    placeholder="Enter number"
                                                    type={'tel'}
                                                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                                    value={phoneNumber}
                                                    onChange={(event) => {
                                                        if (event?.target?.value?.length > 9) return
                                                        if (event?.target?.value?.length < 9)
                                                            setPhoneNumberError(
                                                                'Routing number must be 9 digits long'
                                                            )
                                                        if (event?.target?.value?.length === 9)
                                                            setPhoneNumberError('')
                                                        setPhoneNumber(event?.target?.value)
                                                    }}
                                                    required
                                                />
                                            </label>
                                            {phoneNumberError && (
                                                <h2
                                                    className="absolute bottom-[2em] font-light"
                                                    style={{ color: 'red' }}
                                                >
                                                    {phoneNumberError}
                                                </h2>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <label className="flex flex-col font-normal">
                                                <span className="w-fit p-1 text-xl font-bold text-navyBlue">
                                                    Email
                                                </span>
                                                <input
                                                    className={`h-[29px] w-[145px] rounded-[59px] border  border-solid border-celadonBlue pl-2 text-xl font-normal text-navyBlue focus:outline-none md:h-[46px] xl:w-[352px] ${
                                                        emailError && 'border-red'
                                                    }`}
                                                    placeholder="Enter Email"
                                                    type={'email'}
                                                    value={email}
                                                    required
                                                    onChange={(event) => {
                                                        setEmail(event?.target?.value)
                                                        if (
                                                            emailError &&
                                                            isValidEmail(event.target.value)
                                                        )
                                                            return setEmailError(false)
                                                    }}
                                                    onBlur={(event) => {
                                                        if (!isValidEmail(event.target.value)) {
                                                            setEmailError(true)
                                                        } else {
                                                            setEmailError(false)
                                                        }
                                                    }}
                                                />
                                            </label>
                                            {emailError && (
                                                <h2
                                                    className="absolute  bottom-[2em] font-light"
                                                    style={{ color: 'red' }}
                                                >
                                                    Please use a valid email format.
                                                </h2>
                                            )}{' '}
                                        </>
                                    )}
                                </div>
                                <div className="h-[29px] w-[145px] text-[20px] font-bold leading-[24px] md:h-[46px] md:w-[168px]">
                                    <Button
                                        text={loading ? 'Loading...' : 'Send'}
                                        mainPath
                                        action={() => {
                                            if (smsButton && phoneNumber.length === 9) {
                                                setPhoneNumberError('')
                                                return smsWebhook()
                                            } else if (!smsButton && isValidEmail(email)) {
                                                setEmailError(false)
                                                return emailWebhook()
                                            } else {
                                                if (smsButton)
                                                    setPhoneNumberError(
                                                        'Routing number must be 9 digits long'
                                                    )
                                                else {
                                                    setEmailError(true)
                                                }
                                            }
                                        }}
                                        disabled={
                                            loading
                                                ? true
                                                : smsButton
                                                ? phoneNumberError !== ''
                                                : emailError
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default QuoteModal
