import React, { ChangeEventHandler, FC } from "react";
import Button from "../../components/Button";
import cx from "classnames";
import FormFieldError from "./FormFieldError";

type ZipCodeFormProps = {
  value: string;
  error: string;
  pending: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

const ZipCodeForm: FC<ZipCodeFormProps> = ({
  value,
  error,
  pending,
  onChange,
  onSubmit
}: ZipCodeFormProps) => {

  return (
    <div className="sticky top-44 rounded-3xl border-4 border-black bg-white px-[21px] pt-[31px] pb-[26px]">
      <form onSubmit={onSubmit}>
        <div className="flex w-full flex-col items-center gap-4 md:flex-row">
          <div
            className={cx(
              "text-[20px] font-bold leading-[24.2px] text-black "
            )}
          >
            Please enter your zip code to begin
          </div>

          <input
            className={cx(
              "h-[37px] w-full max-w-[342px]",
              "md:ml-[22px] md:w-[296px]",
              "appearance-none rounded-lg",
              "text-[20px] leading-[24.2px]",
              "border border-black bg-backgroundSolidGrey",
              "pl-3 text-black outline-none focus:outline-none"
            )}
            value={value}
            onChange={(e) => onChange(e)}
          />

          <div
            className={cx(
              "h-[37px] w-full max-w-[342px]",
              "text-[20px] leading-[24.2px]",
              "md:ml-[10px] md:w-[112.28px]"
            )}
          >
            <Button
              className="submitZipQuote"
              text={"Quote"}
              disabled={pending}
              loadingIcon={pending}
              submit
              mainPath
            />
          </div>
        </div>
        {error && <FormFieldError error={error} />}

      </form>
    </div>
  )
}

export default ZipCodeForm