import React, { FC, Fragment, useEffect, useState } from "react";
import Plan from "../../models/Plan";
import cx from "classnames";
import Agent from "../../models/Agent";
import Member from "../../models/Member";
import Dependent from "../../models/Dependent";
import {
  NCDCompleteByMetLife,
  NCDCompleteByMetLifeNYCT,
  NCDEssentialsByMetLife,
  NCDEssentialsByMetLifeNYCT,
  NCDValueByMetLife,
  NCDValueByMetLifeNYCT,
  VSPextra,
  VSPPreferredByMetLife,
  NCDElite1500,
  NCDElite3000,
  NCDElite5000,
  NCDSmile1000byMetLife,
  NCDSmile2500byMetLife,
} from "../../planIds"
import PlanInfo from "./planInfo";
import { PlanLabel } from "../../enums";

type PlanDetailProps = {
  member?: Member;
  agent: Agent;
  plan: Plan;
  rows?: number;
  associationPlans?: Plan[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
  selectedPlans: Plan[];
  setSelectedPlan?: React.Dispatch<React.SetStateAction<Plan>>;
  selectable: boolean;
  fullViewArray: string[];
  setFullViewArray: React.Dispatch<React.SetStateAction<string[]>>;
  associatedPlan?: Plan;
  associatedPlanDental: Plan;
  dentalAssociationPlans: Plan[] | undefined;
  numberOfSelectedPlans: number;
  wantsDental: boolean;
  wantsVision: boolean;
  zipCodeState: string;
  max: number;
  setMax: React.Dispatch<React.SetStateAction<number>>;
  dependents: Dependent[];
  amountOfDependentsType?: string;
  validVisionInState?: boolean;
  associationLeftover: number;
  setAssociationLeftover: React.Dispatch<React.SetStateAction<number>>;
};

const PlanDetail: FC<PlanDetailProps> = ({
  member,
  agent,
  plan,
  rows = 1,
  associationPlans,
  setStep,
  selectedPlans,
  setSelectedPlan,
  selectable,
  fullViewArray,
  setFullViewArray,
  associatedPlan,
  associatedPlanDental,
  dentalAssociationPlans,
  numberOfSelectedPlans,
  wantsDental,
  wantsVision,
  zipCodeState,
  max,
  setMax,
  dependents = [],
  amountOfDependentsType,
  validVisionInState,
  associationLeftover,
  setAssociationLeftover,
}: PlanDetailProps) => {
  const [associationFee, setAssociationFee] = useState<number | undefined>();
  const [priceQuote, setPriceQuote] = useState(0);

  const shouldRenderCoPayWrapper = () => {
    return (
      (plan?.id === NCDSmile1000byMetLife || plan?.id === NCDSmile2500byMetLife)
    );
  };

  function getAssociationFee() {
    associationPlans?.some((associationPlan) => {
      if (
        (associationPlan.id === 38923 && plan.id === VSPPreferredByMetLife) ||
        (associationPlan.id === 38928 && plan.id === VSPextra) ||
        (associationPlan.id === 38923 && plan.id === VSPextra) ||
        (associationPlan.id === 38941 &&
          (plan.id === NCDEssentialsByMetLife ||
            plan.id === VSPPreferredByMetLife ||
            plan.id === NCDCompleteByMetLife ||
            plan.id === NCDEssentialsByMetLifeNYCT ||
            NCDCompleteByMetLifeNYCT ||
            plan.id === NCDElite1500 ||
            plan.id === NCDElite3000 ||
            plan.id === NCDElite5000)) ||
        ((plan.id === VSPPreferredByMetLife ||
          plan.id === NCDValueByMetLife ||
          plan.id === NCDValueByMetLifeNYCT ||
          plan.id === NCDSmile1000byMetLife ||
          plan.id === NCDSmile2500byMetLife) &&
          associationPlan.id === 38928) ||
        (associationPlan.id === 38449 &&
          (plan.id === NCDValueByMetLife ||
            plan.id === NCDValueByMetLifeNYCT ||
            plan.id === NCDSmile1000byMetLife ||
            plan.id === NCDSmile2500byMetLife)) ||
        (associationPlan.id === 38450 &&
          (plan.id === NCDEssentialsByMetLife ||
            plan.id === NCDEssentialsByMetLifeNYCT ||
            plan.id === NCDCompleteByMetLife ||
            plan.id === NCDCompleteByMetLifeNYCT ||
            plan.id === NCDElite1500 ||
            plan.id === NCDElite3000 ||
            plan.id === NCDElite5000))
      ) {
        if (associationPlan.rateUpdate !== undefined && associationPlan.rateUpdate > 0) {
          setAssociationFee(associationPlan.rateUpdate);
          if (associationPlan?.rateUpdate && associatedPlanDental?.rate)
            setAssociationLeftover(
              associationPlan.rateUpdate - associatedPlanDental.rate
            );
        } else {
          setAssociationFee(associationPlan.rate);
          if (associationPlan?.rate && associatedPlanDental?.rate)
            setAssociationLeftover(
              associationPlan.rate - associatedPlanDental.rate
            );
        }
        return true;
      }
      return false;
    });
  }

  useEffect(() => {
    if (numberOfSelectedPlans > max) {
      setMax(numberOfSelectedPlans);
    }
    return;
  }, [numberOfSelectedPlans]);

  useEffect(() => {
    getAssociationFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, wantsDental, wantsVision]);

  useEffect(() => {
    if (dependents.length < 2 && zipCodeState !== "OR") {
      setPriceQuote(5);
    } else if (dependents.length < 2 && zipCodeState === "OR") {
      setPriceQuote(2);
    } else {
      setPriceQuote(0);
    }
  }, [plan]);

  const planLogoHandler = () => {
    switch (plan?.label) {
      case PlanLabel.NCDValueByMetLife:
        return "/ncd-value.svg";
      case PlanLabel.NCDEssentialsByMetLife:
        return "/ncd-essentials.svg";
      case PlanLabel.NCDCompleteByMetLife:
        return "/ncd-complete.svg";
      case PlanLabel.MetLifeElite1500:
        return "/ncd-elite-1500.svg";
      case PlanLabel.MetLifeElite3000:
        return "/ncd-elite-3000.svg";
      case PlanLabel.MetLifeElite5000:
        return "/ncd-elite-5000.svg";
      case PlanLabel.NCDSmile1000byMetLife:
        return "/ncd-smile-1000.svg";
      case PlanLabel.NCDSmile2500byMetLife:
        return "/ncd-smile-2500.svg";
      default:
        return "/ncd-value.svg";
    }
  };

  const cardPriceHandler = () => {
    if (plan?.rate && associationFee) {
      if ((wantsDental && !wantsVision) || (!wantsDental && wantsVision)) {
        return plan.rate + associationFee;
      } else if (
        plan.label === PlanLabel.VSPPreferredPlan ||
        plan.label === PlanLabel.NCDVisionORNY
      ) {
        return plan.rate + associationLeftover;
      } else if (
        plan.label === PlanLabel.NCDValueByMetLife ||
        plan.label === PlanLabel.NCDSmile1000byMetLife ||
        plan.label === PlanLabel.NCDSmile2500byMetLife
      ) {
        return plan.rate + (dentalAssociationPlans?.[0]?.rate || 0);
      } else {
        return plan.rate + (dentalAssociationPlans?.[1]?.rate || 0);
      }
    }
    return 0;
  };

  // Orange Border Conditional for Best Value Banner
  // selectable && plan?.id === NCDCompleteByMetLife ? "border-orange" : "border-celadonBlue"
  return (
    <Fragment key={plan.id}>
      {shouldRenderCoPayWrapper() ? (
        <div
          className={cx(
            "flex h-full w-full max-w-[500px] flex-col",
            "rounded-3xl border-4 border-black"
          )}
          data-cy={plan.label}
        >
          <div className="ribbon z-10">
            <span className="absolute -left-24 z-50 -rotate-45 text-center font-bold">Copay <br></br>Plan</span>
          </div>
          {/* 
      {agent?.id === 600539 ? (
        <>
          {selectable && plan?.id === NCDCompleteByMetLife && (
            <div className="ribbon ribbon-top-left z-10">
              <span className="text">best value!</span>
            </div>
            // <div className="absolute top-5 -left-8 z-50 w-[130px] -rotate-45 bg-navyBlue text-center font-bold text-orange">
            //   Best Value
            // </div>
          )}{" "}
        </>
      ) : null}
      */}
          <div
            className={`${selectable} flex w-full flex-col items-center justify-center rounded-tl-[20px] rounded-tr-[20px] bg-white pt-6`}
          >
            <img
              id={plan.label}
              src={
                plan.label !== PlanLabel.VSPPreferredPlan &&
                plan.label !== PlanLabel.NCDVisionORNY
                  ? planLogoHandler()
                  : "/vsp.png"
              }
              className={cx({
                "h-[110px] w-[159px]":
                  plan?.label === PlanLabel.NCDValueByMetLife ||
                  plan?.label === PlanLabel.NCDEssentialsByMetLife ||
                  plan?.label === PlanLabel.NCDCompleteByMetLife,
                "h-[77px] w-[211px] lg:h-[110px] xl:h-[77px]":
                  plan?.label === PlanLabel.MetLifeElite1500 ||
                  plan?.label === PlanLabel.NCDSmile1000byMetLife ||
                  plan?.label === PlanLabel.NCDSmile2500byMetLife,
                "my-[19px] h-[72px] w-[142px]":
                  (plan.label === PlanLabel.VSPPreferredPlan ||
                    plan.label === PlanLabel.NCDVisionORNY) &&
                  selectedPlans[0]?.label?.charAt(0).toUpperCase() !== "M", // Elite
                "my-[3px] h-[72px] w-[142px] lg:my-[19px] xl:my-[3px]":
                  (plan.label === PlanLabel.VSPPreferredPlan ||
                    plan.label === PlanLabel.NCDVisionORNY) &&
                  selectedPlans[0]?.label?.charAt(0).toUpperCase() === "M", // Elite
              })}
              alt="NCD Logo"
            />
            <div
              className={cx(
                "flex w-full justify-between",
                "mt-[19px] px-12 text-xl md:text-2xl",
                {
                  hidden: !fullViewArray?.includes(plan?.label || ""),
                }
              )}
            >
              {plan?.id !== VSPPreferredByMetLife && plan?.id !== VSPextra ? (
                <>
                  <div className="text-black">Total</div>

                  <div className="font-extrabold text-black">
                    ${cardPriceHandler()}
                    {/* 
                {plan?.rate && associationFee
                  ? numberOfSelectedPlans &&
                    max === numberOfSelectedPlans &&
                    selectedPlans[1].id
                    ? Math.floor(associationFee / 2 + plan?.rate)
                    : associationFee + plan?.rate
                  : null}
                {wantsDental &&
                  wantsVision &&
                  !!associationFee &&
                  associationFee % 2 !== 0 && (
                    <sup>
                      {(associationFee / 2 - Math.floor(associationFee / 2)) *
                        100}
                    </sup>
                  )}
                  */}
                    /mo
                  </div>
                </>
              ) : (
                <>
                  <div className="text-black">Total </div>
                  <div className="font-extrabold text-black">
                    $ {cardPriceHandler()}
                    {/* 
                {plan?.rate && associationFee && numberOfSelectedPlans === 1
                  ? plan?.rate + associationFee
                  : plan?.rate &&
                    associationFee &&
                    Math.floor(plan?.rate + associationFee / 2)}
             
                {wantsDental &&
                  wantsVision &&
                  associationFee &&
                  associationFee % 2 !== 0 && (
                    <sup>
                      {(associationFee / 2 - Math.floor(associationFee / 2)) *
                        100}
                    </sup>
                  )}
                  */}
                    /mo
                  </div>
                </>
              )}
            </div>
            <div className="mt-6 h-3.5 w-full bg-black"></div>
          </div>
          <PlanInfo
            fullViewArray={fullViewArray}
            agent={agent}
            plan={plan}
            rows={rows}
            numberOfSelectedPlans={numberOfSelectedPlans}
            wantsDental={wantsDental}
            wantsVision={wantsVision}
            zipCodeState={zipCodeState}
            associationLeftover={associationLeftover}
            max={max}
            associationFee={associationFee || 0}
            selectedPlans={selectedPlans}
            amountOfDependentsType={amountOfDependentsType || ""}
            selectable={selectable}
            priceQuote={priceQuote}
            setFullViewArray={setFullViewArray}
            setStep={setStep}
            setSelectedPlan={setSelectedPlan}
            cardPriceHandler={cardPriceHandler}
          />
        </div>
      ) : (
        <div
          className={cx(
            "flex h-full w-full max-w-[500px] flex-col",
            "rounded-3xl border-4 border-black"
          )}
          data-cy={plan.label}
        >
          {/* 
      {agent?.id === 600539 ? (
        <>
          {selectable && plan?.id === NCDCompleteByMetLife && (
            <div className="ribbon ribbon-top-left z-10">
              <span className="text">best value!</span>
            </div>
            // <div className="absolute top-5 -left-8 z-50 w-[130px] -rotate-45 bg-navyBlue text-center font-bold text-orange">
            //   Best Value
            // </div>
          )}{" "}
        </>
      ) : null}
      */}
          <div
            className={`${selectable} flex w-full flex-col items-center justify-center rounded-tl-[20px] rounded-tr-[20px] bg-white pt-6`}
          >
            <img
              id={plan.label}
              src={
                plan.label !== PlanLabel.VSPPreferredPlan &&
                plan.label !== PlanLabel.NCDVisionORNY
                  ? planLogoHandler()
                  : "/vsp.png"
              }
              className={cx({
                "h-[110px] w-[159px]":
                  plan?.label === PlanLabel.NCDValueByMetLife ||
                  plan?.label === PlanLabel.NCDEssentialsByMetLife ||
                  plan?.label === PlanLabel.NCDCompleteByMetLife,
                "h-[77px] w-[211px] lg:h-[110px] xl:h-[77px]":
                  plan?.label === PlanLabel.MetLifeElite1500 ||
                  plan?.label === PlanLabel.NCDSmile1000byMetLife ||
                  plan?.label === PlanLabel.NCDSmile2500byMetLife,
                "my-[19px] h-[72px] w-[142px]":
                  (plan.label === PlanLabel.VSPPreferredPlan ||
                    plan.label === PlanLabel.NCDVisionORNY) &&
                  selectedPlans[0]?.label?.charAt(0).toUpperCase() !== "M", // Elite
                "my-[3px] h-[72px] w-[142px] lg:my-[19px] xl:my-[3px]":
                  (plan.label === PlanLabel.VSPPreferredPlan ||
                    plan.label === PlanLabel.NCDVisionORNY) &&
                  selectedPlans[0]?.label?.charAt(0).toUpperCase() === "M", // Elite
              })}
              alt="NCD Logo"
            />
            <div
              className={cx(
                "flex w-full justify-between",
                "mt-[19px] px-12 text-xl md:text-2xl",
                {
                  hidden: !fullViewArray?.includes(plan?.label || ""),
                }
              )}
            >
              {plan?.id !== VSPPreferredByMetLife && plan?.id !== VSPextra ? (
                <>
                  <div className="text-black">Total</div>

                  <div className="font-extrabold text-black">
                    ${cardPriceHandler()}
                    {/* 
                {plan?.rate && associationFee
                  ? numberOfSelectedPlans &&
                    max === numberOfSelectedPlans &&
                    selectedPlans[1].id
                    ? Math.floor(associationFee / 2 + plan?.rate)
                    : associationFee + plan?.rate
                  : null}
                {wantsDental &&
                  wantsVision &&
                  !!associationFee &&
                  associationFee % 2 !== 0 && (
                    <sup>
                      {(associationFee / 2 - Math.floor(associationFee / 2)) *
                        100}
                    </sup>
                  )}
                  */}
                    /mo
                  </div>
                </>
              ) : (
                <>
                  <div className="text-black">Total </div>
                  <div className="font-extrabold text-black">
                    $ {cardPriceHandler()}
                    {/* 
                {plan?.rate && associationFee && numberOfSelectedPlans === 1
                  ? plan?.rate + associationFee
                  : plan?.rate &&
                    associationFee &&
                    Math.floor(plan?.rate + associationFee / 2)}
             
                {wantsDental &&
                  wantsVision &&
                  associationFee &&
                  associationFee % 2 !== 0 && (
                    <sup>
                      {(associationFee / 2 - Math.floor(associationFee / 2)) *
                        100}
                    </sup>
                  )}
                  */}
                    /mo
                  </div>
                </>
              )}
            </div>
            <div className="mt-6 h-3.5 w-full bg-black"></div>
          </div>
          <PlanInfo
            fullViewArray={fullViewArray}
            agent={agent}
            plan={plan}
            rows={rows}
            numberOfSelectedPlans={numberOfSelectedPlans}
            wantsDental={wantsDental}
            wantsVision={wantsVision}
            zipCodeState={zipCodeState}
            associationLeftover={associationLeftover}
            max={max}
            associationFee={associationFee || 0}
            selectedPlans={selectedPlans}
            amountOfDependentsType={amountOfDependentsType || ""}
            selectable={selectable}
            priceQuote={priceQuote}
            setFullViewArray={setFullViewArray}
            setStep={setStep}
            setSelectedPlan={setSelectedPlan}
            cardPriceHandler={cardPriceHandler}
          />
        </div>
      )}
    </Fragment>
  );
};

export default PlanDetail;
