import { FC, useEffect } from "react";
import cx from "classnames";
import { Header } from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import Submitted from "../Agreements/Submitted";
import Member from "../../models/Member";
import Agent from "../../models/Agent";
import { recordRumEvent, recordRumPageView, RUM_EVENTS } from "../../services/awsRum";
import hotJar from "../../services/hotJar";

type CompletedProps = {
  agentState: Agent
  memberInstance: Member
  setMemberInstance: React.Dispatch<React.SetStateAction<Member>>;
}

const Completed: FC<CompletedProps> = ({
  agentState,
  memberInstance,
  setMemberInstance
}: CompletedProps) => {

  useEffect(() => {
    const eventData = {stepName: 'Completed', agentId: agentState.id, parentAgentId: agentState.parentAgentId, memberId: memberInstance.id}
    recordRumEvent(RUM_EVENTS.APP_COMPLETE, eventData)
    recordRumPageView('Completed')
    hotJar.recordPageView('Completed')
  }, [agentState.id, agentState.parentAgentId, memberInstance.id])

  return (
    <div
      className={cx(
        "h-full w-full",
        "bg-gradient-to-r from-[#003764] to-[#53BFE7]"
      )}
    >
      <Header agentState={agentState} />
      <div className="border-b-2 border-b-orange"></div>

      <Submitted
        agentId={agentState.id}
        memberFirstName={memberInstance.firstName}
        memberLastName={memberInstance.lastName}
        memberId={memberInstance.id}
        setMemberInstance={setMemberInstance}
      />

      <div
        className={`fixed bottom-0 z-30 ml-auto mr-auto w-full bg-orange pt-1`}
      >
        <ProgressBar step={1} />
      </div>
    </div>
  );
};

export default Completed;
