import React, { FC, useEffect, useState, useCallback } from "react";
import Agent from "../../models/Agent";
import Dependent from "../../models/Dependent";
import Member from "../../models/Member";
import Plan from "../../models/Plan";
import Button from "../Button";
import DependentList from "./DependentList";
import { CoverageType } from "../../enums";
import useApplicationStore from "../../context/application";

type CoveredMembersProps = {
  member: Member;
  agent: Agent;
  setAmountOfDependents: React.Dispatch<React.SetStateAction<number>>;
  amountOfDependents: number;
  amountOfDependentsType: string;
  setDependents: React.Dispatch<React.SetStateAction<Dependent[]>>;
  dependents: Dependent[];
  validVisionInState: boolean;
  setSelectedPlanVision: React.Dispatch<React.SetStateAction<Plan>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  selectedPlans: Plan[];
  zipCodeState: string;
};

function isObjectEmpty(obj: Object): Boolean {
  return Object.values(obj).every((val) =>
    typeof val === "object" ? isObjectEmpty(val) : false
  );
}

const CoveredMembers: FC<CoveredMembersProps> = ({
  member,
  agent,
  setAmountOfDependents,
  amountOfDependents,
  amountOfDependentsType,
  setDependents,
  dependents,
  validVisionInState,
  setSelectedPlanVision,
  setStep,
  selectedPlans,
  zipCodeState, // Keep ZipCode State for future testing purposes
}: CoveredMembersProps) => {
  const [spouseSelected, setSpouseSelected] = useState<Boolean>(false);
  const [errorList, setErrorList] = useState<Boolean[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const [missingFields, setMissingFields] = useState<{
    [id: string]: { [field: string]: boolean };
  }>({});

  const appId = useApplicationStore((state) => state.appId);

  useEffect(() => {
    dependents?.forEach((element) => {
      if (element?.relationship === "Spouse") setSpouseSelected(true);
    });
  }, [dependents]);

  useEffect(() => {
    if (dependents.length === amountOfDependents) return;
    let dependentsObject = [];
    if (dependents.length > 0) {
      dependentsObject.push(...dependents);
    }

    while (dependentsObject.length < amountOfDependents) {
      let currObject = new Dependent();
      dependentsObject.push(currObject);
    }
    setDependents(dependentsObject);
    setSubmitted(false);
    let visionId = validVisionInState ? 38593 : 39651;

    if (
      amountOfDependents >= 2 &&
      amountOfDependentsType === CoverageType.MemberPlusChildren &&
      selectedPlans.length >= 2
    ) {
      if (selectedPlans[1].id) {
        const plan = new Plan();
        plan
          .getPlanDetails(
            visionId,
            CoverageType.Family,
            agent?.id,
            member?.zipCode,
            member?.state,
            appId
          )
          .then(() => {
            setSelectedPlanVision(plan);
          });
      }
    } else if (
      amountOfDependents < 2 &&
      amountOfDependentsType === CoverageType.MemberPlusChildren &&
      selectedPlans.length >= 2
    ) {
      if (selectedPlans[1].id) {
        const plan = new Plan();
        plan
          .getPlanDetails(
            visionId,
            amountOfDependentsType,
            agent?.id,
            member?.zipCode,
            member?.state,
            appId
          )
          .then(() => {
            setSelectedPlanVision(plan);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountOfDependents, dependents]);

  useEffect(
    function validateFullPage() {
      if (!submitted) return;
      let newMissingFields: { [id: string]: { [field: string]: boolean } } = {};
      const requiredFields = [
        "firstName",
        "lastName",
        "dateOfBirth",
        "gender",
        "relationship",
      ];

      let isEmpty = true;
      dependents.forEach((dependent, index) => {
        let dependentJson = JSON.parse(JSON.stringify(dependent));
        let dependentMissingField: { [field: string]: boolean } = {};
        requiredFields.forEach((field) => {
          if (!dependentJson[field]) {
            dependentMissingField[field] = true;
            isEmpty = false;
          }
        });
        newMissingFields[index] = dependentMissingField;
      });
      setMissingFields(newMissingFields);
    },
    [dependents, submitted]
  );

  const handleOnFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let newMissingFields: { [id: string]: { [field: string]: boolean } } = {};
    const requiredFields = [
      "firstName",
      "lastName",
      "dateOfBirth",
      "gender",
      "relationship",
    ];
    setSubmitted(true);
    let isEmpty = true;
    dependents.forEach((dependent, index) => {
      let dependentJson = JSON.parse(JSON.stringify(dependent));
      let dependentMissingField: { [field: string]: boolean } = {};
      requiredFields.forEach((field) => {
        if (!dependentJson[field]) {
          dependentMissingField[field] = true;
          isEmpty = false;
        }
      });
      if (dependentMissingField)
        newMissingFields[index] = dependentMissingField;
    });

    setMissingFields(newMissingFields);

    if (isEmpty && isObjectEmpty(newMissingFields))
      setStep((current) => current + 1);
  };

  const addButtonHandler = useCallback(() => {
    setAmountOfDependents(amountOfDependents + 1);
  }, [amountOfDependents, setAmountOfDependents]);

  return (
    <div
      className="relative mb-24 flex w-full flex-col rounded-3xl border-4 border-black bg-white p-8"
      id="dependent"
    >
      <form onSubmit={handleOnFormSubmit} noValidate>
        <div className="inherit bg-inherit">
          {dependents?.map((element, index) => {
            return (
              <>
                <DependentList
                  key={index}
                  index={index}
                  member={member}
                  dependent={element}
                  dependents={dependents}
                  setSpouseSelected={setSpouseSelected}
                  spouseSelected={spouseSelected}
                  setDependents={setDependents}
                  setAmountOfDependents={setAmountOfDependents}
                  amountOfDependents={amountOfDependents}
                  amountOfDependentsType={amountOfDependentsType}
                  setErrorList={setErrorList}
                  errorList={errorList}
                  missingFields={missingFields[index]}
                  submitted={submitted}
                />
              </>
            );
          })}
        </div>
        <div className="sticky bottom-[5rem] w-full bg-white">
          <div className="my-8 h-[2px] w-full bg-navyBlue" />
          <div className="flex flex-row items-center justify-center gap-2 pb-5 xl:gap-6 ">
            <div className="h-[45px] w-[100px] text-2xl leading-[38.73px] xl:w-[241px] xl:text-[32px]">
              {!(
                amountOfDependentsType === CoverageType.Member ||
                amountOfDependentsType === CoverageType.MemberPlusSpouse
              ) && <Button text="Add +" action={addButtonHandler} />}
            </div>
            <div className="h-[45px] w-[100px] text-2xl leading-[38.73px] xl:w-[241px] xl:text-[32px]">
              <Button
                text="Submit"
                className="submitDependentInformation"
                submit
                disabled={errorList.includes(true)}
                mainPath
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CoveredMembers;
