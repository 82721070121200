import { IPlan } from "./PlanInterface";
import axios from "axios";
import { buildNCDHeaders} from "../../utils";
import { CoverageType } from "../../enums";

export default class Plan implements IPlan {
  id?: number;
  label?: string;
  benefitID?: number;
  benefitLabel?: string;
  periodID?: number;
  periodLabel?: string;
  rate?: number;
  rateUpdate?: number;
  type?: string;
  index?: number;

  constructor(
    id?: number,
    label?: string,
    benefitID?: number,
    benefitLabel?: string,
    periodID?: number,
    periodLabel?: string,
    rate?: number,
    rateUpdate?: number,
    type?: string,
    index?: number
  ) {
    this.id = id;
    this.label = label;
    this.benefitID = benefitID;
    this.benefitLabel = benefitLabel;
    this.periodID = periodID;
    this.periodLabel = periodLabel;
    this.rate = rate;
    this.rateUpdate = rate;
    this.type = type;
    this.index = index;
  }

  getPlanDetails = async (
    productID = this.id,
    amountOfDependentsLabel: string,
    agentID?: number,
    zipcode?: string,
    state = "",
    appId?: string
  ) => {
    return await axios
      .get(
        `${process.env.REACT_APP_API123METLIFE}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `${process.env.REACT_APP_API123METLIFEAUTH}`,
            ...buildNCDHeaders(agentID, appId),
          },
          params: {
            PAYLOAD: {
              PRODUCT: { PRODUCTID: productID, agentid: agentID },
              PRIMARY: { ZIPCODE: zipcode, STATE: state },
              SPOUSE: {},
            },
          },
        }
      )
      .then((response) => {
        let rates;
        this.id = response?.data?.ID;
        this.label = response?.data?.LABEL;
        rates = response?.data?.RATES?.filter(
          (rate: { BENEFITLABEL: string }) => {
            if (
              rate.BENEFITLABEL === CoverageType.MemberPlusOne &&
              (amountOfDependentsLabel === CoverageType.MemberPlusSpouse ||
                amountOfDependentsLabel === CoverageType.MemberPlusChildren)
            ) {
              return true;
            }
            return rate.BENEFITLABEL === amountOfDependentsLabel;
          }
        );
        this.benefitID = rates[0].BENEFITID;
        this.benefitLabel = rates[0].BENEFITLABEL;
        this.periodID = rates[0].PERIODID;
        this.periodLabel = rates[0].PERIODLABEL;
        this.rate = rates[0].RATE;
        this.type = rates[0].TYPE;
        return this;
      })
      .catch((error) => {
        return this;
      });
  };
}
