export const associationData = [
  {
    ProductID: 38450,
    Label: "National Wellness & Fitness Association",
    Price: 17,
    PriceUpdated: 17,
    BenefitID: 51,
    AmountOfPeople: "Member",
    State: "Default",
  },
  {
    ProductID: 38450,
    Label: "National Wellness & Fitness Association",
    Price: 34,
    PriceUpdated: 34,
    BenefitID: 41,
    AmountOfPeople: "Member plus Spouse",
    State: "Default",
  },
  {
    ProductID: 38450,
    Label: "National Wellness & Fitness Association",
    Price: 34,
    PriceUpdated: 34,
    BenefitID: 677,
    AmountOfPeople: "Member plus Children",
    State: "Default",
  },
  {
    ProductID: 38450,
    Label: "National Wellness & Fitness Association",
    Price: 47,
    PriceUpdated: 47,
    BenefitID: 21,
    AmountOfPeople: "Family",
    State: "Default",
  },
  {
    ProductID: 38941,
    Label: "National Wellness & Fitness Association",
    Price: 25,
    PriceUpdated: 28,
    BenefitID: 51,
    AmountOfPeople: "Member",
    State: "Default",
  },
  {
    ProductID: 38941,
    Label: "National Wellness & Fitness Association",
    Price: 52,
    PriceUpdated: 56,
    BenefitID: 41,
    AmountOfPeople: "Member plus Spouse",
    State: "Default",
  },
  {
    ProductID: 38941,
    Label: "National Wellness & Fitness Association",
    Price: 60,
    PriceUpdated: 65,
    BenefitID: 677,
    AmountOfPeople: "Member plus Children",
    State: "Default",
  },
  {
    ProductID: 38941,
    Label: "National Wellness & Fitness Association",
    Price: 73,
    PriceUpdated: 78,
    BenefitID: 21,
    AmountOfPeople: "Family",
    State: "Default",
  },
  {
    ProductID: 38449,
    Label: "National Wellness & Fitness Association",
    Price: 10,
    PriceUpdated: 10,
    BenefitID: 51,
    AmountOfPeople: "Member",
    State: "Default",
  },
  {
    ProductID: 38449,
    Label: "National Wellness & Fitness Association",
    Price: 20,
    PriceUpdated: 20,
    BenefitID: 41,
    AmountOfPeople: "Member plus Spouse",
    State: "Default",
  },
  {
    ProductID: 38449,
    Label: "National Wellness & Fitness Association",
    Price: 20,
    PriceUpdated: 20,
    BenefitID: 677,
    AmountOfPeople: "Member plus Children",
    State: "Default",
  },
  {
    ProductID: 38449,
    Label: "National Wellness & Fitness Association",
    Price: 33,
    PriceUpdated: 33,
    BenefitID: 21,
    AmountOfPeople: "Family",
    State: "Default",
  },
  {
    ProductID: 38923,
    Label: "National Wellness & Fitness Association",
    Price: 10,
    PriceUpdated: 11,
    BenefitID: 51,
    AmountOfPeople: "Member",
    State: "Default",
  },
  {
    ProductID: 38923,
    Label: "National Wellness & Fitness Association",
    Price: 20,
    PriceUpdated: 22,
    BenefitID: 450,
    AmountOfPeople: "Member + 1",
    State: "Default",
  },
  {
    ProductID: 38923,
    Label: "National Wellness & Fitness Association",
    Price: 28,
    PriceUpdated: 31,
    BenefitID: 21,
    AmountOfPeople: "Family",
    State: "Default",
  },
  {
    ProductID: 38928,
    Label: "National Wellness & Fitness Association",
    Price: 18,
    PriceUpdated: 21,
    BenefitID: 51,
    AmountOfPeople: "Member",
    State: "Default",
  },
  {
    ProductID: 38928,
    Label: "National Wellness & Fitness Association",
    Price: 38,
    PriceUpdated: 42,
    BenefitID: 41,
    AmountOfPeople: "Member plus Spouse",
    State: "Default",
  },
  {
    ProductID: 38928,
    Label: "National Wellness & Fitness Association",
    Price: 46,
    PriceUpdated: 51,
    BenefitID: 677,
    AmountOfPeople: "Member plus Children",
    State: "Default",
  },
  {
    ProductID: 38928,
    Label: "National Wellness & Fitness Association",
    Price: 59,
    PriceUpdated: 64,
    BenefitID: 21,
    AmountOfPeople: "Family",
    State: "Default",
  },
];
