import React, { ChangeEventHandler, FC, useCallback, useState } from "react";
import Member from "../../models/Member";
import Agent from "../../models/Agent";
import cx from "classnames";
import ZipCodeForm from "../../components/Forms/ZipCodeForm";
import { D2CAgentId, ENV, buildNCDHeaders, INVALID_ZIP_CODE_MSG, nonValidDentalStates, nonValidVisionStates, noSalesState, noSalesStateD2C, somethingWentWrong, STATE_NOT_AVAILABLE_MSG, ZIP_CODE_VALID_LENGTH_MSG } from "../../utils";
import HomepageBlurbAgent from "./HomepageBlurbAgent";
import HomepageBlurbD2C from "./HomepageBlurbD2C";
import { getEnvKey } from "../../environment";
import axios from "axios";
import ErrorLog, { LogLevel } from "../../models/ErrorLog";
import useApplicationStore from "../../context/application";

type HomePageProps = {
  member: Member;
  agent: Agent;
  setValidDentalInState: React.Dispatch<React.SetStateAction<boolean>>;
  setValidVisionInState: React.Dispatch<React.SetStateAction<boolean>>;
  setZipCodeState: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

const HomePage: FC<HomePageProps> = ({
  member,
  agent,
  setValidDentalInState,
  setValidVisionInState,
  setZipCodeState,
  setStep,
}: HomePageProps) => {
  const [zipCode, setZipCode] = useState<string>(member.zipCode || '');
  const [error, setError] = useState("");
  const [pending, setPending] = useState<boolean>(false);

  const appId = useApplicationStore((state) => state.appId);

  const isD2C = agent?.id === D2CAgentId

  const handleZipCodeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
    const zip = (event.target as HTMLInputElement)
                .value
                .replace(/\D/g, ''); // replace all non-numbers with ''

    setZipCode(zip.slice(0,5));
    if (zip.length > 5) {
      setError(ZIP_CODE_VALID_LENGTH_MSG);
      return;
    } else if(error.length > 0){
      setError("")
    }
  },[error])

  const handleZipCodeSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if(zipCode.length !== 5) {
      setError(ZIP_CODE_VALID_LENGTH_MSG)
      return;
    }

    const isInNoSalesState = (stateAbbr: string) => {
      return (noSalesState.includes(stateAbbr)
        || (noSalesStateD2C.includes(stateAbbr) && isD2C)
    )}

    setPending(true);
    member.zipCode = zipCode;
    axios
      .get(
        `${getEnvKey(ENV.REACT_APP_ZIPCODELOOKUP)}/${zipCode}`,
        {
          headers: buildNCDHeaders(agent.id, appId)
        }
      )
      .then((response) => {
        setPending(false);
        const result = response?.data[0];
        if (!result) {
          setError(INVALID_ZIP_CODE_MSG)
          return;
        }
        if (result.status === "invalid_zipcode") {
          setError(INVALID_ZIP_CODE_MSG);
          return;
        }

        const stateAbbr = result?.city_states[0]?.state_abbreviation;
        if (!stateAbbr || isInNoSalesState(stateAbbr)) {
          setError(STATE_NOT_AVAILABLE_MSG);
          return;
        }

        if (nonValidDentalStates.includes(stateAbbr)) {
          setValidDentalInState(false);
        }
        if (nonValidVisionStates.includes(stateAbbr)) {
          setValidVisionInState(false);
        }

        setZipCodeState(stateAbbr);
        member.state = stateAbbr;
        setStep((current) => current + 1);
      })
      .catch((err) => {
        setPending(false);
        const msg = somethingWentWrong(isD2C);
        ErrorLog.log(err, msg, LogLevel.error)
        setError(msg)
      });
  }, [agent.id, appId, isD2C, member, zipCode, setValidDentalInState, setValidVisionInState, setZipCodeState, setStep]);

  return (
    <div className="relative px-0.5 font-inter xs:px-2">
      <div className="flex flex-col items-center justify-center gap-4 py-[33px] lg:container">
        <div className="flex max-w-[831px] flex-col gap-[27px]">
          <ZipCodeForm
            value = {zipCode}
            error = {error}
            pending = {pending}
            onChange = {handleZipCodeChange}
            onSubmit = {handleZipCodeSubmit}
          />
          {isD2C
            ? <HomepageBlurbD2C />
            : <HomepageBlurbAgent />
          }
          <div
            className={cx(
              "flex flex-col justify-center sm:justify-between",
              "overflow-y-auto rounded-3xl border-4 border-solid border-black bg-white",
              "p-[21px] text-black sm:flex-row"
            )}
          >
            <div>
              <div className="text-[24px] font-semibold leading-[29.05px] text-black">
                We Are Spreading The Smile!
              </div>
              <p className="mt-[20px] text-[20px] leading-[24.2px]">
                Sounds too good to be true?
                <br />
                See what our other customers are saying!
              </p>
            </div>
            <div className="elfsight-app-a4711b9a-6add-4fc7-9f4d-c1092a299c74"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
