export enum CoverageType {
  MemberPlusChildren = "Member plus Children",
  Member = "Member",
  MemberPlusSpouse = "Member plus Spouse",
  Family = "Family",
  MemberPlusOne = "Member + 1",
}

export enum PlanLabel {
  NCDValueByMetLife = "NCD Value by MetLife",
  NCDEssentialsByMetLife = "NCD Essentials by MetLife",
  NCDCompleteByMetLife = "NCD Complete by MetLife",
  MetLifeElite1500 = "MetLife Elite 1500",
  MetLifeElite3000 = "MetLife Elite 3000",
  MetLifeElite5000 = "MetLife Elite 5000",
  NCDSmile1000byMetLife = "NCD Smile 1000 by MetLife",
  NCDSmile2500byMetLife = "NCD Smile 2500 by MetLife",
  VSPPreferredPlan = "VSP Preferred Plan by NCD",
  NCDVisionORNY = "NCD - Vision OR",
}
