import { IAxiosResponse } from "./axiosTypes";
import ErrorLog from "./models/ErrorLog";

// Axios error handling from https://axios-http.com/docs/handling_errors
// TODO add remaining scenarios to ErrorLog, and use 3rd party tool to capture errors, see CU #ENG-1424
export const handleAxiosError = (error: IAxiosResponse) => {
  if (error.response) {
    const message = `A response was received but with a non-success status code: ${error.response.status}\n ${error.response.data}`;
    ErrorLog.log(error, message)
  } else if (error.request) {
    ErrorLog.log(error, `An error occurred during the request, and no response was received: ${error.request}`);

  } else {
    const message = `An error occurred while setting up the request: ${error.message}`
    ErrorLog.log(error, message)
  }
  return error;
}