import * as PlanIds from './planIds';

export const planMappings: Record<string, Record<number, number>> = {
  SVGUplines: {
    [PlanIds.NCDElite1500]: PlanIds.NCDElite1500_SVG,
    [PlanIds.NCDElite3000]: PlanIds.NCDElite3000_SVG,
    [PlanIds.NCDElite5000]: PlanIds.NCDElite5000_SVG,
    [PlanIds.NCDValueByMetLife]: PlanIds.NCDValueByMetLife_SVG,
    [PlanIds.NCDEssentialsByMetLife]: PlanIds.NCDEssentialsByMetLife_SVG,
    [PlanIds.NCDCompleteByMetLife]: PlanIds.NCDCompleteByMetLife_SVG,
    [PlanIds.VSPPreferredByMetLife]: PlanIds.VSPPreferredByMetLife_SVG,
    [PlanIds.VSPextra]: PlanIds.VSPextra_SVG,
    [PlanIds.NWFA_Low_Dental]: PlanIds.NWFA_Low_Dental_SVG,
    [PlanIds.NWFA_Mid_High_Dental]: PlanIds.NWFA_Mid_High_Dental_SVG,
    [PlanIds.NWFA_Low_Combo]: PlanIds.NWFA_Low_Combo_SVG,
    [PlanIds.NWFA_Mid_High_Combo]: PlanIds.NWFA_Mid_High_Combo_SVG,
    [PlanIds.NWFA_VSP_Only]: PlanIds.NWFA_VSP_Only_SVG
  },
  SQUplines: {
    [PlanIds.NCDElite1500]: PlanIds.NCDElite1500_SQ,
    [PlanIds.NCDElite3000]: PlanIds.NCDElite3000_SQ,
    [PlanIds.NCDElite5000]: PlanIds.NCDElite5000_SQ,
    [PlanIds.NCDValueByMetLife]: PlanIds.NCDValueByMetLife_SQ,
    [PlanIds.NCDValueByMetLifeNYCT]: PlanIds.NCDValueByMetLifeNYCT_SQ,
    [PlanIds.VSPPreferredByMetLife]: PlanIds.VSPPreferredByMetLife_SQ,
    [PlanIds.VSPextra]: PlanIds.VSPextra_SQ,
    [PlanIds.NWFA_Low_Dental]: PlanIds.NWFA_Low_Dental_SQ,
    [PlanIds.NWFA_Low_Combo]: PlanIds.NWFA_Low_Combo_SQ,
    [PlanIds.NWFA_VSP_Only]: PlanIds.NWFA_VSP_Only_SQ
  }
};