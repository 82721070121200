import ErrorLog, { LogLevel } from "./models/ErrorLog";

export const getEnvKey = (key: string): string | undefined => {
  if(Object.keys(process.env).length < 1) {
    ErrorLog.log(new Error(), 'no .env values set', LogLevel.warn);
    return;
  }

  const value = process.env[key];
  if (value === undefined) {
    const message = `Missing required environment variable ${key}`
    ErrorLog.getInstance().logError(new Error(message), message, LogLevel.warn)
  }
  return value;
}
export const getEnv = () => {
  return getEnvKey('NODE_ENV')
}

// environment-level feature flag, controlled by ENV variable
// enable feature `key` by setting ENV variable to a truthy value other than string 'null' (which signifies feature disabled)
export const featureEnabled = (key: string): boolean => {
  const val = getEnvKey(key)
  const enabled = val ? val !== 'null' : false
  return enabled
}