import { FC } from "react";
import { Header } from "../../components/Header";
import ProgressBar from "../../components/ProgressBar";
import Agent from "../../models/Agent";
import AgentLoginForm from "../../components/Forms/AgentLoginForm";

type AgentLoginProps = {
  agentState: Agent;
  setAgentState: React.Dispatch<React.SetStateAction<Agent>>;
};

const AgentLogin: FC<AgentLoginProps> = ({ agentState, setAgentState }: AgentLoginProps) => {
  return (
    <div id="root relative h-screen" className="relative">
      <div className="absolute z-0 flex h-screen w-full flex-col items-center bg-gradient-to-r from-[#003764] to-[#53BFE7] bg-cover bg-center bg-no-repeat">
        <Header />
        <AgentLoginForm agent={agentState} setAgentState={setAgentState} />
        <div className="fixed bottom-0 mt-2 ml-auto mr-auto w-full bg-orange pt-1">
          <ProgressBar step={1} />
        </div>
      </div>
    </div>
  );
};

export default AgentLogin;
