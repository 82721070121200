import { AwsRum, AwsRumConfig } from "aws-rum-web";
import { featureEnabled, getEnvKey } from "../environment";
import { ENV } from "../utils";

let memoAwsRum;

const initAwsRum = () => {
  if(!featureEnabled(ENV.REACT_APP_AWS_RUM_APP_ID)) return;

  const awsRumAppId = getEnvKey(ENV.REACT_APP_AWS_RUM_APP_ID) || '';

  try {
    // see https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: "us-west-2:2a702291-c84f-4925-ac98-b1abe4e64b6a",
      endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      disableAutoPageView: true,
      enableXRay: false
    };

    const APPLICATION_ID: string = awsRumAppId;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'us-west-2';

    const awsRum: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
    // memoize for future calls
    memoAwsRum = awsRum;
    return awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
  // END CLoudwatch RUM
}
export const awsRum = memoAwsRum || initAwsRum();

export const recordRumEvent = (eventName: string, eventData: object = {}) => {
  try {
    awsRum && awsRum.recordEvent(eventName, eventData)
  } catch {
    console.warn('could not record event ', eventName)
  }
}

export const recordRumPageView = (pageId: string) => {
  try {
    awsRum && awsRum.recordPageView({ pageId });
  } catch {
    console.warn('could not record page view', pageId)
  }
}

export const RUM_EVENTS = {
  APP_COMPLETE: 'APP_COMPLETE',
  APP_INIT: 'APP_INIT',
  APP_SUBMIT: 'APP_SUBMIT',
  NON_TEST_AGENT: 'NON_TEST_AGENT',
  PAYMENT_VALIDATION_ERROR: 'PAYMENT_VALIDATION_ERROR',
  PAYMENT_VALIDATION_CONVERSION: 'PAYMENT_VALIDATION_CONVERSION'
}