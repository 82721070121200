import { FC } from "react";

type NwfaLinkProps = {
  text: string;
  queryString: string;
};

const NwfaLink: FC<NwfaLinkProps> = ({ text, queryString }: NwfaLinkProps) => {
  const baseUrl = 'https://nationalwellnessandfitness.com/';
  return (
    <a href={`${baseUrl}${queryString}`} className="text-blue" target="_blank" rel="noopener noreferrer">{text}</a>
  )
};

export default NwfaLink;

